import React from 'react'
import classNames from 'classnames'
import { TextAreaChangeHandler } from '../../types/events'
import GovUKLabel from './GovUKLabel'
import GovUKHint from './GovUKHint'
import GovUKErrorMessage from './GovUKErrorMessage'

type GovUKTextAreaProps = {
  name: string
  hint?: string
  type?: string
  value?: string
  defaultValue?: string
  onChange?: TextAreaChangeHandler
  disabled?: boolean
  showError?: boolean
  errorMessage?: string
  label?: string
  showLabel?: boolean
  inputStyle?: React.CSSProperties
  wrapStyle?: React.CSSProperties
  inputRef?: React.RefObject<HTMLTextAreaElement>
  wrapRef?: React.RefObject<HTMLDivElement>
  rows: number
  columns: number
  maxLength: number
}

const GovUKTextArea = ({
  name,
  hint,
  value,
  defaultValue,
  onChange = () => { /* empty default */ },
  disabled = false,
  showError = false,
  errorMessage = '',
  label = '',
  showLabel = true,
  inputStyle = {},
  wrapStyle = {},
  inputRef,
  wrapRef,
  rows,
  columns,
  maxLength
}: GovUKTextAreaProps) => {

  const id = name.replace(/ /ig, '').toLowerCase()

  const formGroupId = `${id}-wrap`
  const hintId = `${id}-hint`
  const errorId = `${id}-error`

  const describedBy = `${(hint ? hintId : '')} ${(showError ? errorId : '')}`.trim()

  const formGroupClasses = classNames('govuk-form-group', {
    'govuk-form-group--error': showError
  })

  const inputClasses = classNames(`govuk-textarea`, {
    'govuk-textarea--error': showError
  })

  return (
    <div className='govuk-character-count' data-module='govuk-character-count' data-maxlength={maxLength} ref={wrapRef} style={wrapStyle}>
      <div id={formGroupId} className={formGroupClasses}>
        <GovUKLabel text={label || name} visible={showLabel} htmlFor={id} />
        {hint && <GovUKHint id={hintId} hint={hint} />}
        {showError && <GovUKErrorMessage id={errorId} message={errorMessage} />}
        <textarea
          rows={rows}
          cols={columns}
          key={id}
          className={inputClasses}
          style={inputStyle}
          id={id}
          name={id}
          value={value}
          ref={inputRef}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          aria-describedby={describedBy}
          maxLength={maxLength} />
      </div>
      {maxLength &&
        <span id='remaining-characters' className='govuk-hint govuk-character-count__message' aria-live='polite'>
          You have {value ? maxLength - value.length : maxLength} characters remaining
        </span>}
    </div>
  )
}

export default GovUKTextArea
