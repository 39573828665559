import { addSelectedSuggestion } from '../search'
import reportService from '../../reportService'
import { updateReportSearch } from '../search/thunks'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Suggestion } from '../../../../types/Suggestion'
import { requestErrorHandler } from '../../../../services/request'

interface FilterInformation {
  field: string
  aggregateField: string
  value: string
}

export const getReportsSearchSuggestions = createAsyncThunk<Suggestion, FilterInformation, AppAsyncThunkOptions>
  ('Reports/suggestions', async (filter, { rejectWithValue }) => {
    const {
      field, aggregateField, value
    } = filter

    try {
      const result = await reportService.getReportsSearchSuggestions(field, aggregateField, value)
      return { field, suggestions: result.data }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const selectFilterSuggestion = (field: string, value: string): AppThunk => (dispatch) => {
  dispatch(addSelectedSuggestion({ field, value }))
  dispatch(updateReportSearch())
}
