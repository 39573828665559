import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../Authorisation/components/RoleCheck'
import { MI_VIEW } from '../../constants/roles'

const ReportsComponent = lazy(() => import('./components/Reports'))

const Reports = () => (
  <Routes>
    <Route path='/*' element={
      <DefaultSuspense>
        <RoleCheck roles={[MI_VIEW]} render={() => <ReportsComponent />} />
      </DefaultSuspense>
    } />
  </Routes>
)

export default Reports
