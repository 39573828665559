import { UserProfile } from '../../../../types/api'
import authorisationService from '../../authorisationService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAsyncThunkOptions } from '../../../../store'
import { requestErrorHandler } from '../../../../services/request'

export const updateUserProfileTerms = createAsyncThunk('UserProfile/updateUserProfileTerms', async () => {
  await authorisationService.setTermsAccepted()
})

export const getUserProfile = createAsyncThunk<UserProfile, void, AppAsyncThunkOptions>('UserProfile/getUserProfile', async (_, { rejectWithValue }) => {
  try {
    const result = await authorisationService.requestUserProfile()
    return result.data
  }
  catch (e) {
    return rejectWithValue(requestErrorHandler(e as Error))
  }
})

