import { IHealthResponse } from '../../../../types/api'
import { createSlice } from '@reduxjs/toolkit'
import { getStatusInformation } from './thunks'
import { getRejectionError } from '../../../../services/request'

export type StatusInformationState = {
  status: IHealthResponse | null
  error: string | null
}

export const initialState: StatusInformationState = {
  status: null,
  error: null
}

export const STATUS_INFORMATION = 'Status/information'

const slice = createSlice({
  name: STATUS_INFORMATION,
  initialState: initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStatusInformation.fulfilled, (state, action) => {
      state.status = action.payload || initialState.status
    })
    builder.addCase(getStatusInformation.rejected, (state, action) => {
      state.error = getRejectionError(action, initialState.error)
    })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
