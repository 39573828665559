import React from 'react'
import PageTitle from './PageTitle'

const NotFound = () => {

  return (
    <div>
      <PageTitle heading='Page not found' />

      <p>If you typed the web address, check it is correct.</p>
      <p>If you pasted the web address, check you copied the entire address.</p>
      <p>If the web address is correct or you selected a link or button, contact APHIDS support.</p>
    </div>
  )
}

export default NotFound
