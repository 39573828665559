import axios from 'axios'
import response from '../../services/response'
import fileSaver from 'file-saver'

const download = async (path: string, filename: string) => {
  const res = await axios.get(path, { responseType: 'arraybuffer' })
  const fileName = response.getFilenameFromContentDisposition(res, filename)
  const blob = new Blob([res.data], { type: 'application/pdf' })

  //Save pdf as file
  fileSaver.saveAs(blob, fileName)

  //Open the URL on new Window
  const fileURL = URL.createObjectURL(blob)
  window.open(fileURL)
}

export default {
  download
}
