import React from 'react'
import { useAppSelector } from '../../../store'
import FeedbackConfirmation from './FeedbackConfirmation'
import PageTitle from '../../../components/PageTitle'

const GeneralFeedbackConfirmation = () => {

  const {
    error,
    referenceNumber
  } = useAppSelector((state) => state.feedback)

  return (
    <>
      <PageTitle heading='Give feedback' />

      <FeedbackConfirmation
        heading='Feedback received'
        message='Thank you for providing feedback.'
        referenceNumber={referenceNumber}
        error={error}
        errorTitle='There was an error submitting feedback'
      />
    </>
  )
}

export default GeneralFeedbackConfirmation
