import { PayloadAction } from '@reduxjs/toolkit'
import date from '../../services/date'
import { AggregateMetadata } from '../../types/api'
import { BreadboxItem } from '../../types/BreadBoxItem'
import { CheckboxStatus } from '../../types/checkboxes'
import { DateFilter } from '../../types/dates'
import { ExpandedStatus } from '../../types/SideMenu'
import { TextSuggestion } from '../../types/TextSuggestion'

export type FilterState = {
  breadboxItems: BreadboxItem[]
  sideMenuExpandedStatus: boolean
  sideMenuEntryExpandedStatus: ExpandedStatus
  sideMenuEntryTopMatches: AggregateMetadata
  sideMenuTextMatchesStatus: TextSuggestion[]
  sideMenuCheckboxStatus: CheckboxStatus[]
  sideMenuCheckboxSubFilterText: string
  sideMenuDateFilters: DateFilter[]
}

export const initialState: FilterState = {
  breadboxItems: [],
  sideMenuExpandedStatus: false,
  sideMenuEntryExpandedStatus: {},
  sideMenuEntryTopMatches: {},
  sideMenuTextMatchesStatus: [],
  sideMenuCheckboxStatus: [],
  sideMenuCheckboxSubFilterText: '',
  sideMenuDateFilters: []
}

export const toggleSideMenuExpanded = (state: FilterState) => {
  state.sideMenuExpandedStatus = !state.sideMenuExpandedStatus
}

export const toggleSideMenuEntryExpanded = (state: FilterState, action: PayloadAction<string>) => {
  state.sideMenuEntryExpandedStatus[action.payload] = (!state.sideMenuEntryExpandedStatus[action.payload] || false)
}

export const removeBreadboxItem = (state: FilterState, action: PayloadAction<{ breadbox: string, value: string }>) => {
  const { breadbox, value } = action.payload
  state.breadboxItems = state.breadboxItems.filter((item) => item.breadbox !== breadbox || item.value !== value)
  state.sideMenuCheckboxStatus = state.sideMenuCheckboxStatus.filter((checkbox) => checkbox.groupId !== breadbox || checkbox.checkboxId !== value)
  state.sideMenuTextMatchesStatus = state.sideMenuTextMatchesStatus.filter((item) => item.groupId !== breadbox || item.value !== value)
  state.sideMenuDateFilters = state.sideMenuDateFilters.filter((item) => item.id !== breadbox)
}

export const clearDateFilter = (state: FilterState, action: PayloadAction<string>) => {
  state.sideMenuDateFilters = state.sideMenuDateFilters.filter((item) => item.id !== action.payload)
  state.breadboxItems = state.breadboxItems.filter((item) => item.breadbox !== action.payload)
}

export const addSelectedSuggestion = (state: FilterState, action: PayloadAction<{ field: string, value: string }>) => {
  const { field, value } = action.payload

  const filteredState = state.sideMenuTextMatchesStatus.filter((item) => item.groupId !== field || item.value !== value)
  state.sideMenuTextMatchesStatus = [...filteredState, {
    groupId: field,
    value: value
  }]

  const matchingItem = state.breadboxItems.find((item) => item.breadbox === field && item.value === value)
  if (!matchingItem) {
    state.breadboxItems.push({
      breadbox: field,
      value: value
    })
  }
}

export const setSideMenuCheckbox = (state: FilterState, action: PayloadAction<{
  checked: boolean,
  groupId: string,
  checkboxId: string,
  text: string
}>) => {
  const { checked, checkboxId, groupId, text } = action.payload
  if (checked) {
    const filteredItems = state.breadboxItems.filter((item) => item.breadbox !== groupId || item.value !== checkboxId)
    state.breadboxItems = [...filteredItems, { breadbox: groupId, value: checkboxId, text }]
  }
  else {
    state.breadboxItems = state.breadboxItems.filter((item) => item.breadbox !== groupId || item.value !== checkboxId)
  }

  const filteredCheckboxStatus = state.sideMenuCheckboxStatus.filter((checkbox) => checkbox.groupId !== groupId || checkbox.checkboxId !== checkboxId)
  state.sideMenuCheckboxStatus = [...filteredCheckboxStatus, {
    checkboxId,
    groupId,
    checked
  }]
}

export const setSideMenuCheckboxSubFilterText = (
  state: FilterState,
  action: PayloadAction<{
  text: string
}>) => {
  const { text } = action.payload
  state.sideMenuCheckboxSubFilterText = text
}

export const setSideMenuDateFilter = (state: FilterState, action: PayloadAction<{
  id: string,
  day: string,
  month: string,
  year: string,
  validDay?: string,
  validMonth?: string,
  validYear?: string
}>) => {
  const { id, validDay, validMonth, validYear, day, month, year } = action.payload

  const filteredItems = state.breadboxItems.filter((item) => item.breadbox !== id)

  if (!validDay || !validMonth || !validYear) {
    state.breadboxItems = filteredItems
  }
  else {
    const newItem: BreadboxItem = {
      breadbox: id,
      value: date.formatDateFromFragments(
        validDay,
        validMonth,
        validYear
      )
    }

    state.breadboxItems = [...filteredItems, newItem]
  }

  const previousFilter = state.sideMenuDateFilters.find((dateFragment) => dateFragment.id === id)

  if (!previousFilter
    || previousFilter.day !== day
    || previousFilter.month !== month
    || previousFilter.year !== year
    || previousFilter.validDay !== validDay
    || previousFilter.validMonth !== validMonth
    || previousFilter.validYear !== validYear) {
    const filteredDateFilters = state.sideMenuDateFilters.filter((dateFragment) => dateFragment.id !== id)

    if (day === ''
      && month === ''
      && year === '') {
      state.sideMenuDateFilters = filteredDateFilters
    }
    else {
      const newFilter: DateFilter = {
        id: id,
        day: day,
        month: month,
        year: year,
        validDay: validDay,
        validMonth: validMonth,
        validYear: validYear
      }

      state.sideMenuDateFilters = [...filteredDateFilters, newFilter]
    }
  }
}
