/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import GovUKCell from './GovUKCell'
import classNames from 'classnames'
import GovUKRow, { ExpandedRowCreator } from './GovUKRow'
import { Header } from '../../types/table'

export type ExpandedSelector = (data: any) => boolean

type GovUKTableProps = {
  title?: string
  headers: Header[]
  rows: any
  expandedSelector?: ExpandedSelector
  expandedRowCreator?: ExpandedRowCreator
  getRowClassName?: Function
}

const GovUKTable = ({
  title,
  headers,
  rows,
  expandedSelector = () => false,
  expandedRowCreator,
  getRowClassName
}: GovUKTableProps) => {

  const headerWeight = 500

  const renderHeaderCell = (header: Header, index: number) => {
    const headerTitle = <span style={{ fontWeight: headerWeight }}>{header.title}</span>

    return (
      <GovUKCell
        key={`header-${index}`}
        content={headerTitle}
        header={true}
      />
    )
  }

  const renderDataRow = (row: any, rowIndex: number, expandedCreator?: ExpandedRowCreator) => {
    const rowClassName = classNames('govuk-table__row',
      typeof getRowClassName === 'function'
        ? getRowClassName(row)
        : ''
    )

    return (
      <GovUKRow
        key={`row-${rowIndex}`}
        data={row}
        index={rowIndex}
        className={rowClassName}
        headers={headers}
        expanded={expandedSelector(row)}
        expandedRowCreator={expandedCreator}
      />
    )
  }

  return (
    <table className="govuk-table" aria-live="polite">
      {title && <caption className="govuk-visually-hidden">{title}</caption>}
      <thead className="govuk-table__head">
        <tr key="header" className="govuk-table__row">
          {
            headers.map((header: Header, headerIndex: number) => {
              return renderHeaderCell(header, headerIndex)
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row: any, rowIndex: number) => {
            return renderDataRow(row, rowIndex, expandedRowCreator)
          })
        }
      </tbody>
    </table>
  )
}

export default GovUKTable
