import auditService from '../../auditService'
import { getAuditSearchRecords } from '../search/thunks'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Suggestion } from '../../../../types/Suggestion'
import { addSelectedSuggestion } from '../search'
import { requestErrorHandler } from '../../../../services/request'

interface FilterInformation {
  field: string
  aggregateField: string
  value: string
}

export const getAuditSearchSuggestions = createAsyncThunk<Suggestion, FilterInformation, AppAsyncThunkOptions>
  ('Search/suggestions', async (filter, { getState, rejectWithValue }) => {
    const terms = getState().audits.search.terms

    const {
      field, aggregateField, value
    } = filter

    try {
      const result = await auditService.getAuditSearchSuggestions(field, aggregateField, value, terms)
      return { field, suggestions: result.data }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const selectFilterSuggestion = (field: string, value: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(addSelectedSuggestion({ field, value }))
  await dispatch(getAuditSearchRecords())
}