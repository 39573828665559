import React, { FC } from 'react'
import HoLogo from '../icons/HoLogo'
import Logout from '../modules/Authorisation/components/Logout'
import { useAuthentication } from '../hooks/authentication'
import PhaseBanner from './PhaseBanner'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../store'
import CookieConsent from 'react-cookie-consent'
import date from '../services/date'
import styled from 'styled-components'

const UserInformationContainer = styled.div`
  text-align: center;
`

const HeaderItems = styled.ul`
  margin: 0;
`

const HeaderItem = styled.li`
  vertical-align: middle;
`

const DisplayLabel = styled.span`
  display: block;
`

const Header: FC = () => {
  const { tokenParsed, authenticated } = useAuthentication()

  const {
    release_phase
  } = useAppSelector((state) => state.health)

  const {
    userProfile
  } = useAppSelector((state) => state.user)

  const lastLogin = userProfile?.lastLogin
    ? <DisplayLabel>Last login: {date.formatDateTimeFromUtcString(userProfile?.lastLogin)}</DisplayLabel>
    : <></>

  const username = tokenParsed ? (tokenParsed.preferred_username || 'user') : ''

  return (
    <header>
      {authenticated && <CookieConsent
        expires={365}
        disableStyles={true}
        location="top"
        buttonText=""
        sameSite="strict"
        ariaAcceptLabel="I accept this website uses cookies"
        containerClasses="app-cookie-banner"
        contentClasses="app-cookie-banner__message"
        buttonClasses="app-cookie-banner__button hod-cross">
        The APHIDS system uses cookies to make the system simpler. <Link to={'/cookies'} target="_blank">Find out more about cookies</Link>
      </CookieConsent>}

      <div className="wrapper-header__system">
        <div className="header-logo" style={{ marginLeft: 0 }}>
          <HoLogo width={150} height={35} title="Home Office" />
        </div>
        <div className='header-title'>
          <Link to='/' className='header-name'>APHIDS<br />Access Pass Holder Information Distribution System</Link>
        </div>
        {authenticated && (
          <div className='header-menu'>
            <HeaderItems>
              <HeaderItem>
                <UserInformationContainer>
                  <DisplayLabel>{username}</DisplayLabel>{lastLogin}
                </UserInformationContainer>
              </HeaderItem>
              <HeaderItem><Logout /></HeaderItem>
            </HeaderItems>
          </div>)
        }
      </div>
      {release_phase && <PhaseBanner phase={release_phase} displayFeedbackMessage={authenticated} />}
    </header>
  )
}

export default Header
