import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../Authorisation/components/RoleCheck'
import { RCRC_CONSUMER } from '../../constants/roles'

const RepeatCrcComponent = lazy(() => import('./components/RepeatCrc'))

const RepeatCrc = () => (
  <Routes>
    <Route path='*' element={
      <DefaultSuspense>
        <RoleCheck roles={[RCRC_CONSUMER]} render={() => <RepeatCrcComponent />} />
      </DefaultSuspense>
    } />
  </Routes>
)

export default RepeatCrc
