import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserProfile } from '../../../../types/api'
import { updateUserProfileTerms, getUserProfile } from './thunks'

export const AUTHORISATION_USERPROFILE = 'Authorisation/userProfile'

export type UserState = {
  userProfile: UserProfile | null
}

export const initialState: UserState = {
  userProfile: null
}

const slice = createSlice({
  name: AUTHORISATION_USERPROFILE,
  initialState,
  reducers: {
    setUserProfile(state, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload
    },
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserProfileTerms.fulfilled, (state) => {
      if (state.userProfile) {
        state.userProfile.hasAcceptedTerms = true
      }
    })
    builder.addCase(updateUserProfileTerms.rejected, () => {
      return initialState
    })
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload
    })
    builder.addCase(getUserProfile.rejected, () => {
      return initialState
    })
  }
})

export * from './thunks'

export const {
  setUserProfile,
  reset
} = slice.actions

export default slice.reducer
