import axios from 'axios'
import queryString from 'query-string'
import { SAVED_ON } from '../../constants/savedProfileSortFields'
import { DESCENDING } from '../../constants/sortOrderFields'
import {
  ApiSuccessResult,
  PageRequest,
  PageResult,
  SavedProfileSummary,
  SortOrder,
  SortRequest,
  SavedProfileResult
} from '../../types/api'

interface SavedProfilePostQueryRequest {
  personId: string
  justification?: string
}

interface SavedProfileSearchQueryRequest extends PageRequest, SortRequest { }

const saveProfile = async (personId: string, justification?: string) => {
  const query: SavedProfilePostQueryRequest = {
    personId
  }

  if (justification) {
    query.justification = justification
  }

  const url = `/api/v1/saved-profiles/?${queryString.stringify(query)}`

  const res = await axios.post<ApiSuccessResult<SavedProfileResult>>(url)
  return res.data
}

const unsaveProfile = async (id: string) => {
  const url = `/api/v1/saved-profiles/${id}`

  const res = await axios.delete(url)
  return res.data
}

const getSavedProfiles = async (
  page: number,
  pageSize: number,
  sortBy?: string,
  sortOrder?: SortOrder
) => {
  const query: SavedProfileSearchQueryRequest = {
    page,
    pageSize
  }
  query.sortBy = sortBy || SAVED_ON
  query.sortOrder = sortOrder || DESCENDING

  const url = `/api/v1/saved-profiles/search?${queryString.stringify(query)}`

  const res = await axios.get<PageResult<SavedProfileSummary>>(url)
  return res.data
}

export default {
  saveProfile,
  unsaveProfile,
  getSavedProfiles
}
