import React, { FC } from 'react'
import { hasGroups, hasRoles } from '../services/token'
import { IMPORT, SEARCH, IMPORT_REVIEW } from '../constants/roles'
import { ACCOUNT_MANAGER } from '../constants/groups'
import { useAppSelector } from '../store'
import { Link } from 'react-router-dom'
import { useAuthentication } from '../hooks/authentication'
import styled from 'styled-components'

const FooterItem = styled.span`
  font-family: "Inter UI", sans-serif;
  text-decoration: none;
`

const Footer: FC = () => {

  const { tokenParsed, authenticated } = useAuthentication()

  const {
    release_number
  } = useAppSelector((state) => state.health)

  return (
    <footer>
      <div className="wrapper-footer__system">
        <div className="footer-message">
          <p>A Home Office Digital, Data and Technology service</p>
        </div>
        <div className="footer-menu">
          <ul>
            {release_number &&
              <li><FooterItem>Version {release_number}</FooterItem></li>
            }
            {authenticated && <li><Link to='/accessibility'>Accessibility</Link></li>}
            <li><Link to='/cookies'>Cookies</Link></li>
            {authenticated && <li><Link to='/terms'>Terms and Conditions</Link></li>}
            <li><Link to='/feedback/support'>Support</Link></li>
            {authenticated && (hasRoles(tokenParsed, SEARCH) || hasRoles(tokenParsed, IMPORT) || hasRoles(tokenParsed, IMPORT_REVIEW) || hasGroups(tokenParsed, ACCOUNT_MANAGER)) && <li><Link to='/training'>Training</Link></li>}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
