import { reset } from '../results/actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const BULKSEARCH_JUSTIFICATION = 'BulkSearch/Justification'

export type BulkSearchJustificationState = {
  isEditing: boolean
  justification: string
}

export const initialState: BulkSearchJustificationState = {
  isEditing: false,
  justification: ''
}

const slice = createSlice({
  name: BULKSEARCH_JUSTIFICATION,
  initialState,
  reducers: {
    setBulkSearchJustification(state, action: PayloadAction<string>) {
      state.justification = action.payload || ''
    },
    startEditing(state) {
      state.isEditing = true
    },
    stopEditing(state) {
      state.isEditing = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  }
})

export const {
  setBulkSearchJustification,
  startEditing,
  stopEditing
} = slice.actions

export default slice.reducer
