import React from 'react'
import { Navigate } from 'react-router-dom'
import { hasGroups } from '../../../services/token'
import { useAuthentication } from '../../../hooks/authentication'

type GroupCheckProps = {
  groups: string[]
  render: Function
}

const GroupCheck = ({
  groups,
  render
}: GroupCheckProps) => {

  const { tokenParsed } = useAuthentication()

  return tokenParsed && hasGroups(tokenParsed, ...groups) ? render() : <Navigate to='/not-found' />
}

export default GroupCheck
