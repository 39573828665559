import React, { FC } from 'react'
import classNames from 'classnames'
import { InputChangeHandler } from '../../types/events'
import { RadioButton } from '../../types/radios'
import GovUKErrorMessage from './GovUKErrorMessage'
import GovUKHint from './GovUKHint'
import GovUKLabel from './GovUKLabel'
import GovUKInput from './GovUKInput'
import GovUKFieldset from './GovUKFieldset'

type GovUKRadioGroupProps = {
  legend?: string
  hint?: string
  items?: RadioButton[]
  name: string
  selected?: string
  onChange?: InputChangeHandler
  showError?: boolean
  errorMessage?: string
  inline?: boolean
  noBottomMargin?: boolean
  legendClass?: string
  wrapRef?: React.RefObject<HTMLDivElement>
  inputRef?: React.RefObject<HTMLInputElement>
}

const GovUKRadioGroup: FC<GovUKRadioGroupProps> = ({
  legend,
  hint,
  items = [],
  name,
  selected,
  onChange,
  showError = false,
  errorMessage = '',
  inline = false,
  noBottomMargin = false,
  legendClass = 'govuk-fieldset__legend--xl',
  wrapRef,
  inputRef
}) => {

  const id = name.replace(/ /g, '').toLowerCase()

  const formGroupId = `${id}-wrap`
  const hintId = `${id}-hint`
  const errorId = `${id}-error`

  const describedBy = `${(hint ? hintId : '')} ${(showError ? errorId : '')}`.trim()

  const formGroupClasses = classNames('govuk-form-group', {
    'mb-0': noBottomMargin,
    'govuk-form-group--error': showError
  })

  const radiosInlineClass = classNames('govuk-radios', {
    'govuk-radios--inline': inline
  })

  return (
    <div id={formGroupId} className={formGroupClasses} ref={wrapRef}>
      <GovUKFieldset role='group' describedBy={describedBy} extraClasses={legendClass} legend={legend}>
        {hint && <GovUKHint id={hintId} hint={hint} />}
        {showError && <GovUKErrorMessage id={errorId} message={errorMessage} />}
        <div className={radiosInlineClass}>
          {items.map((item, i) => {
            return (
              <div className='govuk-radios__item' key={item.id}>
                <GovUKInput
                  extraClasses='govuk-radios__input'
                  ref={i === 0 ? inputRef : null}
                  id={`${name}-${i}`}
                  name={name}
                  type='radio'
                  value={item.id}
                  onChange={onChange}
                  defaultChecked={selected !== undefined && item.id === selected}
                />
                <GovUKLabel text={item.text} extraClasses='govuk-radios__label' htmlFor={`${name}-${i}`} />
              </div>
            )
          })}
        </div>
      </GovUKFieldset>
    </div>
  )
}

export default GovUKRadioGroup
