import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { Suggestion } from '../../../../types/Suggestion'
import { addSelectedSuggestion } from '../../../AccountActivity/state/results'
import AccountActivityService from '../../AccountActivityService'
import { updateUserResults } from '../results/thunks'

interface FilterInformation {
  field: string
  aggregateField: string
  value: string
}

export const getAccountActivityFilterSuggestions = createAsyncThunk<Suggestion, FilterInformation, AppAsyncThunkOptions>
  ('AccountActivity/suggestions', async (filter, { rejectWithValue }) => {
    const {
      field, aggregateField, value
    } = filter

    try {
      const result = await AccountActivityService.getUsersSuggestions(field, aggregateField, value)
      return { field, suggestions: result.data }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const selectFilterSuggestion = (field: string, value: string): AppThunk => (dispatch) => {
  dispatch(addSelectedSuggestion({ field, value }))
  dispatch(updateUserResults())
}
