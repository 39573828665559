import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Phase } from '../types/api'

type PhaseBannerProps = {
  displayFeedbackMessage?: boolean
  phase: Phase
}

const PhaseBanner: FC<PhaseBannerProps> = ({
  displayFeedbackMessage = false,
  phase
}) => {
  return (
    <div className="govuk-phase-banner">
      <p className="govuk-phase-banner__content">
        <strong className="govuk-tag govuk-phase-banner__content__tag">
          {phase}
        </strong>
        <span className="govuk-phase-banner__text">
          {displayFeedbackMessage
            ? <span>This is a new service - your <Link to='/feedback/general'>feedback</Link> will help us to improve it.</span>
            : 'This is a new service.'}
        </span>
      </p>
    </div>
  )
}

export default PhaseBanner
