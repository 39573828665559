import { createSlice } from '@reduxjs/toolkit'
import { getRejectionErrorAndCode } from '../../../../services/request'
import { ApplicationError } from '../../../../store'
import { IReferenceDataSummary, UserOrganisationSummary } from '../../../../types/api'
import { getUserOrganisations } from './thunks'

export const ORGANISATION_INFORMATION = 'Organisation/information'

export type OrganisationInformationState = {
  orgs: UserOrganisationSummary[] | null
  config: IReferenceDataSummary 
  error: ApplicationError | null
}

export const initialState: OrganisationInformationState = {
  orgs: null,
  config: {},
  error: null
}

const slice = createSlice({
  name: ORGANISATION_INFORMATION,
  initialState: initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserOrganisations.fulfilled, (state, action) => {
      state.orgs = action.payload.orgs
      state.config = action.payload.config
      state.error = initialState.error
    })
    builder.addCase(getUserOrganisations.rejected, (state, action) => {
      state.error = getRejectionErrorAndCode(action, initialState.error)
    })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
