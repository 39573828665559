import React, { useState, FormEvent, ChangeEvent } from 'react'
import GovUKRadioGroup from '../../../components/govuk/GovUKRadioGroup'
import { RadioButton } from '../../../types/radios'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { Navigate, useNavigate } from 'react-router-dom'
import { SupportRequestType } from '../../../types/api'
import PageTitle from '../../../components/PageTitle'
import { useAuthentication } from '../../../hooks/authentication'
import { hasRoles } from '../../../services/token'
import { CREATE_ACCOUNT_REQUESTOR, REMOVE_ACCOUNT_REQUESTOR } from '../../../constants/roles'
import GovUKButton from '../../../components/govuk/GovUKButton'
import GovUKBackButton from '../../../components/govuk/GovUKBackButton'

const typeInputName = 'request-type'
const typeErrorMessage = 'The request type field cannot be empty'

const errorLinks: ErrorLinks = {
  [typeErrorMessage]: {
    anchorName: `#${typeInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  }
}

const typeIsValid = (input: SupportRequestType) =>
  input === SupportRequestType.ChangeOTPDevice ||
  input === SupportRequestType.CreateAccount ||
  input === SupportRequestType.RemoveAccount ||
  input === SupportRequestType.Other

const SupportFeedbackRequest = () => {

  const navigate = useNavigate()
  const { tokenParsed } = useAuthentication()
  const [type, setType] = useState('')
  const [typeError, setTypeError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    switch (type) {
      case SupportRequestType.ChangeOTPDevice:
        return <Navigate to='/feedback/support/request/account/otp' />
      case SupportRequestType.RemoveAccount:
        return <Navigate to='/feedback/support/request/account/remove' />
      case SupportRequestType.CreateAccount:
        return <Navigate to='/feedback/support/request/account/create' />
      case SupportRequestType.Other:
        return <Navigate to='/feedback/support/request/other' />
    }
  }

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault()

    if (typeIsValid(type as SupportRequestType)) {
      setSubmitted(true)
    } else {
      setTypeError(typeErrorMessage)
    }
  }

  const onTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as SupportRequestType
    if (typeIsValid(value)) {
      setTypeError('')
      setType(value)
    } else {
      setTypeError(typeErrorMessage)
    }
  }

  const radioItems = (): RadioButton[] => {
    const items: RadioButton[] = [{
      id: SupportRequestType.Other,
      text: 'I have a different request'
    }]

    if (hasRoles(tokenParsed, CREATE_ACCOUNT_REQUESTOR)) {
      items.unshift({
        id: SupportRequestType.CreateAccount,
        text: 'Create account'
      })
    }

    if (hasRoles(tokenParsed, REMOVE_ACCOUNT_REQUESTOR)) {
      items.unshift({
        id: SupportRequestType.RemoveAccount,
        text: 'Remove account'
      })
    }

    items.unshift({
      id: SupportRequestType.ChangeOTPDevice,
      text: 'Change OTP device'
    })

    return items
  }

  return (
    <>
      <GovUKBackButton caption='Back to support' onClick={() => { navigate('/feedback/support') }} />

      <PageTitle heading='Request' />

      {typeError && <GovUKErrorSummary title='Please check the form' errors={[typeError]} errorLinks={errorLinks} />}

      <form onSubmit={onSubmit}>

        <GovUKRadioGroup
          name={typeInputName}
          legend='How can we help you?'
          items={radioItems()}
          selected={type}
          onChange={onTypeChange}
          legendClass='govuk-fieldset__legend--s'
          showError={typeError.length > 0}
          errorMessage={typeError}
          wrapRef={errorLinks[typeErrorMessage].wrapRef}
          inputRef={errorLinks[typeErrorMessage].inputRef}
        />

        <div className='govuk-form-group'>
          <GovUKButton
            style={{ marginBottom: '0px' }}
            id='support-request-continue'
            type='submit'>
            Continue
          </GovUKButton>
        </div>

      </form>
    </>
  )
}

export default SupportFeedbackRequest
