import { SortList } from '../../../Sort/state'
import { AppAsyncThunkOptions } from '../../../../store'
import { BulkExportSummary, PageResult } from '../../../../types/api'
import historyService from '../historyService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

export const requestHistory = createAsyncThunk<PageResult<BulkExportSummary>, number, AppAsyncThunkOptions>
  ('BulkExport/requestHistory', async (page, { getState, rejectWithValue }) => {
    const state = getState()
    const {
      pageSize
    } = state.bulkExport.history

    const {
      sortBy,
      sortOrder
    } = state.sort

    const tableSortBy = sortBy[SortList.BulkExportTable]
    const tableSortOrder = sortOrder[SortList.BulkExportTable]

    try {
      const result = await historyService.requestHistory(page, pageSize, tableSortBy, tableSortOrder)
      return result
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
