import axios from 'axios'
import queryString from 'query-string'
import { DATE } from '../../constants/auditSearchSortFields'
import { ASCENDING } from '../../constants/sortOrderFields'
import {
  SortOrder,
  PageRequest,
  SortRequest,
  AuditSearchSummary,
  PageResult,
  Aggregate,
  Filter,
  SearchMetadata,
  ApiSuccessResult
} from '../../types/api'
import _ from 'lodash'

interface AuditQueryRequest extends PageRequest, SortRequest {
  terms?: string
}

const requestAuditSearchRecords = async (
  terms: string,
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: SortOrder,
  filter?: Filter,
  aggregates?: Aggregate[]
) => {

  const query: AuditQueryRequest = {
    page,
    pageSize
  }

  if (terms && terms.trim().length > 0) {
    query.terms = terms.trim()
  }

  query.sortBy = sortBy || DATE
  query.sortOrder = sortOrder || ASCENDING

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
    aggregates: _.isEmpty(aggregates) ? undefined : aggregates
  }

  const result = await axios.post<PageResult<AuditSearchSummary, SearchMetadata>>(`/api/v1/audits/search?${queryString.stringify(query)}`, payload)
  return result.data
}

const getAuditSearchSuggestions = async (field: string, aggregateField: string, value: string, terms: string) => {
  const query = {
    terms,
    aggregateField,
    searchField: field,
    value
  }

  const result = await axios.get<ApiSuccessResult<string[]>>(`/api/v1/audits/search/suggestions?${queryString.stringify(query)}`)
  return result.data
}

export default {
  requestAuditSearchRecords,
  getAuditSearchSuggestions
}