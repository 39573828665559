import { OrganisationSummary } from '../../../../types/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllOrganisations, requestDownload, requestPreview } from './thunks'
import { PassActiveValueType } from '../../../../services/filterRenderers'

export interface IBulkExportFilter {
  field: string
  value: PassActiveValueType
}
export type IBulkExportFilters = IBulkExportFilter[]

export type BulkExportDownloadState = {
  isFetching: boolean
  hasError: boolean
  justification: string
  organisations: string[]
  filters: IBulkExportFilters
  filtersDisplayValue: string
  preview: string
  count: number | null
  organisationLookupInfo: OrganisationSummary[]
}

export const initialState: BulkExportDownloadState = {
  isFetching: false,
  hasError: false,
  justification: '',
  organisations: [],
  filters: [],
  filtersDisplayValue: '',
  preview: '',
  count: null,
  organisationLookupInfo: []
}

export const BULKEXPORT_DOWNLOAD = 'BulkExport/download'

const slice = createSlice({
  name: BULKEXPORT_DOWNLOAD,
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    setJustification(state, action: PayloadAction<string>) {
      state.justification = action.payload
    },
    setOrganisations(state, action: PayloadAction<string[]>) {
      state.organisations = action.payload
    },
    setFilters(state, action: PayloadAction<IBulkExportFilters>) {
      state.filters = action.payload
    },
    setFiltersDisplayValue(state, action: PayloadAction<string>) {
      state.filtersDisplayValue = action.payload
    },
    setExportError(state) {
      state.hasError = true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestPreview.pending, (state) => {
      state.isFetching = true
      state.hasError = false
      state.count = initialState.count
      state.preview = initialState.preview
    })
    builder.addCase(requestPreview.fulfilled, (state, action) => {
      state.preview = action.payload.previewJson
      state.count = action.payload.count
      state.isFetching = false
      state.hasError = false
    })
    builder.addCase(requestPreview.rejected, (state) => {
      state.hasError = true
      state.count = initialState.count
      state.isFetching = false
      state.preview = initialState.preview
    })
    builder.addCase(requestDownload.rejected, (state) => {
      state.hasError = true
    })
    builder.addCase(getAllOrganisations.pending, (state) => {
      state.hasError = false
      state.organisationLookupInfo = initialState.organisationLookupInfo
    })
    builder.addCase(getAllOrganisations.fulfilled, (state, action) => {
      state.organisationLookupInfo = action.payload
      state.hasError = false
    })
    builder.addCase(getAllOrganisations.rejected, (state) => {
      state.hasError = true
      state.organisationLookupInfo = initialState.organisationLookupInfo
    })
  }
})

export * from './thunks'

export const {
  setJustification,
  setOrganisations,
  setFilters,
  setFiltersDisplayValue,
  reset,
  setExportError
} = slice.actions

export default slice.reducer
