import { createSlice } from '@reduxjs/toolkit'
import { getRejectionError } from '../../../../services/request'
import { SavedProfileSummary } from '../../../../types/api'
import { requestSavedProfiles } from './thunks'

export type SavedProfileState = {
  isFetching: boolean,
  totalRecords: number,
  records: SavedProfileSummary[],
  error: string | null,
  currentPage: number,
  pageSize: number
}

export const initialState: SavedProfileState = {
  isFetching: false,
  totalRecords: 0,
  records: [],
  error: null,
  currentPage: 1,
  pageSize: 20
}

export const SAVED_PROFILE = 'SavedProfile'

const slice = createSlice({
  name: SAVED_PROFILE,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(requestSavedProfiles.pending, (state) => {
      state.isFetching = true
      state.totalRecords = initialState.totalRecords
      state.records = initialState.records
      state.currentPage = initialState.currentPage
      state.error = initialState.error
    })
    builder.addCase(requestSavedProfiles.fulfilled, (state, action) => {
      const { data: { records, totalRecords } } = action.payload
      state.isFetching = false
      state.totalRecords = totalRecords || initialState.totalRecords
      state.records = records || initialState.records
      state.currentPage = action.meta.arg
      state.error = initialState.error
    })
    builder.addCase(requestSavedProfiles.rejected, (state, action) => {
      state.isFetching = false
      state.totalRecords = initialState.totalRecords
      state.records = initialState.records
      state.currentPage = initialState.currentPage
      state.error = getRejectionError(action, initialState.error)
    })
  }
})

export * from './thunks'

export default slice.reducer
