import { IUserOrganisationResponse } from '../../../../types/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAsyncThunkOptions } from '../../../../store'
import { requestErrorHandler } from '../../../../services/request'
import organisationService from '../../organisationService'

export const getUserOrganisations = createAsyncThunk<IUserOrganisationResponse, void, AppAsyncThunkOptions>
  ('Organisation/information/getUserOrganisations', async (_, { rejectWithValue }) => {
    try {
      const { data } = await organisationService.getUserOrganisations()
      return data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })