import React from 'react'
import { useAuthentication } from '../../../hooks/authentication'
import styled from 'styled-components'
import { logout } from '../state/keycloak/thunks'
import { useAppDispatch } from '../../../store'

const LogoutButton = styled.button`
  position: relative;
  top: -0.1em;
  background-color: #FFF;
  border: none;
  color: rgb(0, 94, 165);
  font-family: "Inter UI", sans-serif !important;
  font-size: 16px;
  padding-left: 1.5em;
  &: hover {
    color: rgb(43, 140, 196);
    cursor: pointer;
  }
`

const Logout = () => {

  const dispatch = useAppDispatch()

  const { logout: keycloakLogout } = useAuthentication()

  const onClick = () => {
    dispatch(logout(keycloakLogout))
  }

  return (
    <LogoutButton onClick={onClick}>
      Sign out
    </LogoutButton>
  )
}

export default Logout