import React, { FC } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

export const MaxWidthWrapper = styled.div`
  max-width: 30em;
`

type GovUKConfirmationProps = {
  heading: string
  message: string
  reference?: string
  type?: 'confirm-page' | 'incomplete'
}

const GovUKConfirmation: FC<GovUKConfirmationProps> = ({
  heading,
  message,
  reference,
  type = 'confirm-page'
}) => {
  const containerClasses = classNames('govuk-box-highlight', type)

  return (
    <MaxWidthWrapper className={containerClasses}>
      <span className="hod-checkmark move"></span>
      <h2 className="govuk-heading-m">
        {heading}
      </h2>
      <p>{message}</p>
      {reference && <p>
        Your reference number is:<br />
        <strong>{reference}</strong>
      </p>}
    </MaxWidthWrapper>
  )
}

export default GovUKConfirmation
