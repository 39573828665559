import { createSlice } from '@reduxjs/toolkit'
import * as suggestions from '../../../../state/suggestions/reducers'
import { getAccountActivityFilterSuggestions } from './thunks'

export const ACCOUNT_ACTIVITY_SUGGESTIONS = 'Users/Suggestions'

const slice = createSlice({
  name: ACCOUNT_ACTIVITY_SUGGESTIONS,
  initialState: suggestions.initialState,
  reducers: {
    clearSuggestions: suggestions.clearSuggestions,
    reset: suggestions.reset
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountActivityFilterSuggestions.fulfilled, (state, action) => {
      state.suggestions = state.suggestions.filter((item) => item.field !== action.payload.field)
      state.suggestions.push(action.payload)
    })
  }
})

export * from './thunks'

export const {
  clearSuggestions,
  reset
} = slice.actions

export default slice.reducer
