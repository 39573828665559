import { FilterWildcard } from '../types/api'
import fieldMappings from '../constants/passHolderFieldMappings'
import { getFriendlyName } from '../services/fieldMappings'
import { MenuFilter, CHECKBOX, TEXT, DATE, TERM } from '../types/filters'
import { yesNoRenderer, sourceTypeRenderer, nullRenderer } from '../services/filterRenderers'

const searchSideMenuFilters: MenuFilter[] = [
  {
    label: getFriendlyName('person.forename', fieldMappings),
    field: 'person.forename',
    aggregateField: 'person.forename.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: getFriendlyName('person.surname', fieldMappings),
    field: 'person.surname',
    aggregateField: 'person.surname.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    inputType: DATE,
    field: 'person.date_of_birth',
    label: getFriendlyName('person.date_of_birth', fieldMappings)
  },
  {
    label: getFriendlyName('full_addresses', fieldMappings),
    field: 'full_addresses.prefix',
    aggregateField: 'full_addresses',
    inputType: TEXT,
    filterType: TERM,
    wildcard: FilterWildcard.Contains
  },
  {
    label: 'Postcode',
    field: 'person.addresses.postcode.concatenated',
    aggregateField: 'person.addresses.postcode.concatenated',
    inputType: TEXT,
    filterType: TERM,
    minimumInputLength: 2
  },
  {
    label: getFriendlyName('person.nationality', fieldMappings),
    field: 'person.nationality',
    aggregateField: 'person.nationality.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: 'Organisation',
    field: 'pass.organisation_name.keyword',
    aggregateField: 'pass.organisation_name.keyword',
    inputType: CHECKBOX
  },
  {
    label: getFriendlyName('pass.active', fieldMappings),
    field: 'pass.active',
    inputType: CHECKBOX,
    displayFormatter: yesNoRenderer,
    showMissingValue: true
  },
  {
    label: getFriendlyName('pass.card_status', fieldMappings),
    field: 'pass.card_status.keyword',
    inputType: CHECKBOX,
    displayFormatter: nullRenderer,
    showMissingValue: true
  },
  {
    label: getFriendlyName('pass.company_name', fieldMappings),
    field: 'pass.company_name',
    aggregateField: 'pass.company_name.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: getFriendlyName('job_title', fieldMappings),
    field: 'job_title',
    aggregateField: 'job_title.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: 'Record type',
    field: 'source_type',
    aggregateField: 'source_type',
    inputType: CHECKBOX,
    displayFormatter: sourceTypeRenderer
  },
  {
    inputType: DATE,
    field: 'pass.start_date',
    label: getFriendlyName('pass.start_date', fieldMappings)
  },
  {
    inputType: DATE,
    field: 'pass.expiry_date',
    label: getFriendlyName('pass.expiry_date', fieldMappings)
  }
]

export default searchSideMenuFilters
