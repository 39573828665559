import { KeycloakTokenParsed } from 'keycloak-js'
import { AxiosRequestConfig } from 'axios'

import { GROUP_PREFIX, ID_ADMIN, SEARCH_AUDITOR, SEARCH_USER } from '../constants/groups'
import { OrganisationType, UserOrganisationSummary } from '../types/api'
import { ROLE_PREFIX } from '../constants/roles'

export interface IKeycloakToken extends KeycloakTokenParsed {
  groups: string[]
  org: string[]
  preferred_username: string
  identity_provider?: string
}

export const getIdentityProvider = (tokenParsed?: IKeycloakToken): string | undefined => {
  const identityProvider = tokenParsed && tokenParsed.identity_provider
  return identityProvider
}

export const forceTokenRefresh = async (updateToken: Function): Promise<void> => {
  const fiveMinutesInSeconds = 60 * 5
  await updateToken(fiveMinutesInSeconds)
}

export const isAccountConfigurationValid = (tokenParsed?: IKeycloakToken): boolean => {
  if (hasGroups(tokenParsed, ID_ADMIN) || hasGroups(tokenParsed, SEARCH_AUDITOR) || hasGroups(tokenParsed, SEARCH_USER)) {
    return hasOrganisation(tokenParsed)
  }
  return true
}

const getRoles = (tokenParsed?: IKeycloakToken): string[] => {
  const roles = (tokenParsed
    && tokenParsed.realm_access
    && tokenParsed.realm_access.roles) || []
  return roles
}

export const hasNoRoles = (tokenParsed?: IKeycloakToken): boolean => {
  const roles = getRoles(tokenParsed)
  const aphidsRoles = roles.filter((role: string) => role.startsWith(ROLE_PREFIX))
  return aphidsRoles.length === 0
}

export const hasRoles = (tokenParsed?: IKeycloakToken, ...inputRoles: string[]): boolean => {
  const roles = getRoles(tokenParsed)
  return inputRoles.some(inputRole => roles.some((role: string) => role === inputRole))
}

const getGroups = (tokenParsed?: IKeycloakToken): string[] => {
  const groups = tokenParsed?.groups || []
  return groups
}

export const hasNoGroups = (tokenParsed?: IKeycloakToken): boolean => {
  const groups = getGroups(tokenParsed)
  const aphidsGroups = groups.filter((group: string) => group.startsWith(GROUP_PREFIX))
  return aphidsGroups.length === 0
}

export const hasGroups = (tokenParsed?: IKeycloakToken, ...inputGroups: string[]): boolean => {
  const groups = getGroups(tokenParsed)
  return inputGroups.some(inputGroup => groups.some(group => group === inputGroup))
}

export const getOrganisations = (tokenParsed?: IKeycloakToken): string[] => {
  const userOrgCodes = (tokenParsed && tokenParsed.org) || []
  const userOrgCodesNoBlanks = userOrgCodes.filter((code: string) => code.trim().length > 0)
  return userOrgCodesNoBlanks
}

export const hasOrganisation = (tokenParsed?: IKeycloakToken): boolean => {
  const userOrgCodes = getOrganisations(tokenParsed)
  return userOrgCodes.length >= 1
}

export const hasSingleOrganisation = (tokenParsed?: IKeycloakToken): boolean => {
  const userOrgCodes = getOrganisations(tokenParsed)
  return userOrgCodes.length === 1
}

export const hasOrganisationType = (token: IKeycloakToken | undefined, organisations: UserOrganisationSummary[], type: OrganisationType): boolean => {
  const userOrgCodes = getOrganisations(token)

  if (userOrgCodes.length > 0 && !!type) {
    const types = organisations.filter((organisation) => userOrgCodes.includes(organisation.code))
      .map((organisation) => organisation.type)

    const match = types.find((t) => t.trim() === type.trim())
    return !!match
  }

  return false
}

export const attachTokenToRequest = (token: string | undefined, req: AxiosRequestConfig): void => {
  req.headers = {
    ...req.headers,
    ['APHIDS-Component']: 'PORTAL'
  }

  if (token) {
    req.headers.Authorization = 'Bearer ' + token
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode
  if (isIE11) {
    req.headers['Cache-control'] = 'no-store'
    req.headers['Pragma'] = 'no-cache'
    req.headers['Expires'] = 0
  }
}