import axios from 'axios'
import queryString from 'query-string'
import { SortOrder, PageResult, BatchSummary, Filter, Aggregate } from '../../types/api'
import { HistoryQueryRequest } from '../../types/history'
import _ from 'lodash'

const requestHistory = async (
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: SortOrder,
  filter?: Filter,
  aggregates?: Aggregate[]) => {

  const query: HistoryQueryRequest = { page, pageSize, sortBy, sortOrder }

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
    aggregates: _.isEmpty(aggregates) ? undefined : aggregates
  }

  const result = await axios.post<PageResult<BatchSummary>>(`/api/v1/batches/search?${queryString.stringify(query)}`, payload)
  return result.data
}

export default {
  requestHistory
}
