export enum DataSourceType {
  ACIC = 'ACIC',
  AIC = 'AIC',
  AIC_APPLICATION = 'AIC_APPLICATION',
  AIR_CARGO_CLEARANCE = 'AIR_CARGO_CLEARANCE',
  AV_SEC_INSTRUCTOR = 'AV_SEC_INSTRUCTOR',
  AV_SEC_VALIDATOR = 'AV_SEC_VALIDATOR',
  CIC = 'CIC',
  IRIC = 'IRIC',
  PORT_APPLICATION = 'PORT_APPLICATION',
  PORT_PASS = 'PORT_PASS',
}