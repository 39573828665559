import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Accessibility from '../modules/Accessibility/components/Accessibility'
import Audits from '../modules/Audits'
import BulkExport from '../modules/BulkExport'
import Feedback from '../modules/Feedback'
import PassHolder from '../modules/PassHolder'
import Providers from '../modules/Providers'
import Reports from '../modules/Reports'
import SavedProfiles from '../modules/SavedProfile'
import Search from '../modules/Search'
import Status from '../modules/Status'
import Training from '../modules/Training'
import Upload from '../modules/Upload'
import BulkSearch from '../modules/BulkSearch'
import CookiePolicy from './CookiePolicy'
import DefaultSuspense from './DefaultSuspense'
import Error from './Error'
import IPRestricted from './IPRestricted'
import Layout from './Layout'
import Loading from './Loading'
import NotAvailable from './NotAvailable'
import NotFound from './NotFound'
import TermsAndConditions from './TermsAndConditions'
import RepeatCrc from '../modules/RepeatCrc'
import AccountActivity from '../modules/AccountActivity'

const Login = lazy(() => import('../modules/Authorisation/components/Login'))
const NoOrganisation = lazy(() => import('./NoOrganisation'))

export const unauthenticatedLayout = (displayMenu?: boolean) => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={displayMenu} />}>
      <Route index element={<DefaultSuspense><Login /></DefaultSuspense>} />
      <Route path='cookies' element={<CookiePolicy />} />
      <Route path='feedback/*' element={<Feedback />} />
      <Route path='*' element={<DefaultSuspense><Login /></DefaultSuspense>} />
    </Route>
  </Routes>
)

export const noOrganisationLayout = (displayMenu?: boolean) => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={displayMenu} />}>
      <Route index element={<DefaultSuspense><NoOrganisation /></DefaultSuspense>} />
      <Route path='accessibility' element={<Accessibility />} />
      <Route path='cookies' element={<CookiePolicy />} />
      <Route path='terms' element={<TermsAndConditions />} />
      <Route path='feedback/*' element={<Feedback />} />
      <Route path='training/*' element={<Training />} />
      <Route path='*' element={<DefaultSuspense><NoOrganisation /></DefaultSuspense>} />
    </Route>
  </Routes>
)

export const noConfigLayout = () => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={false} />}>
      <Route index element={<Loading />} />
      <Route path='*' element={<Loading />} />
    </Route>
  </Routes>
)

export const errorLayout = (errorMessage: string) => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={false} />}>
      <Route index element={<Error message={errorMessage} />} />
      <Route path='status' element={<Status />} />
      <Route path='*' element={<Error message={errorMessage} />} />
    </Route>
  </Routes>
)

export const ipRestrictedLayout = () => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={false} />}>
      <Route index element={<IPRestricted />} />
      <Route path='*' element={<IPRestricted />} />
    </Route>
  </Routes>
)

export const authenticatedLayout = (displayMenu: boolean, defaultElement: JSX.Element) => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={displayMenu} />}>
      <Route index element={defaultElement} />
      <Route path='accessibility' element={<Accessibility />} />
      <Route path='training/*' element={<Training />} />
      <Route path='upload/*' element={<Upload />} />
      <Route path='search/*' element={<Search />} />
      <Route path='bulk-search/*' element={<BulkSearch />} />
      <Route path='saved-profiles/*' element={<SavedProfiles />} />
      <Route path='pass-holder/*' element={<PassHolder />} />
      <Route path='providers/*' element={<Providers />} />
      <Route path='audits/*' element={<Audits />} />
      <Route path='reports/*' element={<Reports />} />
      <Route path='cookies' element={<CookiePolicy />} />
      <Route path='terms' element={<TermsAndConditions />} />
      <Route path='feedback/*' element={<Feedback />} />
      <Route path='bulk-export/*' element={<BulkExport />} />
      <Route path='account-activity/*' element={<AccountActivity />} />
      <Route path='status/*' element={<Status />} />
      <Route path='repeatcrc/*' element={<RepeatCrc />} />
      <Route path='not-available' element={<NotAvailable />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  </Routes>
)

export const termsLayout = () => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={false} />}>
      <Route index element={<TermsAndConditions />} />
      <Route path='accessibility' element={<Accessibility />} />
      <Route path='cookies' element={<CookiePolicy />} />
      <Route path='feedback/*' element={<Feedback />} />
      <Route path='training/*' element={<Training />} />
      <Route path='terms' element={<TermsAndConditions />} />
      <Route path='*' element={<TermsAndConditions />} />
    </Route>
  </Routes>
)

export const loadingLayout = (displayMenu?: boolean) => (
  <Routes>
    <Route path='/' element={<Layout displayMenu={displayMenu} />}>
      <Route index element={<Loading />} />
    </Route>
  </Routes>
)
