import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthentication } from '../hooks/authentication'
import { updateUserProfileTerms } from '../modules/Authorisation/state/user'
import { logout } from '../modules/Authorisation/state/keycloak/thunks'
import { useAppDispatch, useAppSelector } from '../store'
import PageTitle from './PageTitle'
import { useScrollToTop } from '../hooks/window'
import { MaxWidthWrapper } from './govuk/GovUKConfirmation'
import GovUKButton from './govuk/GovUKButton'
import { IMPORT, IMPORT_REVIEW, SEARCH } from '../constants/roles'
import { hasRoles } from '../services/token'

const TermsAndConditions = () => {

  useScrollToTop()

  const dispatch = useAppDispatch()
  const { logout: keycloakLogout, tokenParsed } = useAuthentication()

  const onAcceptClick = async () => {
    await dispatch(updateUserProfileTerms())
  }

  const onRejectClick = async () => {
    dispatch(logout(keycloakLogout))
  }

  const { config } = useAppSelector((state) => state.config)
  const { userProfile } = useAppSelector((state) => state.user)

  const userCanAccessTrainingMaterial = hasRoles(tokenParsed, SEARCH) || hasRoles(tokenParsed, IMPORT) || hasRoles(tokenParsed, IMPORT_REVIEW)

  return (
    <>
      <PageTitle heading='Terms and conditions' title='Terms and conditions' />
      {config?.termsVersion && <span id='terms-version' className='govuk-body govuk-!-font-size-14'>Version: {config.termsVersion}</span>}
      <h2 className='govuk-heading-m'>Introduction to APHIDS and Security</h2>
      <p>Users are responsible for the day-to-day security operations of APHIDS. Your behaviour is important, adherence to these Security Operating Procedures will minimise the risk of a security incident occurring; APHIDS is an essential piece of IT infrastructure for the UK Government; it holds information that is vital to protection of UK PLC and therefore must be protected.</p>
      <p>Users of APHIDS must avoid any action that may bring their conduct into question:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>You are responsible for your own professional behaviour and, to ensure that you deliver to the highest standards possible.</li>
          <li>For Government Agents and Civil Servants, you are expected to carry out your role with dedication and with a commitment to the Civil Service and its core values of integrity, honesty, objectivity and impartiality.</li>
          <li>In 2013, Home Office introduced a zero-tolerance policy towards accessing any record on any IT system without a legitimate business need; to all cases of suspected fraud, corruption, bribery or theft – if the Home Office finds there is a case to answer, it will take disciplinary or legal action.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Breach or attempted breach of this SyOPs and inappropriate use of APHIDS will be taken seriously and may lead to administrative (i.e. APHIDS access removed), disciplinary action and a criminal prosecution.</p>
      <p>You will be asked to read and acknowledge reading this document every year.</p>

      <h2 className='govuk-heading-m'>Access to APHIDS</h2>
      <p>You are responsible for all actions performed using your account.  User accounts must only be used by the individual to whom the account is registered and passwords to the account should not be disclosed to any other individual.</p>
      <p>You may only look at, use, copy, or change information that you are entitled to for official purposes.</p>
      <p>APHIDS must not be used in ways which could compromise the security of the system itself or the data it holds.</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>You are not to breach or attempt to breach APHIDS security and system settings.</li>
          <li>You are not to use someone else’s system credentials (i.e. account and / or password) to gain access to the system.</li>
          <li>You must not import or run non-standard executable code on the system.</li>
        </ul>
      </MaxWidthWrapper>

      {userCanAccessTrainingMaterial &&
        <>
          <h2 className='govuk-heading-m' id='training'>Training</h2>

          <MaxWidthWrapper>
            <p>You can <Link className='training-link' to='/training'>find training material for the APHIDS portal here</Link>.</p>
          </MaxWidthWrapper>
        </>
      }

      <h2 className='govuk-heading-m'>System Security</h2>
      <p>You must take reasonable precautions to prevent the upload of infected data or malware to the APHIDS system.</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Data for uploading to APHIDS must only be taken from another official system. Any data that cannot have its origins traced to an official source must not be uploaded to APHIDS.</li>
          <li>Reasonable measures must be taken to ensure data uploaded is free from virus, malware, macro code and scripts that may attempt to compromise or degrade the APHIDS system. Where possible an anti-virus scan of candidate material should be completed prior to upload to APHIDS.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Users should be aware that all uploads to APHIDS are checked for malware and data will be quarantined if detections are made. Users should be aware that uploads are monitored and audited.</p>

      <h2 className='govuk-heading-m'>Account Security</h2>
      <p>You must take reasonable precautions to prevent unauthorised access to your APHIDS account.</p>
      <p>Use of single sign-on systems</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Single sign-on (SSO) allows user to use just one set of credentials to automatically gain access to multiple applications and services. Where possible APHIDS will establish trust with Identity and Access Management systems to allow SSO on APHIDS.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Multi-factor authentication will be used for APHIDS managed user credentials where SSO is not established.  To provide additional protection to a password a second factor, a one-time pin (OTP) will also need to be entered from an authentication app configured on a user’s mobile device. </p>
      <p>Creating a password:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Your password must have at least 15 alpha-numeric characters. We recommend the use of a passphrase that can be easily remembered. Users could adopt the ‘three random words’ technique suggested by the National Cyber Security Centre.</li>
          <li>Your password must be something suitably obscure that only you can recall. If using a password manager which includes a random password generator feature this should be used.</li>
          <li>You must NOT select a password that others can guess, such as family or friends’ names, dates, places, dates of birth, favourite colours or teams, or anything else that could be guessed by other people.</li>
          <li>Your password cannot be your username.</li>
          <li>A password blacklist is used to prevent users from selecting a common, low security password.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Securing your Password:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Avoid writing down your password as insecure storage increases the likelihood of it being compromised. Password managers where approved within you organisation can be used for the secure storage of passwords.</li>
          <li>Do not use your APHIDS password for any other system.</li>
          <li>Do not disclose your password to another person.</li>
          <li>Do not to allow any other person to use / share your account or use / share your password regardless of whether or not that other person is an authorised user.</li>
          <li>If you suspect that your password may have been compromised, you must change it immediately report this to APHIDS.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Multi-factor authentication:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Your work/personal mobile device should be used. You must not use a shared device.</li>
          <li>Mobile device should be configured to automatically lock after a short period of inactivity.</li>
          <li>Device must have user authentication turned enabled.</li>
          <li>Only authenticator apps listed within the APHIDS portal and account creation guide should be used. App should be installed from Google play store, Apple app store or your own organisations store.</li>
          <li>If the device is lost, stolen or replaced you must immediately report this to APHIDS.</li>
        </ul>
      </MaxWidthWrapper>

      <p>At the end of your session, you must logout from APHIDS. APHIDS will time-out after 30 mins of inactivity and 10 hours is the maximum session time permitted until the system forces you to re-authenticate.</p>

      <h2 className='govuk-heading-m'>Storage of Classified Data</h2>
      <p>The highest protective marking of information permitted on APHIDS is OFFICIAL (including OFFICIAL-SENSITIVE)</p>

      <h2 className='govuk-heading-m'>Disclosure of Information</h2>
      <p>Is there a justifiable business requirement to send the information?</p>
      <p>You must be confident that the recipient is who they say they are and that their email is correct.</p>
      <p>Sharing of any Government information that is OFFICIAL (including OFFICIAL-SENSITIVE) must only be done on a genuine ‘need to know’ basis (the recipient(s) must have a legitimate need to access the information you are sending them).</p>
      <p>Consider using handling instructions (optional with OFFICIAL, mandatory with OFFICIAL-SENSITIVE information).</p>
      <p>Keep information (sent) to a minimum.</p>
      <p>The use of encryption for any confidential personal or sensitive information.  There is not a requirement to encrypt every email that is sent outside of the secure network. Staff should apply a proportionate approach when considering sending personal information over the internet (e.g. accumulation of data, therefore heightening the need to encrypt).</p>
      <p>Disclosure is defined as being the act of revealing or uncovering. In an investigation, this would mean the act of you revealing material that you have gathered during your investigations to interested parties.</p>
      <p>APHIDS is an intelligence purposes only tool and any data required to be used in the evidential chain or criminal proceedings, should be acquired from the data originator. APHIDS only retains copies of data from a range of data providers, and therefore requests should be made to these originating providers and source systems.</p>
      <p>You must use official resources appropriately and in line with the rules and standards set out by the department.  Inappropriate use of APHIDS information asset is a disciplinary offence.</p>
      <p>Users of APHIDS should be aware that the system holds Personally identifiable information (PII) data; that is data than can be used to identify a specific individual. The European Union’s (EU) General Data Protection Regulation (GDPR) legislation which came into full effect on 25th May 2018, provides rules on processing or storing personal data of EU residents. Adherence to GDPR is critical to Home Office and is partner agencies since the legislation grants people more rights regarding how organisations handle their PII. GDPR imposes heavy fines for non-compliance and data breaches. It also requires that organisations report data breaches within a 72-hour window.</p>
      <p>Both OFFICIAL information and information that has an OFFICIAL-SENSITIVE handling requirement can be sent to external partners or members of the public outside of the government secure network as long as this is for business reasons. In such cases, consider the following:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Is there a justifiable business requirement to send the information?</li>
          <li>You must be confident that the recipient is who they say they are and that their email is correct.</li>
          <li>Sharing of any Government information that is OFFICIAL (including OFFICIAL-SENSITIVE) must only be done on a genuine ‘need to know’ basis (the recipient(s) must have a legitimate need to access the information you are sending them).</li>
          <li>Consider using handling instructions (optional with OFFICIAL, mandatory with OFFICIAL-SENSITIVE information).</li>
          <li>Keep information (sent) to a minimum.</li>
          <li>The use of encryption for any confidential personal or sensitive information.  There is not a requirement to encrypt every email that is sent outside of the secure network. Staff should apply a proportionate approach when considering sending personal information over the internet (e.g. accumulation of data, therefore heightening the need to encrypt).</li>
        </ul>
      </MaxWidthWrapper>

      <h2 className='govuk-heading-m'>Security Monitoring and System Audits</h2>
      <p>APHIDS reserves the right to monitor use of its system under various legislations:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Criminal Justice Act 1993: prohibits certain activities where persons have, or have had, by virtue of their position or former position, access to information that is not in the public domain and is considered price-sensitive in relation to security of information.</li>
          <li>the Telecommunications (Lawful Business Practice) (Interception of Communications) Regulations 2000.</li>
          <li>Data Protection Bill (2018), General Data Protection Regulations (2018), Data Protection Act (1998): Protection and use of personal identifiable information data.</li>
          <li>Official Secrets Acts 1911-1989.</li>
          <li>Computer Misuse Act 1990.</li>
        </ul>
      </MaxWidthWrapper>

      <p>You must accept that use of APHIDS is monitored.</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>APHIDS will log the users IP address and username for every interaction made on the system.</li>
        </ul>
      </MaxWidthWrapper>

      <p>Users of the system must avoid any action that may bring their conduct into question (e.g. actively / excessively searching for information); misuse of IT is a serious offence and is subject to disciplinary action.</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>Reiteration: You may only look at, use, copy, or change information that you are entitled to for official purposes.</li>
        </ul>
      </MaxWidthWrapper>

      <p>The following lists provides examples of when this right may be used, but is not an exhaustive list and is for guidance only:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>To ascertain compliance with regulatory or self-regulatory practices, or procedures relevant to the business (i.e. Civil Service values, the Border Force Code of Conduct and the Security Operating Procedures for this system).</li>
          <li>To detect or prevent crime.</li>
          <li>To maintain an adequate level of security for Home Office computer systems.</li>
          <li>To detect any computer viruses.</li>
          <li>To detect cyber-security breaches, compromises or other malicious activity.</li>
          <li>To detect misuse of any official equipment.</li>
        </ul>
      </MaxWidthWrapper>

      <p>The IT system automatically logs all events on a 24-hour basis, which includes timestamps of their use by an individual. No matter when or where these resources are utilised, the same standards of conduct apply, and the same disciplinary action will be taken if it becomes evident that resources have been used inappropriately.</p>

      <h2 className='govuk-heading-m'>Security incidents or breaches</h2>
      <p>A security incident is any event which either results in an actual security breach or creates potential for a security breach to occur. Users have a duty to report security issues. Users are to be vigilant for actual or potential security breaches.</p>
      <p>If you see or suspect any accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to APHIDS data you will notify those specified in Annex B as soon as practicable and, in any event, no later than 48 hours after becoming aware of such an incident.</p>
      <p>If you see or suspect misuse of APHIDS you should report it to your line manager in the first instance or contact those specified in Annex B if the severity of the incident warrants escalation.</p>

      <h2 className='govuk-heading-m'>By accepting these terms you agree that:</h2>
      <p>I have read and I agree to the APHIDS Security Operating Procedures.</p>
      <p>As an authorised user, I agree and understand that:</p>

      <MaxWidthWrapper>
        <ul className='govuk-list govuk-list--bullet'>
          <li>I must avoid any action that may bring my conduct into question.</li>
          <li>I am responsible for all actions performed using my account.</li>
          <li>I am not to breach or attempt to breach the APHIDS security settings.</li>
          <li>I am not to allow any other person to use or share my APHIDS account or use or share my passwords.</li>
          <li>I am not to use someone else’s system credentials (i.e. account and / or password) to gain access to the system.</li>
          <li>I must not load or attempt to load and use non-approved applications / software on APHIDS.</li>
          <li>I must not import or run non-standard executable code on the system.</li>
        </ul>
      </MaxWidthWrapper>

      <p>I will only look at, use, copy, or change information that I am entitled to for official purposes.</p>
      <p>I understand that Home Office has a zero-tolerance policy towards staff accessing any record on any IT system without a legitimate business need; to all cases of suspected fraud, corruption, bribery or theft – if the department finds there is a case to answer, it will take disciplinary or legal action.</p>
      <p>I understand and accept that inappropriate use of any APHIDS information asset is a disciplinary offence.</p>
      <p>I will only disclose APHIDS information for justified business reasons.</p>
      <p>I will inform my Line Manager when I no longer need my account on APHIDS.</p>

      {!userProfile?.hasAcceptedTerms && (
        <>
          <GovUKButton id='accept-button' className='govuk-!-margin-right-1' onClick={onAcceptClick}>Accept</GovUKButton>
          <GovUKButton id='reject-button' secondary onClick={onRejectClick}>Do not accept</GovUKButton>
        </>
      )}
    </>
  )
}

export default TermsAndConditions
