import { AppDispatch, AppThunk } from '../../../../store'
import authService from '../../../../services/authentication'
import { reset as organisationReset } from '../../../Organisation/state/information'
import { reset as userReset } from '../user'

export const logout = (idpLogout: Function): AppThunk => (dispatch: AppDispatch) => {
  authService.logout()
  idpLogout()

  dispatch(organisationReset())
  dispatch(userReset())
}
