import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { executePassHolderSearch, SearchResponse } from './thunks'
import { PassHolderSearchSummary } from '../../../../types/api'
import { reset, SEARCH_RESULTS } from './actions'
import { FilterState, initialState as initialFilterState } from '../../../../state/filter/reducers'
import * as filter from '../../../../state/filter/reducers'
import { saveProfile, unsaveProfile } from '../../../SavedProfile/state/savedProfile'
import { getRejectionError } from '../../../../services/request'

export type SearchResultsState = FilterState & {
  isFetching: boolean
  hasSearched: boolean
  terms: string
  termsError: string
  widened: boolean
  withFilters: boolean
  records: PassHolderSearchSummary[]
  totalRecords: number
  currentPage: number
  pageSize: number
  error: string | null
}

export const initialState: SearchResultsState = {
  ...initialFilterState,
  isFetching: false,
  hasSearched: false,
  termsError: '',
  terms: '',
  widened: false,
  withFilters: false,
  records: [],
  totalRecords: 0,
  currentPage: 1,
  pageSize: 20,
  error: null
}

const slice = createSlice({
  name: SEARCH_RESULTS,
  initialState,
  reducers: {
    setTermsError(state, action: PayloadAction<string>) {
      state.termsError = action.payload
    },
    setWidened(state, action: PayloadAction<boolean>) {
      state.widened = action.payload
    },
    setSearchTerms(state, action: PayloadAction<string>) {
      state.terms = action.payload
    },
    toggleSideMenuExpanded: filter.toggleSideMenuExpanded,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    removeBreadboxItem: filter.removeBreadboxItem,
    clearDateFilter: filter.clearDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuCheckboxSubFilterText: filter.setSideMenuCheckboxSubFilterText,
    setSideMenuDateFilter: filter.setSideMenuDateFilter
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
    builder.addCase(saveProfile.fulfilled, (state, action) => {
      const { id } = action.payload
      const { personId } = action.meta.arg
      const index = state.records.findIndex((passHolder) => passHolder.personId === personId)
      if (index > -1) {
        state.records[index].saved_id = id
      }
    })
    builder.addCase(unsaveProfile.fulfilled, (state, action) => {
      const { personId } = action.meta.arg
      const index = state.records.findIndex((passHolder) => passHolder.personId === personId)
      if (index > -1) {
        state.records[index].saved_id = undefined
      }
    })
    builder.addCase(executePassHolderSearch.pending, (state) => {
      state.isFetching = true
      state.withFilters = initialState.withFilters
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
      state.error = initialState.error
    })
    builder.addCase(executePassHolderSearch.fulfilled, (state, action: PayloadAction<SearchResponse>) => {
      const {
        result: { data: { records, totalRecords, metadata } },
        appliedFilters,
        saveAggregation,
        page
      } = action.payload

      const hasFilters = Object.keys(appliedFilters).length > 0
      const hasResults = totalRecords > 0

      state.termsError = initialState.termsError
      state.isFetching = false
      state.hasSearched = true
      state.withFilters = hasFilters
      state.records = records
      state.totalRecords = totalRecords
      state.currentPage = page
      state.error = initialState.error
      if (saveAggregation) {
        const aggregation = metadata.aggregations || {}
        state.sideMenuEntryTopMatches = aggregation
      }
      state.sideMenuExpandedStatus = hasFilters || hasResults
    })
    builder.addCase(executePassHolderSearch.rejected, (state, action) => {
      if (typeof action.payload == 'string') {
        state.termsError = action.payload
      } else {
        state.error = getRejectionError(action, initialState.error)
      }

      state.sideMenuEntryTopMatches = initialState.sideMenuEntryTopMatches
      state.isFetching = false
      state.withFilters = initialState.withFilters
      state.hasSearched = true
      state.records = initialState.records
    })
  }
})

export * from './thunks'

export * from './actions'

export const {
  setSideMenuDateFilter,
  setSideMenuCheckbox,
  setSideMenuCheckboxSubFilterText,
  addSelectedSuggestion,
  clearDateFilter,
  removeBreadboxItem,
  toggleSideMenuEntryExpanded,
  toggleSideMenuExpanded,
  setSearchTerms,
  setTermsError,
  setWidened
} = slice.actions

export default slice.reducer
