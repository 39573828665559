import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'
import { BULK_EXPORT_REVIEW } from '../../constants/roles'

const BulkExportComponent = lazy(() => import('./components/BulkExport'))

const BulkExport = () => (
  <Routes>
    <Route path='/*' element={
      <RoleCheck roles={[BULK_EXPORT_REVIEW]} render={() =>
        <DefaultSuspense>
          <BulkExportComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>
)

export default BulkExport
