import { setTermsError } from '.'
import searchService from '../../searchService'
import searchSideMenuFilters from '../../../../constants/searchSideMenuFilters'
import { getFilters } from '../../../../services/filters'
import { getAggregates } from '../../../../services/aggregates'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import {
  ClientErrorType,
  PassHolderSearchSummary,
  PageResult,
  Filter
} from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import errorService from '../../errorService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SEARCH_RESULTS } from './actions'
import { requestErrorHandler } from '../../../../services/request'

interface SearchRequest {
  page: number
  isNewTermSearch: boolean
}

export interface SearchResponse {
  result: PageResult<PassHolderSearchSummary>
  saveAggregation: boolean
  appliedFilters: Filter
  page: number
}

export const executePassHolderSearch = createAsyncThunk<SearchResponse, SearchRequest, AppAsyncThunkOptions>
  (`${SEARCH_RESULTS}/executePassHolderSearch`, async ({ isNewTermSearch, page }, { dispatch, getState, rejectWithValue }) => {
    const state = getState()

    const {
      results: {
        pageSize,
        terms,
        widened,
        sideMenuCheckboxStatus,
        sideMenuDateFilters,
        sideMenuTextMatchesStatus
      },
      justification: {
        justification
      }
    } = state.search

    const {
      sortBy,
      sortOrder
    } = state.sort

    const searchSortBy = sortBy[SortList.SearchResults]
    const searchSortOrder = sortOrder[SortList.SearchResults]

    const hasSearchTerms = !!terms && terms.trim().length > 0

    if (!hasSearchTerms) {
      dispatch(logClientError(ClientErrorType.PassHolderSearchTerms))
      return rejectWithValue('Enter a search term')
    }

    const saveAggregation = isNewTermSearch
    const filters = getFilters(searchSideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)
    const aggregates = getAggregates(saveAggregation, searchSideMenuFilters)

    try {
      const result = await searchService.submitPassHolderSearch(widened, terms, justification, searchSortBy, searchSortOrder, page, pageSize, filters, aggregates)
      return {
        result,
        saveAggregation,
        appliedFilters: filters,
        page
      }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const logClientError = createAsyncThunk<void, ClientErrorType, AppAsyncThunkOptions>
  (`${SEARCH_RESULTS}/logClientError`, async (type: ClientErrorType, { getState }) => {
    if (type === ClientErrorType.PassHolderSearchTerms) {
      const state = getState()

      const { justification } = state.search.justification

      const {
        sideMenuCheckboxStatus,
        sideMenuDateFilters,
        sideMenuTextMatchesStatus
      } = state.search.results
      const filters = getFilters(searchSideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)

      await errorService.logSearchTermsError(justification, filters)
    }
  })

export const updatePassHolderSearch = (): AppThunk => (dispatch, getState) => {
  const { terms } = getState().search.results

  const hasSearchTerms = !!terms && terms.trim().length > 0

  if (!hasSearchTerms) {
    dispatch(logClientError(ClientErrorType.PassHolderSearchTerms))
    dispatch(setTermsError('Enter a search term'))
    return
  }

  const page = 1
  const isNewTermSearch = false
  dispatch(executePassHolderSearch({ page, isNewTermSearch }))
}

export const changePage = (page: number): AppThunk => (dispatch) => {
  const isNewTermSearch = false
  dispatch(executePassHolderSearch({ page, isNewTermSearch }))
}
