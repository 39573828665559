import { createSlice } from '@reduxjs/toolkit'
import * as suggestions from '../../../../state/suggestions/reducers'
import { getAuditSearchSuggestions } from './thunks'

export const AUDIT_SUGGESTIONS = 'Audits/suggestions'

const slice = createSlice({
  name: AUDIT_SUGGESTIONS,
  initialState: suggestions.initialState,
  reducers: {
    clearSuggestions: suggestions.clearSuggestions,
    reset: suggestions.reset
  },
  extraReducers: (builder) => {
    builder.addCase(getAuditSearchSuggestions.fulfilled, (state, action) => {
      state.suggestions = state.suggestions.filter((item) => item.field !== action.payload.field)
      state.suggestions.push(action.payload)
    })
  }
})

export * from './thunks'

export const {
  clearSuggestions,
  reset
} = slice.actions

export default slice.reducer
