import uploadService from '../../uploadService'
import { uploadProgressHandler } from '../../../../services/uploadProgress'
import { uploadErrorHandler } from '../../../../services/uploadError'
import { setFileUploadProgress, setHasError } from '.'
import { AppAsyncThunkOptions } from '../../../../store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'
import { DataSourceType } from '../../../../constants/sourceTypes'

interface IUploadFileRequest {
  url: string
  fileName: string
  source: string
  sourceType: DataSourceType
}

export const uploadFile = createAsyncThunk<void, IUploadFileRequest, AppAsyncThunkOptions>
  ('Upload/uploadFile', async ({ url, fileName, source, sourceType }, { dispatch, rejectWithValue }) => {
    const file = await uploadService.getFileFromObjectURL(url)

    const onUploadProgress = uploadProgressHandler((percentCompleted: number) => {
      dispatch(setFileUploadProgress(percentCompleted))
    })

    const onUploadFail = uploadErrorHandler((hasError: boolean) => {
      dispatch(setHasError(hasError))
    })

    try {
      await uploadService.uploadFile(file, fileName, source, sourceType, onUploadProgress, 0, onUploadFail)
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error, (statusCode?: number) => {
        if (statusCode === 502 || statusCode === 401) {
          return 'If the upload took a long time, refresh your browser and try again'
        }
      }))
    }
  })
