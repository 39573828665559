import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKRadioGroup from '../../../components/govuk/GovUKRadioGroup'
import { RadioButton } from '../../../types/radios'
import GovUKTextArea from '../../../components/govuk/GovUKTextArea'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { Navigate } from 'react-router-dom'
import { submitAccessibilityFeedback, reset } from '../state/feedback'
import { useAppDispatch } from '../../../store'
import { AccessibilityFeedbackLocation, AccessibilityFeedbackReplyWanted } from '../../../types/api'
import PageTitle from '../../../components/PageTitle'
import GovUKTextInput from '../../../components/govuk/GovUKTextInput'
import { MAX_WIDTH } from '../../../constants/inlineStyles'
import GovUKButton from '../../../components/govuk/GovUKButton'

const messageInputName = 'feedback-message'
const pageInputName = 'feedback-page'
const messageErrorMessage = 'The message field cannot be empty'
const pageErrorMessage = 'The URL/name field cannot be empty'

const errorLinks: ErrorLinks = {
  [pageErrorMessage]: {
    anchorName: `#${pageInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLInputElement>()
  },
  [messageErrorMessage]: {
    anchorName: `#${messageInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  }
}

const AccessibilityFeedback = () => {

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const [location, setLocation] = useState(AccessibilityFeedbackLocation.WholeSite)
  const [page, setPage] = useState('')
  const [message, setMessage] = useState('')
  const [replyWanted, setReplyWanted] = useState(AccessibilityFeedbackReplyWanted.Yes)
  const [messageError, setMessageError] = useState('')
  const [pageError, setPageError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const errors = [pageError, messageError].filter((error) => error !== '')

  if (submitted) {
    return <Navigate to='/feedback/accessibility/confirmation' />
  }

  const textAreaColumns = 120
  const textAreaRows = 10
  const textAreaMaxLength = 1200

  const messageIsValid = (input: string): boolean => {
    return input.length > 0 && input.length <= textAreaMaxLength
  }

  const pageIsValid = (input: string): boolean => {
    return input.length > 0
  }

  const onLocationChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as AccessibilityFeedbackLocation
    if (value === AccessibilityFeedbackLocation.WholeSite) {
      setPage('')
      setPageError('')
    }
    setLocation(value)
  }

  const onPageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as AccessibilityFeedbackLocation
    if (pageIsValid(value)) {
      setPageError('')
    }
    setPage(value)
  }

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { target: { value } } = e
    if (messageIsValid(value)) {
      setMessageError('')
    }
    setMessage(value)
  }

  const onReplyWantedChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as AccessibilityFeedbackReplyWanted
    setReplyWanted(value)
  }

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    const messageValid = messageIsValid(message)
    const pageValid = location === AccessibilityFeedbackLocation.WholeSite || pageIsValid(page)

    if (messageValid === false) {
      setMessageError(messageErrorMessage)
    }
    if (pageValid === false) {
      setPageError(pageErrorMessage)
    }
    if (messageValid && pageValid) {
      const replyWantedBoolean = replyWanted === AccessibilityFeedbackReplyWanted.No ? false : true
      const specificLocation = location === AccessibilityFeedbackLocation.WholeSite ? 'The whole site' : page
      await dispatch(submitAccessibilityFeedback({ location: specificLocation, text: message, replyWanted: replyWantedBoolean }))
      setSubmitted(true)
    }
  }

  const locationRadioItems: RadioButton[] = [
    {
      id: AccessibilityFeedbackLocation.WholeSite,
      text: 'The whole site'
    },
    {
      id: AccessibilityFeedbackLocation.SpecificPage,
      text: 'A specific page'
    }
  ]

  const replyRadioItems: RadioButton[] = [
    {
      id: AccessibilityFeedbackReplyWanted.Yes,
      text: 'Yes'
    },
    {
      id: AccessibilityFeedbackReplyWanted.No,
      text: 'No'
    }
  ]

  return (
    <>
      <PageTitle heading='Get in touch' />

      <p>This form is for issues to do with the APHIDS website.</p>
      <p>You can use it to ask a question, report a problem or suggest an improvement to the APHIDS team.</p>

      {errors.length > 0 && <GovUKErrorSummary title='Please check the form' errors={errors} errorLinks={errorLinks} />}

      <form onSubmit={onSubmit}>
        <GovUKRadioGroup
          name='feedback-location'
          legend="What's it to do with?"
          items={locationRadioItems}
          selected={location}
          legendClass='govuk-fieldset__legend--s'
          onChange={onLocationChange}
        />

        {location === AccessibilityFeedbackLocation.SpecificPage
          && <GovUKTextInput
            name={pageInputName}
            hint='Enter URL or name of page'
            value={page}
            onChange={onPageChange}
            showLabel={false}
            showError={pageError.length > 0}
            errorMessage={pageError}
            wrapRef={errorLinks[pageErrorMessage].wrapRef}
            inputRef={errorLinks[pageErrorMessage].inputRef}
          />}

        <GovUKTextArea
          rows={textAreaRows}
          columns={textAreaColumns}
          name={messageInputName}
          label='What are the details?'
          hint='For example, if you were searching for something, what did you type into the search box?'
          value={message}
          showLabel={true}
          onChange={onMessageChange}
          maxLength={textAreaMaxLength}
          showError={messageError.length > 0}
          errorMessage={messageError}
          wrapRef={errorLinks[messageErrorMessage].wrapRef}
          inputRef={errorLinks[messageErrorMessage].inputRef}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <GovUKRadioGroup
          name='feedback-reply-wanted'
          legend='Do you want a reply?'
          items={replyRadioItems}
          selected={replyWanted}
          legendClass='govuk-fieldset__legend--s'
          onChange={onReplyWantedChange}
        />

        <div className='govuk-form-group'>
          <GovUKButton
            className='govuk-button'
            style={{ marginBottom: '0px' }}
            id='accessibility-feedback-submit'
            type='submit'>
            Submit
          </GovUKButton>
        </div>
      </form>
    </>
  )
}

export default AccessibilityFeedback
