import React, { FC } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  display: inline-block;
  margin-bottom: 25px;
`

type GovUKBackButtonProps = {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
  caption: string
}

const GovUKBackButton: FC<GovUKBackButtonProps> = ({
  onClick,
  caption
}) => {
  const clickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    onClick && onClick(event)
  }

  const testId = caption.toLowerCase().replace(/\s/g, '-')

  return (
    <StyledLink data-testid={testId} href='#0' onClick={clickHandler}>
      <span className='hod-chevron-left side-space icon-small'></span>
      {caption}
    </StyledLink>
  )
}

export default GovUKBackButton
