import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'
import { CONCISE_VIEW_UNJUSTIFIED, LINKED_PROFILE, SEARCH, SEARCH_UNJUSTIFIED } from '../../constants/roles'

const PassHolderComponent = lazy(() => import('./components/PassHolder'))
const CompareProfileComponent = lazy(() => import('./components/CompareProfile'))

const PassHolder = () =>
  <Routes>

    <Route path='/:personId/linked-profiles/:compareId' element={
      <RoleCheck roles={[LINKED_PROFILE]} render={() =>
        <DefaultSuspense>
          <CompareProfileComponent />
        </DefaultSuspense>
      } />
    } />
    <Route path='/:personId/*' element={
      <RoleCheck roles={[SEARCH, SEARCH_UNJUSTIFIED, CONCISE_VIEW_UNJUSTIFIED]} render={() =>
        <DefaultSuspense>
          <PassHolderComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>

export default PassHolder
