import axios from 'axios'
import queryString from 'query-string'
import {
  Filter,
  ClientErrorType,
  ApiResult
} from '../../types/api'
import _ from 'lodash'

interface LogErrorRequest {
  type: ClientErrorType
  justification?: string
}

const logSearchTermsError = async (justification?: string, filter?: Filter) => {
  const query: LogErrorRequest = {
    type: ClientErrorType.PassHolderSearchTerms
  }

  if (justification) {
    query.justification = justification
  }

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
  }

  const result = await axios.post<ApiResult>(`/api/v1/errors/?${queryString.stringify(query)}`, payload)
  return result.data
}

export default {
  logSearchTermsError
}
