import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

type GovUKNavLinkProps = {
  isCurrent?: boolean,
  path: string,
  title: string
}

const GovUKNavLink: FunctionComponent<GovUKNavLinkProps> = ({
  isCurrent = false, 
  path, 
  title
}) => {

  const isCurrentClass = classNames({"current-page": isCurrent })

  return (
    <li className={isCurrentClass}>
      <Link to={path} className="govuk-link">{title}</Link>
    </li>
  )
}

export default GovUKNavLink
