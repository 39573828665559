import axios from 'axios'
import {
  IHealthResponse,
  ApiSuccessResult
} from '../../types/api'

const getStatusInformation = async () => {
  const result = await axios.get<ApiSuccessResult<IHealthResponse>>(`/health/status`)
  return result.data
}

export default {
  getStatusInformation
}
