import axios, { AxiosRequestConfig } from 'axios'
import React from 'react'
import queryString from 'query-string'
import { RELEVANCE } from '../../constants/searchSortFields'
import {
  PageRequest,
  Aggregate,
  Filter,
  PassHolderSearchSummary,
  PageResult,
  SortRequest,
  SortOrder,
  FilterWildcard,
  PassHolderBulkSearchRecord,
  ApiSuccessResult
} from '../../types/api'
import _ from 'lodash'

interface SearchQueryRequest extends PageRequest, SortRequest {
  terms: string
  justification?: string
  widened: boolean
}

interface SearchSuggestionRequest {
  widened: boolean
  terms: string
  aggregateField: string
  searchField: string
  value: string
  wildcard?: FilterWildcard
}

interface BulkSearchQueryRequest extends PageRequest, SortRequest {
  justification?: string
  bulkSearchType?: string
}

interface BulkSearchSuggestionRequest {
  aggregateField: string
  searchField: string
  value: string
  wilcard?: FilterWildcard
}

const submitPassHolderSearch = async (
  widened: boolean,
  terms: string,
  justification?: string,
  sortBy?: string,
  sortOrder?: SortOrder,
  page?: number,
  pageSize?: number,
  filter?: Filter,
  aggregates?: Aggregate[]
) => {
  const query: SearchQueryRequest = {
    widened,
    terms,
    page,
    pageSize
  }

  if (justification) {
    query.justification = justification
  }

  if (sortBy && sortBy !== RELEVANCE) {
    query.sortBy = sortBy
    query.sortOrder = sortOrder || SortOrder.Asc
  }

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
    aggregates: _.isEmpty(aggregates) ? undefined : aggregates
  }

  const result = await axios.post<PageResult<PassHolderSearchSummary>>(`/api/v1/pass-holders/search?${queryString.stringify(query)}`, payload)
  return result.data
}

const getPassHolderSuggestions = async (
  widened: boolean,
  field: string,
  aggregateField: string,
  value: string,
  terms: string,
  wildcard?: FilterWildcard
) => {
  const query: SearchSuggestionRequest = {
    widened,
    terms,
    aggregateField,
    searchField: field,
    value
  }

  if (wildcard) {
    query.wildcard = wildcard
  }

  const result = await axios.get<ApiSuccessResult<string[]>>(`/api/v1/pass-holders/search/suggestions?${queryString.stringify(query)}`)
  return result.data
}

const uploadBulkSearchFile = async (
  file: Blob,
  onUploadProgress: (ProgressEvent: any) => void,
  justification?: string,
  sortBy?: string,
  sortOrder?: SortOrder,
  page?: number,
  pageSize?: number,
  aggregates?: Aggregate[],
  bulkSearchType?: string
) => {
  const form = new FormData()
  form.append('file', file)
  form.append('aggregates', JSON.stringify(aggregates))

  const query: BulkSearchQueryRequest = {
    page,
    pageSize
  }

  if (justification) {
    query.justification = justification
  }

  if (sortBy && sortBy !== RELEVANCE) {
    query.sortBy = sortBy
    query.sortOrder = sortOrder || SortOrder.Asc
  }

  query.bulkSearchType = bulkSearchType

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  }

  const result = await axios.post<PageResult<PassHolderSearchSummary>>(`/api/v1/pass-holders/bulk-search?${queryString.stringify(query)}`, form, config)
  return result.data
}

const updateBulkSearch = async (
  query: PassHolderBulkSearchRecord[],
  justification?: string,
  sortBy?: string,
  sortOrder?: SortOrder,
  page?: number,
  pageSize?: number,
  filter?: Filter,
  aggregates?: Aggregate[]
) => {

  const bulkSearchQuery: BulkSearchQueryRequest = {
    page,
    pageSize
  }

  if (justification) {
    bulkSearchQuery.justification = justification
  }

  if (sortBy && sortBy !== RELEVANCE) {
    bulkSearchQuery.sortBy = sortBy
    bulkSearchQuery.sortOrder = sortOrder || SortOrder.Asc
  }

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
    aggregates: _.isEmpty(aggregates) ? undefined : aggregates,
    json: query
  }

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const result = await axios.post<PageResult<PassHolderSearchSummary>>(`/api/v1/pass-holders/bulk-search?${queryString.stringify(bulkSearchQuery)}`, payload, config)
  return result.data
}

const getBulkSearchSuggestions = async (
  query: PassHolderBulkSearchRecord[],
  field: string,
  aggregateField: string,
  value: string,
  wildcard?: FilterWildcard
) => {
  const bulkSearchSuggestionQuery: BulkSearchSuggestionRequest = {
    aggregateField,
    searchField: field,
    value
  }

  if (wildcard) {
    bulkSearchSuggestionQuery.wilcard = wildcard
  }

  const result = await axios.post<ApiSuccessResult<string[]>>(`/api/v1/pass-holders/bulk-search/suggestions?${queryString.stringify(bulkSearchSuggestionQuery)}`, query)
  return result.data
}

const highlightValueForDisplay = (value: string) => {
  const regex = /<\s*em[^>]*>(.*?)<\s*\/\s*em>/gi
  let match
  let index = 0

  const output: JSX.Element[] = []
  let tagKey = 0

  if (!value) {
    return output
  }

  while ((match = regex.exec(value)) !== null) {
    if (match.index === regex.lastIndex) {
      regex.lastIndex++
    }

    const matchPosition = match.index
    const matchLength = match[0].length
    const matchContent = match[1]

    const preText = value.substr(index, matchPosition - index)
    if (preText && preText.length > 0) {
      output.push(<span key={tagKey++}>{value.substr(index, matchPosition - index)}</span>)
    }

    output.push(<b className="match" key={tagKey++}>{matchContent}</b>)

    index = matchPosition + matchLength
  }

  if (index < value.length) {
    const postText = value.substr(index)
    if (postText && postText.length > 0) {
      output.push(<span key={tagKey}>{value.substr(index)}</span>)
    }
  }

  return output
}

const MAXIMUM_JUSTIFICATION_LENGTH = 50
const MINIMUM_JUSTIFICATION_LENGTH = 4

const validateJustification = (value?: string) =>
  value !== undefined
  && value.trim().length >= MINIMUM_JUSTIFICATION_LENGTH
  && value.trim().length <= MAXIMUM_JUSTIFICATION_LENGTH

export default {
  MAXIMUM_JUSTIFICATION_LENGTH,
  MINIMUM_JUSTIFICATION_LENGTH,
  submitPassHolderSearch,
  getPassHolderSuggestions,
  uploadBulkSearchFile,
  updateBulkSearch,
  getBulkSearchSuggestions,
  highlightValueForDisplay,
  validateJustification
}
