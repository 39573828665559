import _ from 'lodash'
import { SearchHighlights } from '../types/api'

const subFieldNames = [
  '.keyword',
  '.concatenated',
  '.lowercase',
  '.text',
  '.synonym'
]

const getHighlightKeysForField = (highlights: SearchHighlights, field: string) => {
  const keys = _(highlights)
    .keys()
    .filter((key) => key.startsWith(field))
    .value()

  return keys
}

export const isFieldHighlighted = (highlights: SearchHighlights, field: string) => {
  if (!highlights) {
    return false
  }

  const keys = getHighlightKeysForField(highlights, field)

  return keys.length > 0
}

export const getHighlight = (highlights: SearchHighlights, field: string, defaultValue = ''): string => {
  let fieldHighlight = highlights[field]

  if (fieldHighlight && fieldHighlight.length > 0) {
    return fieldHighlight[0]
  }

  const keys = getHighlightKeysForField(highlights, field)

  if (keys.length > 0) {
    fieldHighlight = highlights[keys[0]]

    if (fieldHighlight && fieldHighlight.length > 0) {
      return fieldHighlight[0]
    }
  }

  return defaultValue
}

export const removeSubFields = (field: string): string => {
  let cleanField = field
  subFieldNames.forEach((segment) => cleanField = cleanField.replace(segment, ''))
  return cleanField
}

export const deduplicateFieldNames = (highlights: SearchHighlights, filteredFields: string[] = []): string[] => {
  return _(highlights)
    .keys()
    .filter((field) => !filteredFields.some((f) => field.startsWith(f)))
    .groupBy((field) => removeSubFields(field))
    .mapValues((value, key) => value.length === 1 ? value[0] : key)
    .values()
    .value()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFriendlyName = (field: string, fieldMappings: any = {}): string => {
  if (fieldMappings[field]) {
    return fieldMappings[field]
  }

  const parentObjectNames = [
    'person.',
    'pass.',
    'names.'
  ]

  let cleanField = removeSubFields(field)
  parentObjectNames.forEach((segment) => cleanField = cleanField.replace(segment, ''))

  if (fieldMappings[cleanField]) {
    return fieldMappings[cleanField]
  }

  const noUnderscores = cleanField.replace(/_/g, '.')
  const words = noUnderscores.split('.')

  const processed = words.map((word, index) => {
    if (index === 0) {
      return word[0].toUpperCase() + word.substr(1)
    }
    if (word === 'id') {
      return 'ID'
    }
    return word
  })

  return processed.join(' ')
}
