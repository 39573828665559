import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import GroupCheck from '../Authorisation/components/GroupCheck'
import { SUPPORT } from '../../constants/groups'
import NotFound from '../../components/NotFound'

const checkedGroups = [SUPPORT]

const StatusComponent = lazy(() => import('./components/Status'))

const Status = () =>
  <Routes>
    <Route path='/' element={
      <GroupCheck groups={checkedGroups} render={() =>
        <DefaultSuspense>
          <StatusComponent />
        </DefaultSuspense>} />
    } />
    <Route path="*" element={<NotFound />} />
  </Routes>

export default Status
