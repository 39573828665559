import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import AuthenticationProvider from './AuthenticationProvider'
import { persistor, store } from './store'

const App = () => (
  <HelmetProvider>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <Router>
          <AuthenticationProvider />
        </Router>
      </Provider>
    </PersistGate>
  </HelmetProvider>
)

export default App
