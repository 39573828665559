import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RepeatChecksAuditSummary } from '../../../../types/api'
import { CRCResponse, getRepeatCheckAudits, requestDownload } from './thunks'

export const REPEAT_CRC_RESULTS = 'RepeatCrc/results'

export type RepeatChecksResultsState = {
  totalRecords: number
  currentPage: number
  pageSize: number
  records: RepeatChecksAuditSummary[]
  fetching: boolean
  hasSearchError: boolean
  hasDownloadError: boolean
}

export const initialState: RepeatChecksResultsState = {
  currentPage: 1,
  totalRecords: 0,
  pageSize: 20,
  records: [],
  fetching: false,
  hasSearchError: false,
  hasDownloadError: false
}

const slice = createSlice({
  name: REPEAT_CRC_RESULTS,
  initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestDownload.rejected, (state) => {
      state.hasDownloadError = true
    })
    builder.addCase(requestDownload.pending, (state) => {
      state.hasDownloadError = false
    })
    builder.addCase(getRepeatCheckAudits.pending, (state) => {
      state.fetching = true
      state.hasSearchError = false
    })
    builder.addCase(getRepeatCheckAudits.rejected, (state) => {
      state.fetching = false
      state.hasSearchError = true
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
    })
    builder.addCase(getRepeatCheckAudits.fulfilled, (state, action: PayloadAction<CRCResponse>) => {
      const {
        page,
        pageSize,
        records,
        totalRecords
      } = action.payload

      state.fetching = false
      state.records = records
      state.totalRecords = totalRecords
      state.currentPage = page
      state.pageSize = pageSize
      state.hasSearchError = false
    })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
