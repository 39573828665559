const twoDigit = (number: number | string) => {
  const num = typeof number === 'string' ? parseInt(number, 10) : number
  return num < 10 && `${num}`.length === 1 ? '0' + num : num
}

const fourDigit = (number: number | string) => {
  const num = typeof number === 'string' ? parseInt(number, 10) : number

  if (`${num}`.length === 2) {
    return new Date().getFullYear().toString().substr(0, 2) + num
  }

  if (`${num}`.length === 3) {
    return '0' + num
  }

  return num
}

const formatDateFromFragments = (day: string, month: string, year: string) => {
  return `${twoDigit(day)}/${twoDigit(month)}/${fourDigit(year)}`
}

const formatDateFromDate = (date: Date) => {
  return `${twoDigit(date.getDate())}/${twoDigit(date.getMonth() + 1)}/${fourDigit(date.getFullYear())}`
}

// Input utcDate should be in format YYYY-MM-DDTHH:MM:SS with no timezone specified. Do NOT use ISO8601 formatted datetime strings.
const formatDateTimeFromUtcString = (utcDate: string) => {
  const date = new Date(utcDate)
  const offsetInMinutes = date.getTimezoneOffset()
  date.setUTCMinutes(date.getUTCMinutes() - offsetInMinutes)

  const formattedDate = formatDateFromDate(date)
  const formattedTime = `${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}:${twoDigit(date.getSeconds())}`
  const formattedDateTime = `${formattedDate} ${formattedTime}`

  return formattedDateTime
}

const isValidDate = (day: string, month: string, year: string) => {
  const containsOnlyNumbers = RegExp(/^[0-9]*$/)
  const yearIsFourCharacters = `${year}`.length === 4

  if (containsOnlyNumbers.test(day) === false
    || containsOnlyNumbers.test(month) === false
    || containsOnlyNumbers.test(year) === false
    || yearIsFourCharacters === false) {

    return false
  }

  const parsedDay = parseInt(day, 10)
  const parsedMonth = parseInt(month, 10)
  const parsedYear = parseInt(year, 10)

  const date = new Date(parsedYear, parsedMonth - 1, parsedDay)
  // If the date is not valid, the Date object will skip ahead, e.g. January 32nd = February 1st
  const calculatedDay = date.getDate()
  const calculatedMonth = date.getMonth() + 1
  const calculatedYear = date.getFullYear()
  return calculatedDay === parsedDay && calculatedMonth === parsedMonth && calculatedYear === parsedYear
}

export default {
  isValidDate,
  formatDateFromDate,
  formatDateFromFragments,
  formatDateTimeFromUtcString
}
