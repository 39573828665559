// Any changes to the field mappings must also be reflected in the HTML file used by the API for pass holder exports
export default {
  full_addresses: 'Full address',

  aphids_record_reference: 'APHIDS record reference',
  application_completion_date: 'Application completed',
  iata: 'Airport IATA',
  icao: 'Airport ICAO',
  'pass.organisation_name.keyword': 'Organisation',
  'pass.access.access_code': 'Access code',
  'pass.access.access_name': 'Access name',
  'pass.active': 'Active',
  'pass.aircrew_pass': 'Aircrew pass?',
  'pass.authoriser': 'Sponsor',
  'pass.card_serial': 'Card serial number',
  'pass.card_status': 'Status',
  'pass.expiry_date': 'Expiry date',
  'pass.has_previous_pass_owned': 'Has had a previous pass?',
  'pass.has_stolen_reported': 'Theft reported?',
  'pass.irt_reference': 'Incident response team reference',
  'pass.is_other_pass_owned': 'Has another pass?',
  'pass.is_parking_exempt': 'Parking exempt?',
  'pass.is_parked_pass': 'Parked?',
  'pass.is_pax_mode': 'PAX/OPS mode?',
  'pass.is_irt': 'Incident response team?',
  'pass.is_stolen_pass': 'Stolen?',
  'pass.is_tools_of_trade': 'Tools of trade?',
  'pass.last_activation_datetime': 'Last activation time',
  'pass.organisation_code': 'Organisation code',
  'pass.organisation_name': 'Organisation name',
  'pass.reference': 'Reference',
  'pass.start_date': 'Start date',
  'pass.stolen_reference_number': 'Theft reference number',
  'pass.previous_pass_reference': 'Previous reference',
  'pass.previous_pass_expiry_date': 'Previous expiry date',
  'pass.reason_for_access': 'Reason for access',

  'person.ac_expiry_date': 'UKSV accreditation check expiry date',
  'person.ac_clearance_number': 'UKSV accreditation check clearance number',
  'person.ac_issue_date': 'UKSV accreditation check obtained date',
  'person.ac_sponsor_number': 'UKSV accreditation check sponsor number',
  'person.addresses.current_address': 'Present address',
  'addresses.address_1': 'Address line 1',
  'addresses.address_2': 'Address line 2',
  'addresses.address_3': 'Address line 3',
  'addresses.address_4': 'Address line 4',
  'addresses.address_5': 'Address line 5',
  'person.addresses.address_date_from': 'Address date from',
  'person.addresses.address_date_to': 'Address date to',
  'person.addresses.postcode': 'Postcode',
  'person.crc_issue_country': 'CRC issuing country',
  'person.crc_obtained': 'CRC obtained?',
  'person.crc_overseas': 'Overseas CRC?',
  'current_address_1': 'Current address line 1',
  'current_address_2': 'Current address line 2',
  'current_address_3': 'Current address line 3',
  'current_address_4': 'Current address line 4',
  'current_address_5': 'Current address line 5',

  'person.identification.identification_type': 'Identification type',
  'person.identification.identification_number': 'Number',
  'person.identification.identification_expiry_date': 'Expiry date',
  'person.identification.identification_issue_date': 'Issue date',
  'person.identification.identification_issuing_country': 'Issuing country',
  'person.identification.identification_number_full': 'Identification number',
  'person.identification.identification_expiry_date_full': 'Identification expiry date',
  'person.identification.identification_issue_date_full': 'Identification issue date',
  'application.person.identification.identification_number': 'Identification Number',
  'application.person.identification.identification_expiry_date': 'Identification Expiry date',
  'application.person.identification.identification_issue_date': 'Identification Issue date',

  'person.is_visa_obtained': 'Has visa',
  'person.ni_number': 'UK National Insurance number',
  'person.previous_address_1': 'Previous address line 1',
  'person.previous_address_2': 'Previous address line 2',
  'person.previous_address_3': 'Previous address line 3',
  'person.previous_address_4': 'Previous address line 4',
  'person.previous_address_5': 'Previous address line 5',
  'person.reference': 'Person reference number',
  'person.uksv_subject_id': 'Clearance subject ID number',
  'person.vehicle_reg_number': 'Vehicle registration number',

  source_type: 'Record type',

  crc_last_check_date: 'Date last submitted for repeat criminal record check',
  access_history: "Access history"
}