import React from 'react'
import classNames, { Argument as ClassValue } from 'classnames'
import { InputChangeHandler } from '../../types/events'
import GovUKLabel from './GovUKLabel'
import GovUKHint from './GovUKHint'
import GovUKErrorMessage from './GovUKErrorMessage'
import GovUKInput from './GovUKInput'

type GovUKTextInputProps = {
  name: string
  hint?: string
  type?: string
  label?: string
  value?: string
  defaultValue?: string
  onChange?: InputChangeHandler
  disabled?: boolean
  showError?: boolean
  errorMessage?: string
  showLabel?: boolean
  inputStyle?: React.CSSProperties
  wrapStyle?: React.CSSProperties
  wrapRef?: React.RefObject<HTMLDivElement>
  inputRef?: React.RefObject<HTMLInputElement>
  maxLength?: number
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  labelClasses?: ClassValue
  inputClasses?: ClassValue
}

const GovUKTextInput = ({
  name,
  hint,
  label,
  type = 'text',
  value,
  defaultValue,
  onChange = () => { /* empty default */ },
  disabled = false,
  showError = false,
  errorMessage = '',
  showLabel = true,
  inputStyle = {},
  wrapStyle = {},
  wrapRef,
  inputRef,
  inputProps,
  labelClasses,
  inputClasses,
  maxLength
}: GovUKTextInputProps) => {

  const id = name.replace(/ /ig, '').toLowerCase()

  const formGroupId = `${id}-wrap`
  const hintId = `${id}-hint`
  const errorId = `${id}-error`

  const describedBy = `${(hint ? hintId : '')} ${(showError ? errorId : '')}`.trim()

  const formGroupClasses = classNames('govuk-form-group', {
    'govuk-form-group--error': showError
  })

  return (
    <div id={formGroupId} className={formGroupClasses} ref={wrapRef} style={wrapStyle}>
      <GovUKLabel text={label || name} extraClasses={labelClasses} visible={showLabel} htmlFor={id} />
      {hint && <GovUKHint id={hintId} hint={hint} />}
      {showError && <GovUKErrorMessage id={errorId} message={errorMessage} />}
      <GovUKInput
        id={id}
        style={inputStyle}
        describedBy={describedBy}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        ref={inputRef}
        extraClasses={inputClasses}
        maxLength={maxLength}
        type={type}
        showError={showError}
        extraProps={inputProps}
        onChange={onChange}
      />
    </div>
  )
}

export default GovUKTextInput
