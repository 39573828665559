import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import { MI_VIEW } from '../../constants/roles'
import RoleCheck from '../Authorisation/components/RoleCheck'

const AccountActivityComponent = lazy(() => import('./components/AccountActivity'))

const AccountActivity = () =>
  <Routes>
    <Route path='*' element={
      <DefaultSuspense>
        <RoleCheck roles={[MI_VIEW]} render={() => <AccountActivityComponent />} />
      </DefaultSuspense>
    } />
  </Routes>

export default AccountActivity
