import organisationService from '../../../Organisation/organisationService'
import { DataProviderSummary } from '../../../../types/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAsyncThunkOptions } from '../../../../store'
import { requestErrorHandler } from '../../../../services/request'

export const PROVIDERS_STATUS = 'Providers/status'

export const getDataProviderStatus = createAsyncThunk<DataProviderSummary[], void, AppAsyncThunkOptions>
  (`${PROVIDERS_STATUS}/getDataProviderStatus`, async (_, { rejectWithValue }) => {
    try {
      const result = await organisationService.getDataProviderStatus()
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
