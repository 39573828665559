import { combineReducers } from 'redux'
import history from './history'
import upload from './upload'
import issues from './issues'

export default combineReducers({
  history,
  upload,
  issues
})
