import React, { Suspense, FC } from 'react'
import Loading from './Loading'

type DefaultSuspenseProps = {
}

const DefaultSuspense: FC<DefaultSuspenseProps> = ({
  children
}) => {
  return <Suspense fallback={<Loading />}>
    {children}
  </Suspense>
}

export default DefaultSuspense
