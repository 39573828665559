import fieldMappings from './reportSearchFieldMappings'
import { MenuFilter, CHECKBOX, DATE_TIME } from '../types/filters'
import { getFriendlyName } from '../services/fieldMappings'

const reportSearchSideMenuFilters: MenuFilter[] = [
  {
    label: getFriendlyName('report_title', fieldMappings),
    field: 'report_title',
    aggregateField: 'report_title',
    inputType: CHECKBOX
  },
  {
    label: getFriendlyName('created_at', fieldMappings),
    field: 'created_at',
    inputType: DATE_TIME
  }
]

export default reportSearchSideMenuFilters
