import axios from 'axios'
import response from '../../../services/response'
import fileSaver from 'file-saver'
import { IBulkExportFilters } from './download'

interface postRequestBody {
  justification: string;
  organisationCodes: string[];
  filters?: IBulkExportFilters
}

const requestPreview = async (justification: string, organisationCodes: string[], filters: IBulkExportFilters = []) => {
  const body: postRequestBody = {
    justification,
    organisationCodes,
    filters,
  }

  const result = await axios.post('/api/v1/bulk-export/preview', body)
  return result.data;
}

const requestExport = async (justification: string, organisationCodes: string[], filters: IBulkExportFilters = []) => {
  const body: postRequestBody = {
    justification,
    organisationCodes,
    filters,
  }

  const result = await axios.post('/api/v1/bulk-export/export', body)
  return result.data
}

const download = async (exportId: string) => {
  const res = await axios.get(`/api/v1/bulk-export/${exportId}/download`, { responseType: 'arraybuffer' })
  const contentType = res.headers['content-type']

  const fileName = response.getFilenameFromContentDisposition(res, 'Bulk export.json')

  const blob = new Blob([res.data], { type: contentType })

  fileSaver.saveAs(blob, fileName)
}

export default {
  requestPreview,
  requestExport,
  download
}
