import React from 'react'
import { Navigate } from 'react-router-dom'
import { hasGroups, hasRoles, hasSingleOrganisation } from '../../../services/token'
import { useAuthentication } from '../../../hooks/authentication'

type RoleAndGroupCheckProps = {
  roles: string[]
  groups: string[]
  render: Function
  singleOrgOnly?: boolean
}

const RoleAndGroupCheck = ({
  roles,
  groups,
  render,
  singleOrgOnly = false
}: RoleAndGroupCheckProps) => {

  const { tokenParsed } = useAuthentication()

  return tokenParsed && ((hasRoles(tokenParsed, ...roles) && (!singleOrgOnly || hasSingleOrganisation(tokenParsed))) || hasGroups(tokenParsed, ...groups)) ? render() : <Navigate to='/not-found' />
}


export default RoleAndGroupCheck
