import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKRadioGroup from '../../../components/govuk/GovUKRadioGroup'
import { RadioButton } from '../../../types/radios'
import GovUKTextArea from '../../../components/govuk/GovUKTextArea'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { Navigate } from 'react-router-dom'
import { submitGeneralFeedback, reset } from '../state/feedback'
import { useAppDispatch } from '../../../store'
import { FeedbackScore } from '../../../types/api'
import PageTitle from '../../../components/PageTitle'
import { MAX_WIDTH } from '../../../constants/inlineStyles'
import GovUKButton from '../../../components/govuk/GovUKButton'

const scoreInputName = 'feedback-score'
const scoreRadioGroupIdentifier = `${scoreInputName}-wrap`
const scoreErrorMessage = 'The ratings field cannot be empty'

const errorLinks: ErrorLinks = {
  [scoreErrorMessage]: {
    anchorName: `#${scoreRadioGroupIdentifier}`,
    wrapRef: React.createRef<HTMLDivElement>(),
    inputRef: React.createRef<HTMLInputElement>()
  }
}

const GeneralFeedback = () => {

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const [score, setScore] = useState('')
  const [text, setText] = useState('')
  const [scoreError, setScoreError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return <Navigate to='/feedback/general/confirmation' />
  }

  const textAreaColumns = 120
  const textAreaRows = 10
  const textAreaMaxLength = 1200

  const scoreIsValid = (input: string): boolean => {
    if (!input || input.length === 0) {
      return false
    }
    try {
      const value = parseInt(input, 10)
      return value >= 1 && value <= 5
    } catch (e) {
      return false
    }
  }

  const textIsValid = (input: string): boolean => {
    return !input || input.length <= textAreaMaxLength
  }

  const onScoreChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as FeedbackScore
    if (scoreIsValid(value)) {
      setScoreError('')
      setScore(value)
    }
    else {
      setScoreError(scoreErrorMessage)
    }
  }

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { target: { value } } = e
    if (textIsValid(value)) {
      setText(value)
    }
  }

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    if (scoreIsValid(score)) {
      const scoreValue = parseInt(score)
      await dispatch(submitGeneralFeedback({ score: scoreValue, text }))
      setSubmitted(true)
    }
    else {
      setScoreError(scoreErrorMessage)
    }
  }

  const radioItems: RadioButton[] = [
    {
      id: '5',
      text: 'Very satisfied'
    },
    {
      id: '4',
      text: 'Satisfied'
    },
    {
      id: '3',
      text: 'Neither satisfied or dissatisfied'
    },
    {
      id: '2',
      text: 'Dissatisfied'
    },
    {
      id: '1',
      text: 'Very dissatisfied'
    }
  ]

  return (
    <>
      <PageTitle heading='Give feedback' />

      {scoreError && <GovUKErrorSummary title='Please check the form' errors={[scoreError]} errorLinks={errorLinks} />}

      <form onSubmit={onSubmit}>
        <GovUKRadioGroup
          name={scoreInputName}
          legend='Overall, how do you feel about the service you received today?'
          items={radioItems}
          selected={score}
          legendClass='govuk-fieldset__legend--s'
          onChange={onScoreChange}
          showError={scoreError.length > 0}
          errorMessage={scoreError}
          wrapRef={errorLinks[scoreErrorMessage].wrapRef}
          inputRef={errorLinks[scoreErrorMessage].inputRef}
        />

        <GovUKTextArea
          rows={textAreaRows}
          columns={textAreaColumns}
          name='feedback-text'
          label='How could we improve this service?'
          value={text}
          showLabel={true}
          onChange={onTextChange}
          maxLength={textAreaMaxLength}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <div className='govuk-form-group'>
          <GovUKButton
            className='govuk-button'
            style={{ marginBottom: '0px' }}
            id='general-feedback-submit'
            type='submit'>
            Submit
          </GovUKButton>
        </div>
      </form>
    </>
  )
}

export default GeneralFeedback
