import axios from 'axios'
import { HistoryQueryRequest } from '../../../types/history'
import { PageResult, BulkExportSummary, SortOrder } from '../../../types/api'
import queryString from 'query-string'

const requestHistory = async (page: number, pageSize: number, sortBy: string, sortOrder: SortOrder) => {
  const query: HistoryQueryRequest = { page, pageSize, sortBy, sortOrder }

  const result = await axios.post<PageResult<BulkExportSummary>>(`/api/v1/bulk-export/search?${queryString.stringify(query)}`)
  return result.data
}

export default {
  requestHistory
}
