import { PayloadAction } from '@reduxjs/toolkit'
import { Suggestion } from '../../types/Suggestion'

export type SuggestionsState = {
  suggestions: Suggestion[]
}

export const initialState: SuggestionsState = {
  suggestions: []
}

export const clearSuggestions = (state: SuggestionsState, action: PayloadAction<string>) => {
  state.suggestions = state.suggestions.filter((item) => item.field !== action.payload)
}

export const reset = () => {
  return initialState
}
