import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'
import { SEARCH, SEARCH_UNJUSTIFIED, REDACTED_SEARCH_UNJUSTIFIED } from '../../constants/roles'

const SearchComponent = lazy(() => import('./components/Search'))

const Search = () => (
  <Routes>
    <Route path='*' element={
      <DefaultSuspense>
        <RoleCheck roles={[SEARCH, SEARCH_UNJUSTIFIED, REDACTED_SEARCH_UNJUSTIFIED]} render={() => <SearchComponent />} />
      </DefaultSuspense>
    }
  />
  </Routes>
)

export default Search
