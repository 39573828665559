import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AuthClientInitOptions, AuthClientEvent } from '@react-keycloak/core'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import axios from 'axios'

import Routing from './Routing'
import { addHeadersToAllRequests, getWithBackoff } from './services/request'
import authService from './services/authentication'
import { errorLayout, noConfigLayout } from './components/Layouts'
import { useAppSelector } from './store'
import { setConfig, setConfigError } from './modules/Authorisation/state/config'
import { setHealth } from './modules/Authorisation/state/health'

const AuthenticationProvider = () => {
  const dispatch = useDispatch();
  const { config, error: configError } = useAppSelector((state) => state.config)

  useEffect(() => {
    getWithBackoff(getApplicationConfiguration, onFail);
    getWithBackoff(getHealthInformation, onFail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApplicationConfiguration = async () : Promise<string> => {
    try {
      const response = await axios.get('/api/v1/config/portal');
      const fetchedConfig = response.data.data;
      dispatch(setConfig(fetchedConfig));
      return '';
    } catch(error)  {
      return 'The application configuration could not be fetched.';
    }
  }

  const getHealthInformation = async () : Promise<string> => {
    try {
      const response = await axios.get('/health');
      const health = response.data.data;
      dispatch(setHealth(health))

      return '';
    } catch (error) {
      return 'The application health check could not be fetched.';
    }
  }

  const onFail = (errorMessage: string) : void => {
    dispatch(setConfigError(errorMessage))
  }

  if (!config) {
    return noConfigLayout();
  }

  if (configError) {
    return errorLayout(configError);
  }

  const keycloak = new Keycloak(config)

  const keycloakInitOptions: AuthClientInitOptions = {
    flow: 'hybrid',
    onLoad: 'check-sso',
    checkLoginIframe: false
  }

  const onKeycloakEvent = (event: AuthClientEvent) => {
    if (event === 'onAuthRefreshError') {
      authService.logout()
      keycloak.logout({
        redirectUri: window.location.origin
      })
    }
  }

  addHeadersToAllRequests(keycloak)

  return (
    <ReactKeycloakProvider
      initOptions={keycloakInitOptions}
      authClient={keycloak}
      onEvent={onKeycloakEvent}>
      <Routing />
    </ReactKeycloakProvider>
  )
}

export default AuthenticationProvider
