import { reset } from '../results/actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const BULKSEARCH_TYPE = 'BulkSearch/Type'

export type BulkSearchTypeState = {
  bulkSearchType: string
}

export const initialState: BulkSearchTypeState = {
  bulkSearchType: 'any-text'
}

const slice = createSlice({
  name: BULKSEARCH_TYPE,
  initialState,
  reducers: {
    setBulkSearchType(state, action: PayloadAction<string>) {
      state.bulkSearchType = action.payload || 'any-text'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  }
})

export const {
  setBulkSearchType
} = slice.actions

export default slice.reducer
