import { createSlice } from '@reduxjs/toolkit'
import { getDataProviderStatus, PROVIDERS_STATUS } from './thunks'
import { DataProviderSummary } from '../../../../types/api'
import { getRejectionError } from '../../../../services/request'

export type ProvidersStatusState = {
  error: string | null
  organisations: DataProviderSummary[]
}

export const initialState: ProvidersStatusState = {
  error: null,
  organisations: []
}

const slice = createSlice({
  name: PROVIDERS_STATUS,
  initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDataProviderStatus.fulfilled, (state, action) => {
      state.organisations = action.payload
      state.error = initialState.error
    })
    builder.addCase(getDataProviderStatus.pending, () => initialState)
    builder.addCase(getDataProviderStatus.rejected, (state, action) => {
      state.organisations = initialState.organisations
      state.error = getRejectionError(action, initialState.error)
    })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
