import feedbackService from '../../feedbackService'
import {
  SupportRequest,
  SupportFeedbackType,
  FeedbackConfirmation
} from '../../../../types/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAsyncThunkOptions } from '../../../../store'
import { requestErrorHandler } from '../../../../services/request'

interface IAccessibilityFeedback {
  location: string
  text: string
  replyWanted: boolean
}

export const submitAccessibilityFeedback = createAsyncThunk<FeedbackConfirmation, IAccessibilityFeedback, AppAsyncThunkOptions>
  ('Feedback/submitAccessibilityFeedback', async ({ location, text, replyWanted }, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitAccessibilityFeedback(location, text, replyWanted)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

interface IGeneralFeedback {
  score: number
  text: string
}

export const submitGeneralFeedback = createAsyncThunk<FeedbackConfirmation, IGeneralFeedback, AppAsyncThunkOptions>
  ('Feedback/submitGeneralFeedback', async ({ score, text }, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitGeneralFeedback(score, text)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

interface IIssueRequestFeedback {
  type: SupportFeedbackType
  text: string
}

export const submitIssueRequestFeedback = createAsyncThunk<FeedbackConfirmation, IIssueRequestFeedback, AppAsyncThunkOptions>
  ('Feedback/submitIssueRequestFeedback', async ({ type, text }, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitSupportIssueRequestFeedback(type, text)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

  export const submitOtherRequestFeedback = createAsyncThunk<FeedbackConfirmation, IIssueRequestFeedback, AppAsyncThunkOptions>
  ('Feedback/submitOtherRequestFeedback', async ({ type, text }, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitOtherRequestFeedback(type, text)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

interface IAccessFeedback {
  organisation: string
  reason: string
}

export const submitAccessFeedback = createAsyncThunk<FeedbackConfirmation, IAccessFeedback, AppAsyncThunkOptions>
  ('Feedback/submitAccessFeedback', async ({ organisation, reason }, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitSupportAccessFeedback(organisation, reason)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const submitCreateAccountRequest = createAsyncThunk<FeedbackConfirmation, SupportRequest, AppAsyncThunkOptions>
  ('Feedback/submitCreateAccountRequest', async (content, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitCreateAccountRequestFeedback(content)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const submitRemoveAccountRequest = createAsyncThunk<FeedbackConfirmation, SupportRequest, AppAsyncThunkOptions>
  ('Feedback/submitRemoveAccountRequest', async (content, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitRemoveAccountRequestFeedback(content)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const submitChangeOtpRequest = createAsyncThunk<FeedbackConfirmation, SupportRequest, AppAsyncThunkOptions>
  ('Feedback/submitChangeOtpRequest', async (content, { rejectWithValue }) => {
    try {
      const result = await feedbackService.submitChangeOtpRequestFeedback(content)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
