import organisationService from '../../../Organisation/organisationService'
import { AppAsyncThunkOptions } from '../../../../store'
import { BulkExportPreviewResult, OrganisationSummary } from '../../../../types/api'
import downloadService from '../downloadService'
import { requestHistory } from '../history/thunks'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isErrorAxiosError, requestErrorHandler } from '../../../../services/request'

interface IPreviewResponse {
  previewJson: string
  count: number | null
}

export const requestPreview = createAsyncThunk<IPreviewResponse, void, AppAsyncThunkOptions>
  ('BulkExport/requestPreview', async (_, { getState, rejectWithValue }) => {
    const state = getState()

    const { justification, organisations, filters } = state.bulkExport.download

    try {
      const result = await downloadService.requestPreview(justification, organisations, filters)

      const { preview, count } = result.data as BulkExportPreviewResult
      const previewJson: string = JSON.stringify(preview, undefined, 2)

      return {
        previewJson,
        count
      }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const getAllOrganisations = createAsyncThunk<OrganisationSummary[], void, AppAsyncThunkOptions>
  ('BulkExport/getAllOrganisations', async (_, { rejectWithValue }) => {
    try {
      const result = await organisationService.getAllOrganisations()
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const requestDownload = createAsyncThunk<void, string, AppAsyncThunkOptions>
  ('BulkExport/requestDownload', async (exportId, { dispatch, getState, rejectWithValue }) => {
    try {
      await downloadService.download(exportId)
    } catch (e) {
      const error = e as Error
      if (isErrorAxiosError(error)) {
        if (error.code === '404') {
          const state = getState()
          const { currentPage } = state.bulkExport.history

          dispatch(requestHistory(currentPage))
          return
        }
      }
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
