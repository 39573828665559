import { Aggregate } from '../types/api'
import { MenuFilter, CHECKBOX } from '../types/filters'

export const getAggregates = (saveAggregation: boolean, possibleFilters: MenuFilter[]): Aggregate[] =>
  saveAggregation
    ? possibleFilters
      .filter((filter) => filter.inputType === CHECKBOX)
      .map((filter) => {
        return {
          field: filter.aggregateField || filter.field,
          includeMissing: !!filter.showMissingValue
        }
      })
    : []