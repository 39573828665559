import { createSlice } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { AsyncThunk } from '@reduxjs/toolkit'
import { FeedbackConfirmation } from '../../../../types/api'
import { AppAsyncThunkOptions } from '../../../../store'
import { getRejectionError } from '../../../../services/request'

type FeedbackConfirmationThunk = AsyncThunk<FeedbackConfirmation, unknown, AppAsyncThunkOptions>

type RejectedAction = ReturnType<FeedbackConfirmationThunk['rejected']>
type FulfilledAction = ReturnType<FeedbackConfirmationThunk['fulfilled']>
type PendingAction = ReturnType<FeedbackConfirmationThunk['fulfilled']>

export const isPendingAction = (prefix: string) => (action: AnyAction): action is PendingAction => {
  return action.type.startsWith(prefix) && action.type.endsWith('/pending')
}

export const isFulfilledAction = (prefix: string) => (action: AnyAction): action is FulfilledAction => {
  return action.type.startsWith(prefix) && action.type.endsWith('/fulfilled')
}

export const isRejectedAction = (prefix: string) => (action: AnyAction): action is RejectedAction => {
  return action.type.startsWith(prefix) && action.type.endsWith('/rejected')
}


export type FeedbackState = {
  error: string | null
  referenceNumber: string
}

export const initialState: FeedbackState = {
  error: null,
  referenceNumber: ''
}

export const FEEDBACK = 'Feedback/feedback'

const slice = createSlice({
  name: FEEDBACK,
  initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction('Feedback/'), (state) => {
        state.error = initialState.error
      })
      .addMatcher(isFulfilledAction('Feedback/'), (state, action) => {
        state.error = initialState.error
        state.referenceNumber = action.payload.referenceNumber
      })
      .addMatcher(isRejectedAction('Feedback/'), (state, action) => {
        state.error = getRejectionError(action, initialState.error)
        state.referenceNumber = initialState.referenceNumber
      })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
