import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BULK_SEARCH, IMPORT, IMPORT_REVIEW, SEARCH } from '../../constants/roles'
import DefaultSuspense from '../../components/DefaultSuspense'
import { ACCOUNT_MANAGER } from '../../constants/groups'
import RoleAndGroupCheck from '../Authorisation/components/RoleAndGroupCheck'


const TrainingComponent = lazy(() => import('./components/Training'))

const Training = () =>
  <Routes>
    <Route path='/' element={
      <RoleAndGroupCheck roles={[SEARCH, IMPORT, IMPORT_REVIEW, BULK_SEARCH]} groups={[ACCOUNT_MANAGER]} render={() =>
        <DefaultSuspense>
          <TrainingComponent />
        </DefaultSuspense>} />
    } />
  </Routes>

export default Training