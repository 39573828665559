import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterState, initialState as initialFilterState } from '../../../../state/filter/reducers'
import { ReportSummary } from '../../../../types/api'
import * as filter from '../../../../state/filter/reducers'
import { executeReportSearch, SearchResponse } from './thunks'

export type ReportSearchState = FilterState & {
  isFetching: boolean
  totalRecords: number
  records: ReportSummary[]
  currentPage: number
  pageSize: number
  hasError: boolean
}

export const initialState: ReportSearchState = {
  ...initialFilterState,
  isFetching: false,
  totalRecords: 0,
  records: [],
  currentPage: 1,
  pageSize: 10,
  hasError: false
}

export const REPORTS_SEARCH = 'Reports/search'

const slice = createSlice({
  name: REPORTS_SEARCH,
  initialState,
  reducers: {
    reset() { return initialState },
    removeBreadboxItem: filter.removeBreadboxItem,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    clearDateFilter: filter.clearDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuDateFilter: filter.setSideMenuDateFilter
  },
  extraReducers: (builder) => {
    builder.addCase(executeReportSearch.pending, (state) => {
      state.isFetching = true
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
      state.hasError = false
    })
    builder.addCase(executeReportSearch.fulfilled, (state, action: PayloadAction<SearchResponse>) => {
      const {
        result: { data: { records, totalRecords, metadata } },
        saveAggregation,
        page
      } = action.payload

      state.isFetching = false
      state.records = records
      state.totalRecords = totalRecords
      state.currentPage = page
      state.hasError = false
      if (saveAggregation) {
        const aggregation = metadata.aggregations || {}
        state.sideMenuEntryTopMatches = aggregation
      }
    })
    builder.addCase(executeReportSearch.rejected, (state) => {
      state.sideMenuEntryTopMatches = initialState.sideMenuEntryTopMatches
      state.hasError = true
      state.isFetching = false
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
    })
  }
})

export * from './thunks'

export const {
  reset,
  toggleSideMenuEntryExpanded,
  setSideMenuDateFilter,
  setSideMenuCheckbox,
  addSelectedSuggestion,
  clearDateFilter,
  removeBreadboxItem,
} = slice.actions

export default slice.reducer
