import { combineReducers, AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import config from './modules/Authorisation/state/config'
import health from './modules/Authorisation/state/health'
import bulkSearch from './modules/BulkSearch/state'
import ingest from './modules/Upload/state'
import search from './modules/Search/state'
import crc from './modules/RepeatCrc/state'
import passHolder from './modules/PassHolder/state/passHolder'
import savedProfile from './modules/SavedProfile/state/savedProfile'
import audits from './modules/Audits/state'
import organisations from './modules/Organisation/state'
import reports from './modules/Reports/state'
import accountActivity from './modules/AccountActivity/state'
import { persistStore, persistReducer, PURGE, FLUSH, PAUSE, PERSIST, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createFilter } from 'redux-persist-transform-filter'
import feedback from './modules/Feedback/state/feedback'
import sort from './modules/Sort/state'
import bulkExport from './modules/BulkExport/state'
import training from './modules/Training/state'
import status from './modules/Status/state'
import user from './modules/Authorisation/state/user'
import providers from './modules/Providers/state'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const searchFilter = createFilter(
  'search',
  ['justification']
)

const bulkSearchFilter = createFilter(
  'bulkSearch',
  ['justification']
)

const passHolderFilter = createFilter(
  'passHolder',
  ['fromLocation']
)

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['search', 'bulkSearch', 'passHolder'],
  transforms: [
    searchFilter,
    bulkSearchFilter,
    passHolderFilter
  ]
}

const reducer = combineReducers({
  audits,
  bulkExport,
  training,
  config,
  health,
  feedback,
  ingest,
  organisations,
  passHolder,
  providers,
  savedProfile,
  search,
  crc,
  bulkSearch,
  sort,
  status,
  reports,
  user,
  accountActivity
})

interface Purge {
  type: typeof PURGE
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any = {}, action: Purge) => {
  if (action.type === PURGE) {
    state = undefined
  }

  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>

export type ApplicationState = RootState

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, AnyAction>

export type AppDispatch = typeof store.dispatch

export type AppAsyncThunkOptions = {
  state: ApplicationState
  dispatch: AppDispatch
  rejectValue: ApplicationError | string
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface ApplicationError {
  statusCode?: number
  message: string
}
