import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKTextArea from '../../../components/govuk/GovUKTextArea'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { reset, submitIssueRequestFeedback, submitOtherRequestFeedback } from '../state/feedback'
import { useAppDispatch } from '../../../store'
import PageTitle from '../../../components/PageTitle'
import { SupportFeedbackType } from '../../../types/api'
import { Navigate, useNavigate } from 'react-router-dom'
import { MAX_WIDTH } from '../../../constants/inlineStyles'
import GovUKButton from '../../../components/govuk/GovUKButton'
import GovUKBackButton from '../../../components/govuk/GovUKBackButton'

const messageInputName = 'feedback-message'
const messageErrorMessage = 'The message field cannot be empty'

const errorLinks: ErrorLinks = {
  [messageErrorMessage]: {
    anchorName: `#${messageInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  }
}

type SupportFeedbackDetailsProps = {
  backButtonPath: string
  backButtonText: string
  heading: string
  type: SupportFeedbackType
}

const SupportFeedbackDetails = ({
  backButtonPath,
  backButtonText,
  heading,
  type
}: SupportFeedbackDetailsProps) => {

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return <Navigate to='/feedback/support/confirmation' state={{ type }} />
  }

  const textAreaColumns = 120
  const textAreaRows = 10
  const textAreaMaxLength = 1200

  const messageIsValid = (input: string): boolean => {
    return input.length > 0 && input.length <= textAreaMaxLength
  }

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { target: { value } } = e
    if (messageIsValid(value)) {
      setMessageError('')
    }
    setMessage(value)
  }

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    if (messageIsValid(message)) {
      // Component used for both incidents and other request.
      if (type === SupportFeedbackType.Issue){
        await dispatch(submitIssueRequestFeedback({ type, text: message }))
      } else {
        await dispatch(submitOtherRequestFeedback({ type, text: message }))
      }
      setSubmitted(true)
    }
    else {
      setMessageError(messageErrorMessage)
    }
  }

  return (
    <>
      <GovUKBackButton caption={backButtonText} onClick={() => { navigate(backButtonPath) }} />

      <PageTitle heading={heading} />

      {messageError && <GovUKErrorSummary title='Please check the form' errors={[messageError]} errorLinks={errorLinks} />}

      <form onSubmit={onSubmit}>
        <GovUKTextArea
          rows={textAreaRows}
          columns={textAreaColumns}
          name={messageInputName}
          label='Your message'
          value={message}
          showLabel={true}
          onChange={onMessageChange}
          maxLength={textAreaMaxLength}
          showError={messageError.length > 0}
          errorMessage={messageError}
          wrapRef={errorLinks[messageErrorMessage].wrapRef}
          inputRef={errorLinks[messageErrorMessage].inputRef}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <div className='govuk-form-group'>
          <GovUKButton
            style={{ marginBottom: '0px' }}
            id='support-feedback-submit'
            type='submit'>
            Submit
          </GovUKButton>
        </div>
      </form>
    </>
  )
}

export default SupportFeedbackDetails
