const isRouteActive = (route: string, paths: Array<string | RegExp> = [], fromState?: string, from?: string) => {
  let matched = false

  for (const path of paths) {
    if (typeof path === 'string') {
      if (route === path) {
        matched = true
        break
      }
    } else {
      const matches = new RegExp(path).exec(route)

      if (matches !== null
        && matches.length > 0 // There must be at least one match
        && matches.index === 0 // The first match must be at the start of the route 
        && matches[0].length === route.length) { // The match account for the full route
        if (fromState || from) {
          matched = fromState === from
          break
        }
        else {
          matched = true
          break
        }
      }
    }
  }

  return matched
}

export default {
  isRouteActive
}