import React from 'react'
import { useAppSelector } from '../../../store'
import { SupportFeedbackType, SupportRequestType } from '../../../types/api'
import FeedbackConfirmation from './FeedbackConfirmation'
import { useLocation } from 'react-router-dom'
import PageTitle from '../../../components/PageTitle'

type RouteState = {
  type: SupportFeedbackType
}

export const errorTitle = {
  [SupportFeedbackType.Issue]: 'There was an error reporting the problem',
  [SupportFeedbackType.Request]: 'There was an error submitting the request',
  [SupportFeedbackType.Access]: 'There was an error requesting access',
  [SupportRequestType.CreateAccount]: 'There was an error requesting account creation',
  [SupportRequestType.RemoveAccount]: 'There was an error requesting account removal'
}

export const pageHeading = {
  [SupportFeedbackType.Issue]: 'Report a problem',
  [SupportFeedbackType.Request]: 'Send a request',
  [SupportFeedbackType.Access]: 'Request access',
  [SupportRequestType.CreateAccount]: 'Create account',
  [SupportRequestType.RemoveAccount]: 'Remove account',
  [SupportRequestType.ChangeOTPDevice]: 'Change OTP device'
}

export const confirmationHeading = {
  [SupportFeedbackType.Issue]: 'Problem reported',
  [SupportFeedbackType.Request]: 'Request received',
  [SupportFeedbackType.Access]: 'Access request received',
  [SupportRequestType.CreateAccount]: 'Account creation request received',
  [SupportRequestType.RemoveAccount]: 'Account removal request received',
  [SupportRequestType.ChangeOTPDevice]: 'Change OTP device request received'
}

export const confirmationMessage = {
  [SupportFeedbackType.Issue]: 'Thank you for reporting a problem with APHIDS',
  [SupportFeedbackType.Request]: 'Thank you for your request with regards to APHIDS',
  [SupportFeedbackType.Access]: 'Thank you for requesting access to APHIDS',
  [SupportRequestType.CreateAccount]: 'Thank you for requesting creation of an account on APHIDS',
  [SupportRequestType.RemoveAccount]: 'Thank you for requesting removal of an account on APHIDS',
  [SupportRequestType.ChangeOTPDevice]: 'Thank you for requesting to change OTP device of an account on APHIDS'
}

const SupportFeedbackConfirmation = () => {

  const {
    error,
    referenceNumber
  } = useAppSelector((state) => state.feedback)

  const location = useLocation()

  const { type } = location.state as RouteState || {}

  return (
    <>
      <PageTitle heading={pageHeading[type]} />

      <FeedbackConfirmation
        heading={confirmationHeading[type]}
        message={confirmationMessage[type]}
        referenceNumber={referenceNumber}
        error={error}
        errorTitle={errorTitle[type]}
      />
    </>
  )
}

export default SupportFeedbackConfirmation
