import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'
import { IMPORT, IMPORT_REVIEW } from '../../constants/roles'

const UploadComponent = lazy(() => import('./components/Upload'))

const Upload = () =>
  <Routes>
    <Route path='*' element={
      <RoleCheck roles={[IMPORT, IMPORT_REVIEW]} render={() =>
        <DefaultSuspense>
          <UploadComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>

export default Upload
