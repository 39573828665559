import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPortalConfig } from '../../../../types/api'

export const AUTHORISATION_CONFIG = 'Authorisation/config'

export type ConfigState = {
  config: IPortalConfig | null,
  error: string
}

export const initialState: ConfigState = {
  config: null,
  error: ''
}

const slice = createSlice({
  name: AUTHORISATION_CONFIG,
  initialState: initialState,
  reducers: {
    setConfig(state, action: PayloadAction<IPortalConfig>) {
      state.config = action.payload
      state.error = initialState.error
    },
    setConfigError(state, action: PayloadAction<string>) {
      state.error = action.payload
    }
  }
})

export const {
  setConfig,
  setConfigError
} = slice.actions

export default slice.reducer
