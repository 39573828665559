import {
  library
} from '@fortawesome/fontawesome-svg-core'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import {
  faCheck,
  faTimes,
  faExclamation,
  faPlus,
  faMinus,
  faSortUp,
  faSortDown,
  faInfo,
  faStar as faStar
} from '@fortawesome/free-solid-svg-icons'

library.add(faCheck)
library.add(faTimes)
library.add(faExclamation)

library.add(faPlus)
library.add(faMinus)

library.add(faSortDown)
library.add(faSortUp)

library.add(faInfo)

library.add(faStar)
library.add(farStar)
