import { ApiFailurePayload, ApiResult, isFailure } from '../types/api'
import axios, { AxiosError } from 'axios'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

import { attachTokenToRequest } from './token'
import { ApplicationError } from '../store'

export type ApiFailureMessageHandler = (statusCode?: number, message?: string) => string | void

const getErrorDataFromAxiosError = (error: AxiosError) => {
  let statusCode
  let message

  // Axios should give us a response object
  if (error && error.response) {
    // If the response data is an object it could be our API response
    if (error.response.data) {
      const data = error.response.data as ApiResult
      if (isFailure<ApiFailurePayload>(data)) {
        const responseData = data
        statusCode = responseData.statusCode
        message = responseData.data.reason || ''
      }
    }

    // If we have no status code then try the response
    if (!statusCode) {
      statusCode = error.response.status
    }
  }

  // Fall back to the error object for a message
  if (!message) {
    message = error.message
  }

  // Fall back to the error object for a status code
  if (!statusCode && error.code) {
    statusCode = parseInt(error.code, 10)
  }

  // Handle global special cases
  if (statusCode === 401 && message === 'Invalid JWT') {
    message = 'Refresh your browser and try again'
  }
  else if (message === 'Network Error') {
    message = 'Check your network connection'
  }

  return {
    statusCode,
    message
  }
}

export const isErrorAxiosError = (error: SerializedError): error is AxiosError => {
  return (error as AxiosError).isAxiosError
}

export const requestErrorHandler = (error: SerializedError | Error, messageHandler?: ApiFailureMessageHandler): ApplicationError => {
  const {
    message,
    statusCode
  } = isErrorAxiosError(error)
      ? getErrorDataFromAxiosError(error)
      : { message: error.message, statusCode: undefined }

  if (messageHandler) {
    const customMessage = messageHandler(statusCode, message)
    if (customMessage) {
      return {
        message: customMessage,
        statusCode
      }
    }
  }

  return {
    message: message || 'An error occurred',
    statusCode
  }
}

export const addHeadersToAllRequests = (keycloak: Keycloak.KeycloakInstance) =>
  axios.interceptors.request.use(req => {
    const twoMinutesInSeconds = 60 * 2

    const { idToken, refreshToken, updateToken } = keycloak

    if (!refreshToken) {
      attachTokenToRequest(idToken, req)
      return req
    }

    return updateToken(twoMinutesInSeconds)
      .then(() => {
        attachTokenToRequest(idToken, req)
        return Promise.resolve(req)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }, error => {
    return Promise.reject(error)
  })

export const getWithBackoff = async (requestFunction: () => Promise<string>, onFail: (message: string) => void, retries = 10, interval = 500): Promise<void> => {
  const message: string = await requestFunction();
  if (message) {
    onFail(message);
    if (retries >= 0) {
      setTimeout(() => {
        getWithBackoff(requestFunction, onFail, retries - 1, interval * 2)
      }, interval)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRejectionError = (action: PayloadAction<string | ApplicationError | undefined, any, any, SerializedError>, initial: string | null): string | null => {
  if (typeof action.payload === 'string') {
    return action.payload
  } else if (action.payload) {
    return action.payload.message
  }
  return action.error.message || initial
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRejectionErrorAndCode = (action: PayloadAction<string | ApplicationError | undefined, any, any, SerializedError>, initial: ApplicationError | null): ApplicationError | null => {
  if (typeof action.payload === 'string') {
    return { message: action.payload }
  }

  if (action.payload) {
    return { message: action.payload.message, statusCode: action.payload.statusCode }
  }

  if (action.error) {
    return requestErrorHandler(action.error)
  }

  return initial
}
