import axios from 'axios'
import { RepeatChecksAuditSummary, PageResult } from '../../types/api'
import queryString from 'query-string'
import response from '../../services/response'
import fileSaver from 'file-saver'

const path = '/api/v1/repeat-checks'

const getAllRepeatChecksAudits = async (
  page: number,
  pageSize: number
) => {

  const result = await axios.post<PageResult<RepeatChecksAuditSummary>>(`${path}/search?${queryString.stringify({
    page,
    pageSize
  })}`)
  return result.data
}

const requestDownload = async (
  exportId: string
) => {
  const url = `${path}/${exportId}/download`
  const result = await axios.get(url)
  const contentType = result.headers['content-type']

  const fileName = response.getFilenameFromContentDisposition(result, 'export-repeat-checks.csv')

  const blob = new Blob([result.data], { type: contentType })

  fileSaver.saveAs(blob, fileName)

}

export default {
  getAllRepeatChecksAudits,
  requestDownload
}
