import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import NotFound from '../../components/NotFound'
import { SEARCH, SEARCH_UNJUSTIFIED } from '../../constants/roles'
import RoleCheck from '../Authorisation/components/RoleCheck'

const SavedProfileComponent = lazy(() => import('./components/SavedProfiles'))

const SavedProfiles = () =>
  <Routes>
    <Route path='/*' element={
      <RoleCheck roles={[SEARCH, SEARCH_UNJUSTIFIED]} render={() =>
        <DefaultSuspense>
          <SavedProfileComponent />
        </DefaultSuspense>
      } />
    } />
    <Route path='*' element={<NotFound />} />s
  </Routes>

export default SavedProfiles
