import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getRejectionError } from '../../../../services/request'
import { uploadFile } from './thunks'
import { DataSourceType } from '../../../../constants/sourceTypes'

export type UploadFile = {
  url: string
  fileName: string
}

export type UploadUploadState = {
  sourceType: DataSourceType | null
  fileToUpload: UploadFile | null
  fileUploadProgress: number
  showUploadProgress: boolean
  displayConfirmationMessage: boolean
  errorMessage: string | null
  hasError: boolean | null
}

export const initialState: UploadUploadState = {
  sourceType: null,
  fileToUpload: null,
  fileUploadProgress: 0,
  showUploadProgress: false,
  displayConfirmationMessage: false,
  errorMessage: null,
  hasError: null
}

export const UPLOAD_UPLOAD = 'Upload/upload'

const slice = createSlice({
  name: UPLOAD_UPLOAD,
  initialState,
  reducers: {
    reset(state) {
      if (state.fileToUpload !== null && state.fileToUpload.url) {
        window.URL.revokeObjectURL(state.fileToUpload.url)
      }
      return initialState
    },
    setFileToUpload(state, action: PayloadAction<UploadFile>) {
      state.fileToUpload = action.payload
    },
    setSourceType(state, action: PayloadAction<DataSourceType>) {
      state.sourceType = action.payload
    },
    setFileUploadProgress(state, action: PayloadAction<number>) {
      state.fileUploadProgress = action.payload
    },
    setShowUploadProgress(state, action: PayloadAction<boolean>) {
      state.showUploadProgress = action.payload
    },
    setHasError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state) => {
      state.showUploadProgress = true
    })
    builder.addCase(uploadFile.fulfilled, (state) => {
      if (state.fileToUpload !== null && state.fileToUpload.url) {
        window.URL.revokeObjectURL(state.fileToUpload.url)
      }
      return {
        ...initialState,
        displayConfirmationMessage: true
      }
    })
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.sourceType = initialState.sourceType
      state.fileUploadProgress = initialState.fileUploadProgress
      state.showUploadProgress = initialState.showUploadProgress
      if (state.fileToUpload !== null && state.fileToUpload.url) {
        window.URL.revokeObjectURL(state.fileToUpload.url)
        state.fileToUpload = initialState.fileToUpload
      }
      state.displayConfirmationMessage = true
      state.errorMessage = getRejectionError(action, initialState.errorMessage)
    })
  }
})

export * from './thunks'

export const {
  reset,
  setFileToUpload,
  setSourceType,
  setFileUploadProgress,
  setShowUploadProgress,
  setHasError
} = slice.actions

export default slice.reducer
