import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import GeneralFeedback from './components/GeneralFeedback'
import GeneralFeedbackConfirmation from './components/GeneralFeedbackConfirmation'
import AccessibilityFeedback from './components/AccessibilityFeedback'
import AccessibilityFeedbackConfirmation from './components/AccessibilityFeedbackConfirmation'
import SupportFeedback from './components/SupportFeedback'
import SupportFeedbackIssue from './components/SupportFeedbackIssue'
import SupportFeedbackAccess from './components/SupportFeedbackAccess'
import SupportFeedbackConfirmation from './components/SupportFeedbackConfirmation'
import { useAuthentication } from '../../hooks/authentication'
import { useScrollToTop } from '../../hooks/window'
import Login from '../Authorisation/components/Login'
import SupportFeedbackRequest from './components/SupportFeedbackRequest'
import SupportFeedbackRequestOther from './components/SupportFeedbackRequestOther'
import SupportFeedbackRequestAccountCreate from './components/SupportFeedbackRequestAccountCreate'
import SupportFeedbackRequestAccountRemove from './components/SupportFeedbackRequestAccountRemove'
import RoleCheck from '../Authorisation/components/RoleCheck'
import { CREATE_ACCOUNT_REQUESTOR, REMOVE_ACCOUNT_REQUESTOR } from '../../constants/roles'
import SupportFeedbackRequestChangeOtp from './components/SupportFeedbackRequestChangeOtp'

const Feedback = () => {
  const { authenticated } = useAuthentication()
  useScrollToTop()

  const authenticatedRoutes = <Routes>
    <Route path='general/confirmation' element={<GeneralFeedbackConfirmation />} />
    <Route path='general' element={<GeneralFeedback />} />
    <Route path='accessibility/confirmation' element={<AccessibilityFeedbackConfirmation />} />
    <Route path='accessibility' element={<AccessibilityFeedback />} />
    <Route path='support' element={<SupportFeedback />} />

    <Route path='support/issue' element={<SupportFeedbackIssue />} />
    <Route path='support/confirmation' element={<SupportFeedbackConfirmation />} />
    <Route path='support/access' element={<SupportFeedbackAccess />} />

    <Route path='support/request' element={<SupportFeedbackRequest />} />
    <Route path='support/request/other' element={<SupportFeedbackRequestOther />} />
    <Route path='support/request/account/create' element={<RoleCheck roles={[CREATE_ACCOUNT_REQUESTOR]} render={() => <SupportFeedbackRequestAccountCreate />} />} />
    <Route path='support/request/account/remove' element={<RoleCheck roles={[REMOVE_ACCOUNT_REQUESTOR]} render={() => <SupportFeedbackRequestAccountRemove />} />} />
    <Route path='support/request/account/otp' element={<SupportFeedbackRequestChangeOtp />} />

    <Route path='*' element={<NotFound />} />
  </Routes>

  const unauthenticatedRoutes = <Routes>
    <Route path='support' element={<SupportFeedback />} />
    <Route path='*' element={<Login />} />
  </Routes>

  return (
    <>
      {authenticated
        ? authenticatedRoutes
        : unauthenticatedRoutes
      }
    </>
  )
}

export default Feedback
