import { createSlice } from '@reduxjs/toolkit'
import * as suggestions from '../../../../state/suggestions/reducers'
import { getPassHolderSuggestions } from './thunks'
import { reset } from '../results'

export const SEARCH_SUGGESTIONS = 'Search/suggestions'

const slice = createSlice({
  name: SEARCH_SUGGESTIONS,
  initialState: suggestions.initialState,
  reducers: {
    clearSuggestions: suggestions.clearSuggestions
  },
  extraReducers: (builder) => {
    builder.addCase(getPassHolderSuggestions.fulfilled, (state, action) => {
      state.suggestions = state.suggestions.filter((item) => item.field !== action.payload.field)
      state.suggestions.push(action.payload)
    })
    builder.addCase(reset, () => suggestions.initialState)
  }
})

export * from './thunks'

export const {
  clearSuggestions
} = slice.actions

export default slice.reducer
