import auditService from '../../auditService'
import auditSearchSideMenuFilters from '../../../../constants/auditSearchSideMenuFilters'
import { getFilters } from '../../../../services/filters'
import { Aggregate, AuditSearchSummary, PageResult, Filter, SearchMetadata } from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

export interface SearchRequest {
  terms: string
  page: number
}

export interface SearchResponse {
  result: PageResult<AuditSearchSummary, SearchMetadata>
  appliedFilters: Filter
  page: number
}

export const requestSearchAudits = createAsyncThunk<SearchResponse, SearchRequest, AppAsyncThunkOptions>
  ('Audits/search/requestSearchAudits',
    async ({ terms, page },
      { getState, rejectWithValue }) => {

      const state = getState()

      const {
        sideMenuCheckboxStatus,
        sideMenuDateFilters,
        sideMenuTextMatchesStatus,
        pageSize
      } = state.audits.search

      const filters = getFilters(auditSearchSideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)
      const aggregates: Aggregate[] = []

      const { sortBy, sortOrder } = state.sort

      const listSortBy = sortBy[SortList.AuditResults]
      const listSortOrder = sortOrder[SortList.AuditResults]

      try {
        const result = await auditService.requestAuditSearchRecords(terms, page, pageSize, listSortBy, listSortOrder, filters, aggregates)
        return {
          result,
          appliedFilters: filters,
          page
        }
      }
      catch (e) {
        return rejectWithValue(requestErrorHandler(e as Error))
      }
    })

export const getInitialAuditSearchRecords = (terms: string): AppThunk<Promise<void>> => async (dispatch) => {
  const page = 1
  dispatch(requestSearchAudits({ terms, page }))
}

export const getAuditSearchRecords = (): AppThunk<Promise<void>> => async (dispatch, getState): Promise<void> => {
  const page = 1
  const terms = getState().audits.search.terms
  dispatch(requestSearchAudits({ terms, page }))
}

export const changePage = (page: number): AppThunk => async (dispatch, getState) => {
  const terms = getState().audits.search.terms
  dispatch(requestSearchAudits({ terms, page }))
}
