import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthentication } from '../hooks/authentication'
import { hasNoGroups, hasNoRoles } from '../services/token'
import PageTitle from './PageTitle'

const Welcome = () => {

  const { tokenParsed } = useAuthentication()

  const hasNoRolesAndGroups = hasNoRoles(tokenParsed) && hasNoGroups(tokenParsed)

  return (
    <div>
      <PageTitle heading='Welcome to APHIDS' />

      {hasNoRolesAndGroups
        && <p>Please log a request with <Link to='/feedback/support/access'>APHIDS support</Link> to complete your account configuration.</p>
      }

      {!hasNoRolesAndGroups
        && <p>Please contact your system administrator to request access to the parts of the system that you require.</p>
      }
    </div>
  )
}

export default Welcome
