import { getFriendlyName } from '../services/fieldMappings'
import fieldMappings from './accountActivityFieldMappings'
import {
  CHECKBOX,
  MenuFilter,
  TEXT,
  DATE_TIME,
  TERM
} from '../types/filters'
import { FilterWildcard } from '../types/api'
import {
  yesNoRenderer,
  accountStatusRenderer,
  accountActionsRenderer,
  getGroupDisplayValue,
} from '../services/filterRenderers'

const accountActivitySideMenuFilters: MenuFilter[] = [
  {
    label: getFriendlyName('username', fieldMappings),
    field: 'username',
    inputType: TEXT,
    filterType: TERM,
    wildcard: FilterWildcard.Contains
  },
  {
    label: getFriendlyName('organisations', fieldMappings),
    field: 'organisations',
    inputType: CHECKBOX,
  },
  {
    label: getFriendlyName('groups', fieldMappings),
    field: 'groups',
    inputType: CHECKBOX,
    displayFormatter: getGroupDisplayValue
  },
  {
    label: getFriendlyName('status', fieldMappings),
    field: 'status',
    inputType: CHECKBOX,
    displayFormatter: accountStatusRenderer
  },
  {
    label: getFriendlyName('lastLogin', fieldMappings),
    field: 'lastLogin',
    inputType: DATE_TIME
  },
  {
    label: getFriendlyName('actions', fieldMappings),
    field: 'actions',
    inputType: CHECKBOX,
    displayFormatter: accountActionsRenderer
  },
  {
    label: getFriendlyName('otp', fieldMappings),
    field: 'otp',
    inputType: CHECKBOX,
    displayFormatter: yesNoRenderer
  }
]

export default accountActivitySideMenuFilters
