import React from 'react'
import { Navigate } from 'react-router-dom'
import { hasRoles, hasSingleOrganisation } from '../../../services/token'
import { useAuthentication } from '../../../hooks/authentication'

type RoleCheckProps = {
  roles: string[]
  render: Function
  singleOrgOnly?: boolean
}

const RoleCheck = ({
  roles,
  render,
  singleOrgOnly = false
}: RoleCheckProps) => {

  const { tokenParsed } = useAuthentication()

  return tokenParsed && hasRoles(tokenParsed, ...roles) && (!singleOrgOnly || hasSingleOrganisation(tokenParsed)) ? render() : <Navigate to='/not-found' />
}

export default RoleCheck
