import React from 'react'

type GovUKErrorMessageProps = {
  message: string
  id: string
}

const GovUKErrorMessage = ({
  message,
  id
}: GovUKErrorMessageProps) => {

  return (
    <span id={id} className="govuk-error-message"><span className="govuk-visually-hidden">Error:</span> {message}</span>
  )
}

export default GovUKErrorMessage
