import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKTextArea from '../../../components/govuk/GovUKTextArea'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { useAppDispatch } from '../../../store'
import PageTitle from '../../../components/PageTitle'
import { submitAccessFeedback, reset } from '../state/feedback'
import { SupportFeedbackType } from '../../../types/api'
import { Navigate } from 'react-router-dom'
import { MAX_WIDTH } from '../../../constants/inlineStyles'
import GovUKTextInput from '../../../components/govuk/GovUKTextInput'
import GovUKButton from '../../../components/govuk/GovUKButton'

const reasonInputName = 'feedback-reason'
const reasonErrorMessage = 'The justification field cannot be empty'

const organisationInputName = 'feedback-organisation'
const organisationErrorMessage = 'The organisation field cannot be empty'

const errorLinks: ErrorLinks = {
  [reasonErrorMessage]: {
    anchorName: `#${reasonInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  },
  [organisationErrorMessage]: {
    anchorName: `#${organisationInputName}-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  }
}

const SupportFeedbackAccess = () => {

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const [reason, setReason] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [organisationError, setOrganisationError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return <Navigate
      to={{ pathname: '/feedback/support/confirmation' }}
      state={{ type: SupportFeedbackType.Access }}
    />
  }

  const textAreaColumns = 120
  const textAreaRows = 10
  const textAreaMaxLength = 800

  const reasonIsValid = (input: string): boolean => {
    return input.trim().length > 0 && input.length <= textAreaMaxLength
  }

  const organisationIsValid = (input: string): boolean => {
    return input.trim().length > 0
  }

  const onReasonChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { target: { value } } = e
    if (reasonIsValid(value)) {
      setReasonError('')
    }
    setReason(value)
  }

  const onOrganisationChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = e
    if (organisationIsValid(value)) {
      setOrganisationError('')
    }
    setOrganisation(value)
  }

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const reasonValid = reasonIsValid(reason)
    const organisationValid = organisationIsValid(organisation)

    if (reasonValid && organisationValid) {
      await dispatch(submitAccessFeedback({ organisation, reason }))
      setSubmitted(true)
    }
    else {
      if (!reasonValid) {
        setReasonError(reasonErrorMessage)
      }
      if (!organisationValid) {
        setOrganisationError(organisationErrorMessage)
      }
    }
  }

  return (
    <>
      <PageTitle heading='Request access' />

      {(reasonError || organisationError) &&
        <GovUKErrorSummary title='Please check the form' errors={[organisationError, reasonError]} errorLinks={errorLinks} />}

      <form onSubmit={onSubmit}>
        <GovUKTextInput
          name={organisationInputName}
          label='Name of organisation'
          value={organisation}
          showLabel={true}
          onChange={onOrganisationChange}
          showError={organisationError.length > 0}
          errorMessage={organisationError}
          wrapRef={errorLinks[organisationErrorMessage].wrapRef}
          inputRef={errorLinks[organisationErrorMessage].inputRef}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <GovUKTextArea
          rows={textAreaRows}
          columns={textAreaColumns}
          name={reasonInputName}
          label='Why do you require access to APHIDS?'
          value={reason}
          showLabel={true}
          onChange={onReasonChange}
          maxLength={textAreaMaxLength}
          showError={reasonError.length > 0}
          errorMessage={reasonError}
          wrapRef={errorLinks[reasonErrorMessage].wrapRef}
          inputRef={errorLinks[reasonErrorMessage].inputRef}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <div className='govuk-form-group'>
          <GovUKButton
            className='govuk-button'
            style={{ marginBottom: '0px' }}
            id='support-feedback-submit'
            type='submit'>
            Submit
          </GovUKButton>
        </div>
      </form>
    </>
  )
}

export default SupportFeedbackAccess
