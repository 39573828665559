import React from 'react'
import Loading from '../../../components/Loading'
import PageTitle from '../../../components/PageTitle'
import { useAuthentication } from '../../../hooks/authentication'

const Login = () => {
  const {
    login,
    initialized
  } = useAuthentication()

  if (!initialized) {
    return <Loading />
  }

  return (
    <>
      <PageTitle caption='Welcome to APHIDS' heading='Sign in to continue' title='Sign in to continue' />

      <button type='button' className='govuk-button' data-module='govuk-button' onClick={login}>
        Sign in
      </button>
    </>
  )
}

export default Login
