type FriendlyName = { [key: string]: string }

const uploadTypesFriendlyNames: FriendlyName = {
  AIC: 'AIC - Airport Identification Card',
  AIC_APPLICATION: 'AIC Application - Airport Identification Card Application',
  CIC: 'CIC - Crew Identification Card',
  AV_SEC_INSTRUCTOR: 'AvSec Instructor',
  AV_SEC_VALIDATOR: 'AvSec Validator',
  IRIC: 'IRIC - International Rail Identification Card',
  MIC: 'MIC - Maritime Identification Card',
  ACIC: 'ACIC - Air Cargo Identification Card',
  AIR_CARGO_CLEARANCE: 'Air Cargo Clearance',
  PORT_PASS: 'Maritime Port Pass',
  PORT_APPLICATION: 'Maritime Port Application'
}

export const shortUploadTypesFriendlyNames: FriendlyName = {
  AIC: 'Airport Identification Card (AIC)',
  AIC_APPLICATION: 'AIC application',
  CIC: 'Crew Identification Card (CIC)',
  AV_SEC_INSTRUCTOR: 'AvSec Instructor',
  AV_SEC_VALIDATOR: 'AvSec Validator',
  IRIC: 'International Rail Identification Card (IRIC)',
  MIC: 'Maritime Identification Card (MIC)',
  ACIC: 'Air Cargo Identification Card (ACIC)',
  AIR_CARGO_CLEARANCE: 'Air Cargo Clearance',
  PORT_PASS: 'Port Pass',
  PORT_APPLICATION: 'Port Application'
}

export default uploadTypesFriendlyNames
