import { shortUploadTypesFriendlyNames } from '../constants/uploadTypesFriendlyNames'

export const yesNoRenderer = (value: string) => {
  if (value === 'null') {
    return 'Unknown'
  }
  return value === 'true' ? 'Yes' : 'No'
}

export const nullRenderer = (value: string) => {
  if (value === 'null') {
    return 'Unknown'
  }

  return value
}

export const sourceTypeRenderer = (value: string) => {
  if (value === 'null') {
    return 'Unknown'
  }

  return shortUploadTypesFriendlyNames[value] || value
}

export const accountStatusRenderer = (value: string) => {
  if (value === 'null') {
    return 'Unknown'
  }

  const status = value.toLowerCase().replace('_', ' ')

  return status[0].toUpperCase() + status.substr(1)
}

export const accountActionsRenderer = (value: string) => {
  if (value === 'null') {
    return 'Unknown'
  }

  if (value === 'SET_OTP') {
    return 'Set OTP'
  }

  const action = value.toLowerCase().replace('_', ' ')

  return action[0].toUpperCase() + action.slice(1)
}

export const truthyValues: PassActiveValueType[] = [true, 'TRUE', 'true', 'T', 't', 'YES', 'yes', 'Y', 'y', 1]
export const falseyValues: PassActiveValueType[] = [false, 'FALSE', 'false', 'F', 'f', 'NO', 'no', 'N', 'n', 0]
export type PassActiveValueType = string | number | boolean | undefined | null
interface FieldValueMapping {
  [key: string]: string
}

export const getPassActiveDisplayValues = (passActiveValue: PassActiveValueType) => {
  if (truthyValues.includes(passActiveValue)) {
    return 'Active'
  }
  if (falseyValues.includes(passActiveValue)) {
    return 'Inactive'
  }
  return 'Unknown'
}

export const passHolderFieldValuesMappings = (filterKey: string, value: PassActiveValueType) => {
  const fieldValues: FieldValueMapping = {
    'pass.active': getPassActiveDisplayValues(value)
  }
  return fieldValues[filterKey]
}

export const getGroupDisplayValue = (group: string):string => {
  const displayGroupValue = group
    // removes the aphids prefix if it exists
    .replace(/^aphids/, '')
    // replaces all hyphens with a space
    .replace(/-/g, ' ')
    // finds the first letter of each word and then makes it uppercase
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    // trims off any whitespace from the start or end of the formatted string
    .trim();
  return displayGroupValue;
}
