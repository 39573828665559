import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BulkExportSummary } from '../../../../types/api'
import { requestHistory } from './thunks'

export type BulkExportHistoryState = {
  isFetching: boolean
  totalRecords: number
  records: BulkExportSummary[]
  expanded: string[]
  currentPage: number
  pageSize: number
  hasError: boolean
}

export const initialState: BulkExportHistoryState = {
  isFetching: false,
  totalRecords: 0,
  records: [],
  expanded: [],
  currentPage: 1,
  pageSize: 10,
  hasError: false
}

export const BULKEXPORT_HISTORY = 'BulkExport/history'

const slice = createSlice({
  name: BULKEXPORT_HISTORY,
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    toggleExpand(state, action: PayloadAction<string>) {
      const index = state.expanded.indexOf(action.payload)

      if (index > -1) {
        state.expanded.splice(index, 1)
      } else {
        state.expanded.push(action.payload)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestHistory.pending, (state) => {
      state.isFetching = true
      state.hasError = false
    })
    builder.addCase(requestHistory.fulfilled, (state, action) => {
      const {
        data: { records, totalRecords }
      } = action.payload
      state.isFetching = false
      state.hasError = false
      state.records = records
      state.totalRecords = totalRecords
      if (action.meta.arg !== state.currentPage) {
        state.expanded = []
      }
      state.currentPage = action.meta.arg
    })
    builder.addCase(requestHistory.rejected, (state) => {
      state.isFetching = false
      state.hasError = true
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
    })
  }
})

export * from './thunks'

export const {
  toggleExpand,
  reset
} = slice.actions

export default slice.reducer
