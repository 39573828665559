import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import { MI_USER, SEARCH_USER, SUPPORT } from '../../constants/groups'
import GroupCheck from '../Authorisation/components/GroupCheck'

const ProvidersComponent = lazy(() => import('./components/Providers'))

const Providers = () => (
  <Routes>
    <Route path='/*' element={
      <GroupCheck groups={[SEARCH_USER, SUPPORT, MI_USER]} render={() =>
        <DefaultSuspense>
          <ProvidersComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>
)

export default Providers
