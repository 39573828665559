import { SortOrder } from '../types/api'

export const sortValueDelimiter = ':'

const convertValueToSortOptions = (value: string) => {
  const [sortBy, sortOrder] = value.split(sortValueDelimiter)
  return {
    sortBy,
    sortOrder: sortOrder as SortOrder
  }
}

const convertSortOptionsToValue = (sortBy: string, sortOrder: SortOrder) => {
  return `${sortBy}${sortValueDelimiter}${sortOrder}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortByPropertyValue = (input1: any, input2: any, sortProperty: string, fallbackSortProperty?: string) => {

  const input1Value = input1[sortProperty] === undefined && fallbackSortProperty ? input1[fallbackSortProperty] : input1[sortProperty]
  const input2Value = input2[sortProperty] === undefined && fallbackSortProperty ? input2[fallbackSortProperty] : input2[sortProperty]

  if (input1Value > input2Value) {
    return 1
  }
  if (input1Value < input2Value) {
    return -1
  }
  return 0
}

export default {
  convertSortOptionsToValue,
  convertValueToSortOptions,
  sortByPropertyValue
}
