import searchSideMenuFilters from '../../../../constants/searchSideMenuFilters'
import { getAggregates } from '../../../../services/aggregates'
import { uploadProgressHandler } from '../../../../services/uploadProgress'
import { AppAsyncThunkOptions } from '../../../../store'
import {
  AggregateMetadata,
  PageResult,
  PassHolderBulkSearchRecord,
  PassHolderSearchSummary
} from '../../../../types/api'
import searchService from '../../../Search/searchService'
import { SortList } from '../../../Sort/state'
import uploadService from '../../../Upload/uploadService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { setFileUploadProgress } from './'
import { requestErrorHandler } from '../../../../services/request'

export type BulkSearchMetadata = {
  warningMessage: string
  query: PassHolderBulkSearchRecord[],
  aggregations: AggregateMetadata
}

export const uploadFile = createAsyncThunk<PageResult<PassHolderSearchSummary, BulkSearchMetadata>, string, AppAsyncThunkOptions>
  ('BulkSearch/uploadFile', async (url, { dispatch, getState, rejectWithValue }) => {
    const file = await uploadService.getFileFromObjectURL(url)

    const onUploadProgress = uploadProgressHandler((percentCompleted: number) => {
      dispatch(setFileUploadProgress(percentCompleted))
    })

    const state = getState()

    const page = 1
    const {
      pageSize
    } = state.bulkSearch.results

    const {
      sortBy,
      sortOrder
    } = state.sort

    const searchSortBy = sortBy[SortList.SearchResults]
    const searchSortOrder = sortOrder[SortList.SearchResults]

    const { justification } = state.bulkSearch.justification
    const { bulkSearchType } = state.bulkSearch.bulkSearchType

    const aggregates = getAggregates(true, searchSideMenuFilters)

    try {
      const result = await searchService.uploadBulkSearchFile(file, onUploadProgress, justification, searchSortBy, searchSortOrder, page, pageSize, aggregates, bulkSearchType)

      return result
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
