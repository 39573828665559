import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GovUKBackButton from './govuk/GovUKBackButton'
import PageTitle from './PageTitle'

const NotAvailable = () => {
  const navigate = useNavigate()

  return (
    <div>
      <GovUKBackButton caption='Back' onClick={() => { navigate('/upload/history') }} />

      <PageTitle heading='Sorry, the service is unavailable' />

      <p>You will be able to use the service later.</p>

      <p>Contact <Link to='/feedback/support/issue'>APHIDS support</Link> if you have any urgent requests.</p>
    </div>
  )
}

export default NotAvailable
