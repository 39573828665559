import { getFriendlyName } from '../services/fieldMappings'
import fieldMappings from '../constants/uploadFieldMappings'
import { MenuFilter, DATE_TIME, CHECKBOX } from '../types/filters'
import { sourceTypeRenderer } from '../services/filterRenderers'

const uploadSideMenuFilters: MenuFilter[] = [
  {
    label: getFriendlyName('UPLOADED_timestamp', fieldMappings),
    field: 'UPLOADED_timestamp',
    fallbackField: 'timestamp',
    inputType: DATE_TIME,
    defaultDateFilterType: 'range'
  },
  {
    label: getFriendlyName('source_type', fieldMappings),
    field: 'source_type',
    aggregateField: 'source_type',
    inputType: CHECKBOX,
    displayFormatter: sourceTypeRenderer,
    showMissingValue: true
  }
]

export default uploadSideMenuFilters
