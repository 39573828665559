import React from 'react'
import GovUKErrorSummary from '../../../components/govuk/GovUKErrorSummary'
import GovUKConfirmation from '../../../components/govuk/GovUKConfirmation'
import { Navigate } from 'react-router-dom'

type FeedbackConfirmationProps = {
  heading: string
  message: string
  referenceNumber: string
  error: string | null
  errorTitle: string
}

const FeedbackConfirmation = ({
  heading,
  message,
  referenceNumber,
  error,
  errorTitle
}: FeedbackConfirmationProps) => {

  const hasError = error && error.trim().length > 0
  const hasReference = referenceNumber && referenceNumber.trim().length > 0

  if (!hasError && !hasReference) {
    return <Navigate to='/' />
  }

  return (
    <>
      {hasError && <GovUKErrorSummary title={errorTitle} errors={[error]} />}

      {!hasError && <GovUKConfirmation heading={heading} message={message} reference={referenceNumber} />}
    </>
  )
}

export default FeedbackConfirmation
