import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'
import { AUDIT_SEARCH } from '../../constants/roles'

const AuditsComponent = lazy(() => import('./components/Audits'))

const Audits = () => (
  <Routes>
    <Route path='/*' element={
      <RoleCheck roles={[AUDIT_SEARCH]} render={() =>
        <DefaultSuspense>
          <AuditsComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>
)

export default Audits
