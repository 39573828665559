import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'
import fieldMappings from '../../constants/passHolderFieldMappings'
import { getFriendlyName } from '../../services/fieldMappings'
import { SortOrder, PageRequest, SortRequest, PageResult, BatchRecord } from '../../types/api'

interface ReviewQueryRequest extends PageRequest, SortRequest {
  severity: string | string[]
}

const requestReview = async (batchId: string, severity: string, page: number, pageSize: number, sortBy: string, sortOrder: SortOrder) => {
  const params: ReviewQueryRequest = {
    page,
    pageSize,
    sortBy,
    sortOrder,
    severity: severity && severity.length > 0 ? severity.toUpperCase() : ['ERROR', 'WARNING']
  }

  const result = await axios.get(`/api/v1/batches/${batchId}/issues?${queryString.stringify(params)}`)
  return mapResultFields(result)
}

const mapResultFields = (res: AxiosResponse<PageResult<BatchRecord>>) => {
  const regex = /\[\d*\]/g

  const { data } = res

  data.data.records.forEach((record) => {
    const systemName = record.field

    const systemNameWithoutIndices = systemName && systemName.replace(regex, '')

    const friendlyName = systemNameWithoutIndices ? getFriendlyName(systemNameWithoutIndices, fieldMappings) : systemName

    record.field = friendlyName
  })

  return data
}

export default {
  requestReview
}
