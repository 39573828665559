import React from 'react'
import PageTitle from './PageTitle'

const IPRestricted = () => {

  return (
    <div>
      <PageTitle heading='Access not permitted' />

      <p>Access to APHIDS is not permitted from your current location.</p>
      <p>Please contact aphids.support@homeoffice.gov.uk if you require assistance accessing the system.</p>
    </div>
  )
}

export default IPRestricted
