import axios from 'axios'
import {
  OrganisationSummary,
  ApiSuccessResult,
  OrganisationType,
  DataProviderSummary,
  IUserOrganisationResponse
} from '../../types/api'
import sort from '../../services/sort'

const getOrganisationsOfType = <T extends OrganisationSummary>(orgs: T[] | null, orgType: OrganisationType): T[] => {
  if (orgs === null) {
    return []
  }

  return orgs
    .filter((org) => org.type === orgType)
    .sort((a, b) => sort.sortByPropertyValue(a, b, 'name', 'code'))
}

const getUserOrganisations = async () => {
  const res = await axios.get<ApiSuccessResult<IUserOrganisationResponse>>('/api/v1/organisations/user')
  return res.data
}

const getAllOrganisations = async () => {
  const res = await axios.get<ApiSuccessResult<OrganisationSummary[]>>('/api/v1/organisations')
  return res.data
}

const getDataProviderStatus = async () => {
  const res = await axios.get<ApiSuccessResult<DataProviderSummary[]>>('/api/v1/organisations/status')
  return res.data
}

export default {
  getUserOrganisations,
  getAllOrganisations,
  getDataProviderStatus,
  getOrganisationsOfType
}
