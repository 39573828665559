import React, { FC } from 'react'
import GovUKTable from './govuk/GovUKTable'
import { Header } from '../types/table'
import styled from 'styled-components'
import PageTitle from './PageTitle'
import { useScrollToTop } from '../hooks/window'
import { MAX_WIDTH } from '../constants/inlineStyles'

const MaxWidthWrapper = styled.div`
  max-width: ${MAX_WIDTH};
`

type Cookie = {
  name: string
  purpose: string
  expires: string
  type: 'settings' | 'identity'
}

const headers: Header<Cookie>[] = [
  {
    title: 'Name',
    data: 'name'
  },
  {
    title: 'Purpose',
    data: 'purpose'
  },
  {
    title: 'Expires',
    data: 'expires'
  }
]

const cookies: Cookie[] = [{
  name: 'CookieConsent',
  purpose: 'Remembers that you have seen the cookie policy banner',
  expires: '1 year',
  type: 'settings'
},
{
  name: 'MaintenanceWarning',
  purpose: 'Remembers that you have seen the maintenance warning banner',
  expires: '1 week',
  type: 'settings'
},
{
  name: 'elastic',
  purpose: 'Used to ensure that you are able to access the correct information in the application as you use it',
  expires: 'When you close your browser',
  type: 'identity'
}]

const settingsCookies = cookies.filter((cookie) => cookie.type === 'settings')
const identityCookies = cookies.filter((cookie) => cookie.type === 'identity')

const CookiePolicy: FC = () => {

  useScrollToTop()

  return (
    <>
      <PageTitle heading='Cookies in APHIDS' title='Cookie policy' />

      <p>APHIDS puts small files (known as &apos;cookies&apos;) onto your computer to assist in the effective functioning of the site. Find out more about the cookies we use, what they are used for and when they expire. </p>

      <h2 id='settings-cookies' className='govuk-heading-m'>Cookies that remember your settings</h2>
      <p>These cookies do things like remember your preferences and the choices you make, to personalise your experience of APHIDS.</p>

      <MaxWidthWrapper>
        <GovUKTable headers={headers} rows={settingsCookies} />
      </MaxWidthWrapper>

      <h2 id='identity-cookies' className='govuk-heading-m'>Cookies used for identity management</h2>
      <p>We use Keycloak software to allow you to log in to APHIDS using one of several identity providers. These cookies are required for proper functioning of the system, to ensure that the site gives you access to the relevant information. </p>

      <MaxWidthWrapper>
        <GovUKTable headers={headers} rows={identityCookies} />
      </MaxWidthWrapper>
    </>
  )
}

export default CookiePolicy
