import { RELEVANCE } from '../../../constants/searchSortFields'
import { DATE as AUDIT_TIMESTAMP } from '../../../constants/auditSearchSortFields'
import { DATE as REPORT_CREATION_DATE } from '../../../constants/reportSearchSortFields'
import { POSITION } from '../../../constants/uploadIssuesSortFields'
import { SortOrder } from '../../../types/api'
import { DATE_TIME as uploadDATE_TIME } from '../../../constants/uploadTableSortFields'
import { SAVED_ON } from '../../../constants/savedProfileSortFields'
import { DATE_TIME as bulkExportDATE_TIME } from '../../../constants/bulkExportTableSortFields'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum SortList {
  AuditResults = 'audit',
  BulkExportTable = 'bulkExportTable',
  BulkSearchResults = 'bulkSearch',
  SearchResults = 'search',
  ReportResults = 'reports',
  UploadIssues = 'uploadIssues',
  UploadTable = 'uploadTable',
  SavedProfileResults = 'savedProfile',
  AccountActivityResults = 'accountActivity'
}

export type SortByState = {
  [key: string]: string
}

export type SortOrderState = {
  [key: string]: SortOrder
}

export type SortState = {
  sortBy: SortByState
  sortOrder: SortOrderState
}

export const initialState: SortState = {
  sortBy: {
    [SortList.UploadTable]: uploadDATE_TIME,
    [SortList.AuditResults]: AUDIT_TIMESTAMP,
    [SortList.ReportResults]: REPORT_CREATION_DATE,
    [SortList.SearchResults]: RELEVANCE,
    [SortList.UploadIssues]: POSITION,
    [SortList.SavedProfileResults]: SAVED_ON,
    [SortList.BulkExportTable]: bulkExportDATE_TIME,
  },
  sortOrder: {
    [SortList.UploadTable]: SortOrder.Desc,
    [SortList.AuditResults]: SortOrder.Desc,
    [SortList.ReportResults]: SortOrder.Desc,
    [SortList.SearchResults]: SortOrder.Desc,
    [SortList.UploadIssues]: SortOrder.Asc,
    [SortList.SavedProfileResults]: SortOrder.Desc,
    [SortList.BulkExportTable]: SortOrder.Desc,
  }
}

export const SORT = 'Sort'

const slice = createSlice({
  name: SORT,
  initialState,
  reducers: {
    onSort(state, action: PayloadAction<{ list: SortList, field: string, order: SortOrder }>) {
      const { list, field, order } = action.payload
      state.sortBy[list] = field
      state.sortOrder[list] = order
    },
    reset() {
      return initialState
    }
  }
})

export const {
  onSort,
  reset
} = slice.actions

export default slice.reducer
