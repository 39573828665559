export const GROUP_PREFIX = 'aphids'

export const ACCOUNT_MANAGER = `${GROUP_PREFIX}-account-manager`
export const ID_ADMIN = `${GROUP_PREFIX}-id-admin`
export const ID_ISSUER = `${GROUP_PREFIX}-id-issuer`
export const MI_USER = `${GROUP_PREFIX}-mi-user`
export const PARTNER = `${GROUP_PREFIX}-partner`
export const SEARCH_AUDITOR = `${GROUP_PREFIX}-search-auditor`
export const SEARCH_USER = `${GROUP_PREFIX}-search-user`
export const SUPPORT = `${GROUP_PREFIX}-support`
