import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterState, initialState as initialFilterState } from '../../../../state/filter/reducers'
import * as filter from '../../../../state/filter/reducers'
import { AuditSearchSummary } from '../../../../types/api'
import { requestSearchAudits } from './thunks'
import { getRejectionError } from '../../../../services/request'

export const AUDITS_SEARCH = 'Audits/search'

export type AuditSearchState = FilterState & {
  terms: string
  termsError: string
  isFetching: boolean
  totalRecords: number
  records: AuditSearchSummary[]
  error: string | null
  currentPage: number
  pageSize: number
  hasError: boolean
}

export const initialState: AuditSearchState = {
  ...initialFilterState,
  terms: '',
  termsError: '',
  isFetching: false,
  totalRecords: 0,
  records: [],
  error: null,
  currentPage: 1,
  pageSize: 20,
  hasError: false
}

const slice = createSlice({
  name: AUDITS_SEARCH,
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    setTermsError(state, action: PayloadAction<string>) {
      state.termsError = action.payload
    },
    setSearchTerms(state, action: PayloadAction<string>) {
      state.terms = action.payload
    },
    toggleSideMenuExpanded: filter.toggleSideMenuExpanded,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    removeBreadboxItem: filter.removeBreadboxItem,
    clearDateFilter: filter.clearDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuDateFilter: filter.setSideMenuDateFilter
  },
  extraReducers: (builder) => {
    builder.addCase(requestSearchAudits.pending, (state, action) => {
      state.isFetching = true
      state.terms = action.meta.arg.terms
      state.hasError = false
      state.error = initialState.error
    })
    builder.addCase(requestSearchAudits.fulfilled, (state, action) => {
      const { result: { data: { records, totalRecords } }, page } = action.payload
      state.hasError = false
      state.isFetching = false
      state.error = initialState.error
      state.totalRecords = totalRecords || initialState.totalRecords
      state.records = records || initialState.records
      state.currentPage = page || initialState.currentPage
    })
    builder.addCase(requestSearchAudits.rejected, (state, action) => {
      state.isFetching = false
      state.hasError = true
      state.error = getRejectionError(action, initialState.error)
    })
  }
})

export * from './thunks'

export const {
  reset,
  setSideMenuDateFilter,
  setSideMenuCheckbox,
  addSelectedSuggestion,
  clearDateFilter,
  removeBreadboxItem,
  toggleSideMenuEntryExpanded,
  toggleSideMenuExpanded,
  setSearchTerms,
  setTermsError
} = slice.actions

export default slice.reducer
