import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

type TitleProps = {
  title?: string
}

const Title: FC<TitleProps> = ({
  title
}) => {

  const defaultTitle = 'APHIDS'

  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  )
}

export default Title
