import React from 'react'
import { useAppSelector } from '../store'
import styled from 'styled-components'
import { MaintenanceStatus } from '../types/api'
import { useCookies } from 'react-cookie'

const Banner = styled.div<{ status: MaintenanceStatus }>`
  display: flex;
  width: 100%;
  background-color: ${props => props.status === 'ENABLED' ? '#dad5e9' : '#bfe3e1'};
  padding: 2px 15px;
  align-items: center;
  box-sizing: border-box;
`

const Message = styled.div`
  margin-left: auto;
  margin-right: auto;
`

const DismissButton = styled.button`
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
`

const MaintenanceBanner = () => {

  const maintenanceCookieName = 'MaintenanceWarning'

  const {
    maintenance_status
  } = useAppSelector((state) => state.health)

  const [cookies, setCookie, removeCookie] = useCookies([maintenanceCookieName])

  const removeMaintenanceCookie = () => {
    removeCookie(maintenanceCookieName)
  }

  const saveMaintenanceCookie = () => {
    const now = new Date()
    now.setDate(now.getDate() + 7)

    setCookie(maintenanceCookieName, true, {
      sameSite: 'strict',
      expires: now,
      secure: true
    })
  }

  if (!maintenance_status) {
    removeMaintenanceCookie()
    return <></>
  }

  const { status, warning_message, maintenance_message } = maintenance_status

  if (status === 'DISABLED') {
    removeMaintenanceCookie()
    return <></>
  }

  const message = status === 'ENABLED'
    ? maintenance_message?.trim()
    : warning_message?.trim()

  if (!message || message.length === 0) {
    removeMaintenanceCookie()
    return <></>
  }

  const canDismiss = status === 'WARNING'

  if (canDismiss && cookies.MaintenanceWarning === 'true') {
    return <></>
  }

  return (
    <Banner className="app-maintenance-banner" status={status}>
      <Message className="app-maintenance-banner__message">{message}</Message>
      {canDismiss &&
        <div>
          <DismissButton className="app-maintenance-banner__button hod-cross" aria-label='Dismiss' onClick={saveMaintenanceCookie} />
        </div>
      }
    </Banner>
  )
}

export default MaintenanceBanner
