import { createAsyncThunk } from '@reduxjs/toolkit'
import { REPEAT_CRC_RESULTS } from '.'
import { isErrorAxiosError, requestErrorHandler } from '../../../../services/request'
import { AppAsyncThunkOptions } from '../../../../store'
import { RepeatChecksAuditSummary } from '../../../../types/api'
import repeatCrcService from '../../repeatCrcService'

export interface CRCResponse {
  totalRecords: number
  pageSize: number
  page: number
  records: RepeatChecksAuditSummary[]
}

export const requestDownload = createAsyncThunk<void, string, AppAsyncThunkOptions>
  (`${REPEAT_CRC_RESULTS}/requestDownload`, async (exportId, { dispatch, getState, rejectWithValue }) => {
    try {
      await repeatCrcService.requestDownload(exportId)
    } catch (e) {
      const error = e as Error
      if (isErrorAxiosError(error)) {
        if (error.code === '404') {
          const state = getState()
          const { currentPage } = state.crc.results

          dispatch(getRepeatCheckAudits(currentPage))
          return
        }
      }
      return rejectWithValue(requestErrorHandler(error))
    }
  })

export const getRepeatCheckAudits = createAsyncThunk<CRCResponse, number, AppAsyncThunkOptions>
  (`${REPEAT_CRC_RESULTS}/search`, async (page, { getState }) => {
    const state = getState()
    const usedPage = page ? page : 1
    const pageSize = state.crc.results.pageSize
    const result = await repeatCrcService.getAllRepeatChecksAudits(usedPage, pageSize)
    return {
      page: usedPage,
      pageSize,
      records: result.data.records,
      totalRecords: result.data.totalRecords
    }
  })
