import React from 'react'
import classNames from 'classnames'

type GovUKCellProps = {
  content?: JSX.Element | string
  header?: boolean
  extraClasses?: string
  extraProps?: object
}

const GovUKCell = ({
  content = '',
  header = false,
  extraClasses = '',
  extraProps = {}
}: GovUKCellProps) => {

  const classes = classNames('govuk-body', extraClasses, {
    'govuk-table__header': header,
    'govuk-table__cell': !header
  })

  return header ? (
    <th scope="col" className={classes} {...extraProps}>
      {content}
    </th>
  ) : (
    <td className={classes} {...extraProps}>
      {content}
    </td>
  )
}

export default GovUKCell
