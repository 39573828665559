import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BatchSummary } from '../../../../types/api'
import * as filter from '../../../../state/filter/reducers'
import { FilterState, initialState as initialFilterState } from '../../../../state/filter/reducers'
import { executeRequestHistory } from './thunks'

export type UploadHistoryState = FilterState & {
  isFetching: boolean
  totalRecords: number
  records: BatchSummary[]
  expanded: string[]
  currentPage: number
  pageSize: number
  hasError: boolean
}

export const initialState: UploadHistoryState = {
  ...initialFilterState,
  isFetching: false,
  totalRecords: 0,
  records: [],
  expanded: [],
  currentPage: 1,
  pageSize: 10,
  hasError: false
}

export const UPLOAD_HISTORY = 'Upload/history'

const slice = createSlice({
  name: UPLOAD_HISTORY,
  initialState,
  reducers: {
    toggleExpand(state, action: PayloadAction<string>) {
      const index = state.expanded.indexOf(action.payload)

      if (index > -1) {
        state.expanded.splice(index, 1)
      } else {
        state.expanded.push(action.payload)
      }
    },
    toggleSideMenuExpanded: filter.toggleSideMenuExpanded,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    removeBreadboxItem: filter.removeBreadboxItem,
    clearDateFilter: filter.clearDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuDateFilter: filter.setSideMenuDateFilter
  },
  extraReducers: (builder) => {
    builder.addCase(executeRequestHistory.pending, (state) => {
      state.isFetching = true
      state.hasError = false
    })
    builder.addCase(executeRequestHistory.fulfilled, (state, action) => {
      const {
        result: { data: { records, totalRecords, metadata } },
        saveAggregation
      } = action.payload
      state.isFetching = false
      state.hasError = false
      state.records = records
      state.totalRecords = totalRecords
      if (action.meta.arg.page !== state.currentPage) {
        state.expanded = []
      }
      if (saveAggregation) {
        const aggregation = metadata.aggregations || {}
        state.sideMenuEntryTopMatches = aggregation
      }
      state.currentPage = action.meta.arg.page
    })
    builder.addCase(executeRequestHistory.rejected, (state) => {
      state.isFetching = false
      state.hasError = true
      state.records = initialState.records
      state.totalRecords = initialState.totalRecords
    })
  }
})

export * from './thunks'

export const {
  toggleExpand,
  setSideMenuDateFilter,
  setSideMenuCheckbox,
  addSelectedSuggestion,
  clearDateFilter,
  removeBreadboxItem,
  toggleSideMenuEntryExpanded,
  toggleSideMenuExpanded
} = slice.actions

export default slice.reducer
