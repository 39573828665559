import statusService from '../../statusService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHealthResponse } from '../../../../types/api'
import { AppAsyncThunkOptions } from '../../../../store'
import { requestErrorHandler } from '../../../../services/request'

export const getStatusInformation = createAsyncThunk<IHealthResponse, void, AppAsyncThunkOptions>
  ('Status/information', async (_, { rejectWithValue }) => {
    try {
      const result = await statusService.getStatusInformation()

      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
