import React from 'react'
import classNames, { Argument as ClassValue } from 'classnames'
import { InputChangeHandler } from '../../types/events'

export type AsyncBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => Promise<void>

export type AsyncKeyboardEventHandler = (event: React.KeyboardEvent<HTMLInputElement>) => Promise<void>

type GovUKInputProps = {
  id: string
  name?: string
  type?: string
  value?: string
  defaultValue?: string
  onChange?: InputChangeHandler
  onBlur?: AsyncBlurHandler
  onKeyUp?: AsyncKeyboardEventHandler
  disabled?: boolean
  showError?: boolean
  style?: React.CSSProperties
  maxLength?: number
  extraProps?: React.HTMLAttributes<HTMLInputElement>
  extraClasses?: ClassValue
  describedBy?: string
  checked?: boolean
  defaultChecked?: boolean
}

const GovUKInput = React.forwardRef<HTMLInputElement, GovUKInputProps>(({
  id,
  name,
  type = 'text',
  value,
  defaultValue,
  onChange = () => { /* empty default */ },
  onBlur = (): Promise<void> => Promise.resolve(),
  onKeyUp = (): Promise<void> => Promise.resolve(),
  disabled = false,
  showError = false,
  style = {},
  extraProps,
  extraClasses,
  maxLength,
  describedBy,
  checked,
  defaultChecked
}, ref) => {

  const inputClasses = classNames('govuk-input', extraClasses, {
    'govuk-input--error': showError
  })

  return (
    <input
      key={id}
      className={inputClasses}
      style={style}
      id={id}
      name={name || id}
      type={type}
      value={value}
      ref={ref}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      disabled={disabled}
      aria-describedby={describedBy}
      maxLength={maxLength}
      checked={checked}
      defaultChecked={defaultChecked}
      {...extraProps} />
  )
})
GovUKInput.displayName = 'GovUKInput'

export default GovUKInput
