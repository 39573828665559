import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { FilterWildcard } from '../../../../types/api'
import searchService from '../../../Search/searchService'
import { updateBulkSearch } from '../results/thunks'
import { addSelectedSuggestion } from '../results'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Suggestion } from '../../../../types/Suggestion'
import { requestErrorHandler } from '../../../../services/request'

interface FilterInformation {
  field: string
  aggregateField: string
  value: string
  wildcard?: FilterWildcard
}

export const getBulkSearchSuggestions = createAsyncThunk<Suggestion, FilterInformation, AppAsyncThunkOptions>
  ('BulkSearch/getBulkSearchSuggestions', async ({ field, aggregateField, value, wildcard }, { getState, rejectWithValue }) => {
    const state = getState()
    const { query } = state.bulkSearch.results

    const hasBulkSearchTerms = !!query && query.length > 0

    if (!hasBulkSearchTerms) {
      return { field, suggestions: [] }
    }

    try {
      const result = await searchService.getBulkSearchSuggestions(query, field, aggregateField, value, wildcard)
      return { field, suggestions: result.data }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const selectFilterSuggestion = (field: string, value: string): AppThunk => (dispatch) => {
  dispatch(addSelectedSuggestion({ field, value }))
  dispatch(updateBulkSearch())
}
