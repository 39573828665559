import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BatchRecord } from '../../../../types/api'
import { refresh } from './thunks'

export const initialState: UploadIssuesState = {
  records: [],
  totalRecords: 0,
  timestamp: null,
  hasError: false,
  severity: 'all',
  page: 1,
  pageSize: 10
}

export type UploadIssuesState = {
  records: BatchRecord[]
  totalRecords: number
  timestamp: string | null
  hasError: boolean
  severity: string
  page: number
  pageSize: number
}

export const UPLOAD_ISSUES = 'Upload/issues'

const slice = createSlice({
  name: UPLOAD_ISSUES,
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    setSeverity(state, action: PayloadAction<string>) {
      state.severity = action.payload || initialState.severity
    }
  },
  extraReducers: (builder) => {
    builder.addCase(refresh.pending, (state) => {
      state.hasError = false
    })
    builder.addCase(refresh.fulfilled, (state, action) => {
      const {
        data: {
          records,
          totalRecords,
          metadata: {
            timestamp
          }
        }
      } = action.payload
      state.hasError = false
      state.records = records || initialState.records
      state.totalRecords = totalRecords || initialState.totalRecords
      state.timestamp = timestamp || initialState.timestamp
      state.page = action.meta.arg.newPage
    })
    builder.addCase(refresh.rejected, () => {
      return {
        ...initialState,
        hasError: true
      }
    })
  }
})

export * from './thunks'

export const {
  reset,
  setSeverity
} = slice.actions

export default slice.reducer
