import { AxiosResponse } from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFilenameFromContentDisposition = (response: AxiosResponse<any>, defaultFilename: string) => {
  const regex = /filename="(.*)"/gm

  const contentDisposition = response.headers['content-disposition']

  const matches = regex.exec(contentDisposition)

  if (matches !== null && matches.length === 2) {
    return matches[1]
  }

  return defaultFilename
}

export default {
  getFilenameFromContentDisposition
}