import React, { FC, RefAttributes, useState } from 'react'
import classNames, { Argument as ClassValue } from 'classnames'

type GovUKButtonProps = RefAttributes<HTMLButtonElement> & {
  onClick?: (event: React.MouseEvent) => Promise<void>
  ariaLabel?: string
  className?: ClassValue
  secondary?: boolean
  disabled?: boolean
  id?: string
  style?: React.CSSProperties
  type?: 'button' | 'submit' | 'reset'
  title?: string
  blockOnClick?: boolean
}

export const BlockingContext = React.createContext({
  isBlocked: false
})


export const UnblockedContent: FC = ({
  children
}) => {
  return <BlockingContext.Consumer>
    {value => value.isBlocked ? <></> : children}
  </BlockingContext.Consumer>
}

export const BlockedContent: FC = ({
  children
}) => {
  return <BlockingContext.Consumer>
    {value => value.isBlocked ? children : <></>}
  </BlockingContext.Consumer>
}

const GovUKButton: FC<GovUKButtonProps> = React.forwardRef(({
  onClick,
  ariaLabel,
  children,
  secondary,
  disabled,
  className,
  id,
  style,
  type = 'button',
  title,
  blockOnClick = false
}, ref) => {

  const [isBlocked, setBlocked] = useState(false)

  const buttonClasses = classNames('govuk-button', className, {
    'govuk-button--secondary': secondary,
    'govuk-button--disabled': disabled
  })

  const internalOnClick = async (event: React.MouseEvent) => {
    if (!onClick) {
      return
    }

    if (!blockOnClick) {
      await onClick(event)
      return
    }

    if (!isBlocked) {
      setBlocked(true)
      await onClick(event)
      setBlocked(false)
    }
  }

  const buttonContent = <button
    ref={ref}
    className={buttonClasses}
    data-module="govuk-button"
    onClick={internalOnClick}
    id={id}
    name={id}
    disabled={disabled}
    aria-disabled={disabled}
    aria-label={ariaLabel}
    type={type}
    style={style}
    title={title}>
    {children}
  </button>

  return blockOnClick
    ? <BlockingContext.Provider value={{ isBlocked }}>
      {buttonContent}
    </BlockingContext.Provider>
    : buttonContent
})
GovUKButton.displayName = 'GovUKButton'

export default GovUKButton
