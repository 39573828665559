import { FilterWildcard } from './api'
import { Checkbox } from './checkboxes'
import { FilterType as DateFilterType } from './dates'

export const DATE = 'date'
export const DATE_TIME = 'datetime'
export const TEXT = 'text'
export const CHECKBOX = 'checkbox'

export type FilterInputType = typeof DATE | typeof DATE_TIME | typeof TEXT | typeof CHECKBOX

export const MATCH = 'match'
export const RANGE = 'range'
export const TERM = 'term'

export type FilterType = typeof MATCH | typeof RANGE | typeof TERM

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DisplayFormatter = (value: any) => string

export type MenuFilter = {
  /** Label to display in filter side menu */
  label: string

  /** Field to filter on for suggestions and search */
  field: string

  /** Field to filter on for search if "field" does not exist (range/match only) - OR logic (min 1 match) applied between field and fallbackField for the same filter value(s) */
  fallbackField?: string

  /** Field to return suggestion aggregates from */
  aggregateField?: string

  /** Type of input control to be used */
  inputType: FilterInputType

  /** Filter query type */
  filterType?: FilterType

  /** Show filter option for undefined values */
  showMissingValue?: boolean

  /** Filter value display formatter */
  displayFormatter?: DisplayFormatter

  /** Type of date filter selected by default */
  defaultDateFilterType?: DateFilterType

  /** Minimum required characters before suggestion query is triggered */
  minimumInputLength?: number

  /** Minimum required characters before suggestion query is triggered */
  wildcard?: FilterWildcard
}

export type FilterMenuItem = MenuFilter & {
  /** Selectable checkbox values */
  checkboxes?: Checkbox[]

  /** Filter is displayed expanded */
  isExpanded: boolean
}

export type TextFilterSuggestion = {
  field: string
  suggestions: string[]
}
