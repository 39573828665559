import { reset } from '.'
import downloadService from '../../downloadService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

interface IDownloadRequest {
  path: string
  filename: string
}

export const requestDownload = createAsyncThunk<void, IDownloadRequest>
  ('Training/requestDownload', async ({ path, filename }, { dispatch, rejectWithValue }) => {
    dispatch(reset())

    try {
      await downloadService.download(path, filename)
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
