import reportService from '../../reportService'
import { getFilters } from '../../../../services/filters'
import { getAggregates } from '../../../../services/aggregates'
import reportSearchSideMenuFilters from '../../../../constants/reportSearchSideMenuFilters'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { Filter, PageResult, ReportSummary } from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

interface SearchRequest {
  page: number
  isInitialSearch: boolean
}

export interface SearchResponse {
  result: PageResult<ReportSummary>
  saveAggregation: boolean
  appliedFilters: Filter
  page: number
}

export const executeReportSearch = createAsyncThunk<SearchResponse, SearchRequest, AppAsyncThunkOptions>
  (`Reports/search/executeReportSearch`, async (request, { getState, rejectWithValue }) => {
    const state = getState()

    const {
      pageSize
    } = state.reports.search

    const {
      sortBy,
      sortOrder
    } = state.sort

    const {
      page,
      isInitialSearch
    } = request

    const reportsSortBy = sortBy[SortList.ReportResults]
    const reportsSortOrder = sortOrder[SortList.ReportResults]

    const saveAggregation = isInitialSearch

    const sideMenuCheckboxStatus = isInitialSearch ? [] : state.reports.search.sideMenuCheckboxStatus
    const sideMenuDateFilters = isInitialSearch ? [] : state.reports.search.sideMenuDateFilters
    const sideMenuTextMatchesStatus = isInitialSearch ? [] : state.reports.search.sideMenuTextMatchesStatus
    const filters = isInitialSearch ? {} : getFilters(reportSearchSideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)
    const aggregates = getAggregates(saveAggregation, reportSearchSideMenuFilters)

    try {
      const result = await reportService.requestReports(page, pageSize, reportsSortBy, reportsSortOrder, filters, aggregates)

      return {
        result,
        saveAggregation,
        appliedFilters: filters,
        page
      }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const initialReportSearch = (): AppThunk => async (dispatch) => {
  const page = 1
  const isInitialSearch = true
  dispatch(executeReportSearch({ page, isInitialSearch }))
}

export const updateReportSearch = (): AppThunk => async (dispatch) => {
  const page = 1
  const isInitialSearch = false
  dispatch(executeReportSearch({ page, isInitialSearch }))
}

export const changePage = (page: number): AppThunk => async (dispatch) => {
  const isInitialSearch = false
  dispatch(executeReportSearch({ page, isInitialSearch }))
}
