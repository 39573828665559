import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKRadioGroup from '../../../components/govuk/GovUKRadioGroup'
import { RadioButton } from '../../../types/radios'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import { Link, Navigate } from 'react-router-dom'
import { reset } from '../state/feedback'
import { useAppDispatch } from '../../../store'
import { SupportFeedbackType } from '../../../types/api'
import PageTitle from '../../../components/PageTitle'
import { useAuthentication } from '../../../hooks/authentication'
import GovUKButton from '../../../components/govuk/GovUKButton'

const typeInputName = 'feedback-type'
const typeRadioGroupIdentifier = `${typeInputName}-wrap`
const typeErrorMessage = 'The type field cannot be empty'

const errorLinks: ErrorLinks = {
  [typeErrorMessage]: {
    anchorName: `#${typeRadioGroupIdentifier}`,
    wrapRef: React.createRef<HTMLDivElement>(),
    inputRef: React.createRef<HTMLInputElement>()
  }
}

const SupportFeedback = () => {

  const dispatch = useAppDispatch()
  const { authenticated } = useAuthentication()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const [type, setType] = useState(SupportFeedbackType.Issue)
  const [typeError, setTypeError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    switch (type) {
      case SupportFeedbackType.Issue:
        return <Navigate to='/feedback/support/issue' />
      case SupportFeedbackType.Request:
        return <Navigate to='/feedback/support/request' />
    }
  }

  const typeIsValid = (input: string) => input === SupportFeedbackType.Issue || input === SupportFeedbackType.Request

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    if (typeIsValid(type)) {
      setSubmitted(true)
    }
    else {
      setTypeError(typeErrorMessage)
    }
  }

  const onTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as SupportFeedbackType
    if (typeIsValid(value)) {
      setTypeError('')
      setType(value)
    }
    else {
      setTypeError(typeErrorMessage)
    }
  }

  const radioItems: RadioButton[] = [
    {
      id: SupportFeedbackType.Issue,
      text: 'I want to report a problem'
    },
    {
      id: SupportFeedbackType.Request,
      text: 'I have a request'
    }
  ]

  const authenticatedForm = <>
    <p>Please give us your feedback about the site using <Link to='/feedback/general'>our survey</Link></p>

    {typeError && <GovUKErrorSummary title='Please check the form' errors={[typeError]} errorLinks={errorLinks} />}

    <form onSubmit={onSubmit}>

      <GovUKRadioGroup
        name={typeInputName}
        legend='How can we help you?'
        items={radioItems}
        selected={type}
        onChange={onTypeChange}
        legendClass='govuk-fieldset__legend--s'
        showError={typeError.length > 0}
        errorMessage={typeError}
        wrapRef={errorLinks[typeErrorMessage].wrapRef}
        inputRef={errorLinks[typeErrorMessage].inputRef}
      />

      <div className='govuk-form-group'>
        <GovUKButton
          className='govuk-button'
          style={{ marginBottom: '0px' }}
          id='support-feedback-continue'
          type='submit'>
          Continue
        </GovUKButton>
      </div>

    </form>
  </>

  const unauthenticatedForm = <>
    <h2 id='support-email' className='govuk-heading-m'>Email</h2>
    <p>Email the APHIDS team on <a href='mailto:aphids.support@homeoffice.gov.uk'>aphids.support@homeoffice.gov.uk</a>.</p>
  </>

  return (
    <>
      <PageTitle heading='Support' />

      <p>APHIDS support maintains the service behind APHIDS. APHIDS provides operational support from 9am to 5pm, Monday to Friday.</p>

      {authenticated
        ? authenticatedForm
        : unauthenticatedForm
      }
    </>
  )
}

export default SupportFeedback
