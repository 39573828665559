import fieldMappings from './auditSearchFieldMappings'
import { MenuFilter, TEXT, DATE_TIME, TERM } from '../types/filters'
import { getFriendlyName } from '../services/fieldMappings'

const auditSearchSideMenuFilters: MenuFilter[] = [
  {
    label: getFriendlyName('justification', fieldMappings),
    field: 'justification',
    aggregateField: 'justification.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: getFriendlyName('user', fieldMappings),
    field: 'user',
    aggregateField: 'user.keyword',
    inputType: TEXT,
    filterType: TERM
  },
  {
    label: getFriendlyName('http.request.timestamp', fieldMappings),
    field: 'http.request.timestamp',
    inputType: DATE_TIME
  },
  {
    label: getFriendlyName('terms', fieldMappings),
    field: 'terms',
    aggregateField: 'terms.keyword',
    inputType: TEXT,
    filterType: TERM
  }
]

export default auditSearchSideMenuFilters
