import { createSlice } from '@reduxjs/toolkit'
import { requestDownload } from './thunks'

export type TrainingDownloadState = {
  hasError: boolean
}

export const initialState: TrainingDownloadState = {
  hasError: false
}

export const TRAINING_DOWNLOAD = 'Training/download'

const slice = createSlice({
  name: TRAINING_DOWNLOAD,
  initialState,
  reducers: {
    reset() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestDownload.rejected, (state) => {
      state.hasError = true
    })
  }
})

export * from './thunks'

export const {
  reset
} = slice.actions

export default slice.reducer
