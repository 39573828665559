import {
  reset
} from '../results/actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const SEARCH_JUSTIFICATION = 'search/Justification'

export type SearchJustificationState = {
  isEditing: boolean
  justification: string
  justificationError: string
}

export const initialState: SearchJustificationState = {
  isEditing: false,
  justification: '',
  justificationError: ''
}

const slice = createSlice({
  name: SEARCH_JUSTIFICATION,
  initialState,
  reducers: {
    setSearchJustification(state, action: PayloadAction<string>) {
      state.justification = action.payload || ''
    },
    setJustificationError(state, action: PayloadAction<string>) {
      state.justificationError = action.payload || ''
    },
    startEditing(state) {
      state.isEditing = true
    },
    stopEditing(state) {
      state.isEditing = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  }
})

export const {
  setJustificationError,
  setSearchJustification,
  startEditing,
  stopEditing
} = slice.actions

export default slice.reducer
