import axios from 'axios'
import { ApiSuccessResult, UserProfile } from '../../types/api'

const setTermsAccepted = async () => {
  const res = await axios.post('/api/v1/user-profile/terms-acceptance')
  return res.data
}

const requestUserProfile = async () => {
  const res = await axios.get<ApiSuccessResult<UserProfile>>('/api/v1/user-profile')
  return res.data
}

export default {
  setTermsAccepted,
  requestUserProfile
}