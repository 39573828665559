import React, { FC } from 'react'

export type ErrorLink = {
  anchorName: string
  wrapRef?: React.RefObject<HTMLDivElement>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.RefObject<any>
}

export type ErrorLinks = {
  [key: string]: ErrorLink
}

type GovUKErrorSummaryProps = {
  title: string
  errors?: string[]
  errorLinks?: ErrorLinks
}

const GovUKErrorSummary: FC<GovUKErrorSummaryProps> = ({
  title,
  errors = [],
  errorLinks = {}
}) => {

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>, { wrapRef, inputRef }: ErrorLink) => {
    if (wrapRef && wrapRef.current) {
      wrapRef.current.scrollIntoView()
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
    event.preventDefault()
  }

  return (
    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex={-1} data-module="govuk-error-summary">
      <h2 className="govuk-error-summary__title" id="error-summary-title">
        {title}
      </h2>
      <div className="govuk-error-summary__body">
        <ul data-testid='govuk-error-summary__list' className="govuk-list govuk-error-summary__list">
          {errors.map((error: string, errorIndex: number) =>
            <li key={`${error}-${errorIndex}`} data-testid='govuk-error-summary__list-item'>
              {errorLinks[error]
                ? <a href={errorLinks[error].anchorName} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => { onClickHandler(e, errorLinks[error]) }}>
                  {error}
                </a>
                : error}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default GovUKErrorSummary
