import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react'
import GovUKTextInput from '../../../components/govuk/GovUKTextInput'
import GovUKTextArea from '../../../components/govuk/GovUKTextArea'
import GovUKErrorSummary, {
  ErrorLinks
} from '../../../components/govuk/GovUKErrorSummary'
import { useAppDispatch } from '../../../store'
import {
  submitChangeOtpRequest,
  submitCreateAccountRequest,
  submitRemoveAccountRequest
} from '../state/feedback/thunks'
import { Navigate } from 'react-router-dom'
import { MAX_WIDTH } from '../../../constants/inlineStyles'
import {
  SupportRequestType,
  SupportRequestTargetAccount,
  SupportRequest,
  SupportRequestSomeoneElse,
  SupportRequestOwn
} from '../../../types/api'
import { reset } from '../state/feedback'
import GovUKButton from '../../../components/govuk/GovUKButton'
import isEmail from 'validator/lib/isEmail'

type SupportFeedbackRequestAccountDetailsProps = {
  requestType: SupportRequestType
  targetAccount?: SupportRequestTargetAccount
}
const initialState = {
  forename: '',
  surname: '',
  email: '',
  feedbackMessage: '',
  forenameError: '',
  surnameError: '',
  feedbackMessageErrorBoolean: '',
  emailError: '',
  submitted: false,
  formErrors: [],
  submissionEnabled: false
};

export const emailErrorMessage = 'The email field must be a valid email address'
export const forenameErrorMessage = 'The forename field must not be empty'
export const surnameErrorMessage = 'The surname field must not be empty'

const SupportFeedbackRequestAccountDetails = ({
  requestType,
  targetAccount = SupportRequestTargetAccount.SomeoneElse
}: SupportFeedbackRequestAccountDetailsProps) => {
  if (requestType === SupportRequestType.CreateAccount) {
    targetAccount = SupportRequestTargetAccount.SomeoneElse
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    clearState();
  }, [targetAccount]);  

  const [forename, setForename] = useState(initialState.forename)
  const [surname, setSurname] = useState(initialState.surname)
  const [email, setEmail] = useState(initialState.email)
  const [feedbackMessage, setFeedbackMessage] = useState(
    initialState.feedbackMessage
  );
  const [formErrors, setFormErrors] = useState<string[]>(
    initialState.formErrors
  );

  const [feedbackMessageErrorBoolean, setFeedbackMessageError] = useState(
    initialState.feedbackMessageErrorBoolean
  );
  const [submissionEnabled, setSubmissionEnabled] = useState(initialState.submissionEnabled);
  const [submitted, setSubmitted] = useState(initialState.submitted);

  if (submitted) {
    return (
      <Navigate
        to={{ pathname: '/feedback/support/confirmation' }}
        state={{ type: requestType }}
      />
    )
  }

  const clearState = () => {
    setForename(initialState.forename);
    setSurname(initialState.surname);
    setEmail(initialState.email);
    setFeedbackMessage(initialState.feedbackMessage);
    setFeedbackMessageError(initialState.feedbackMessageErrorBoolean);
    setSubmitted(initialState.submitted);
    setFormErrors(initialState.formErrors);
    setSubmissionEnabled(initialState.submissionEnabled);
  };

  const textAreaColumns = 120
  const textAreaRows = 10
  const textAreaMaxLength = 1200

  const feedbackMessageErrorText =
    'The additional comment field cannot exceed 1200 characters'

  const errorLinks: ErrorLinks = {
    [emailErrorMessage]: {
      anchorName: `#email-wrap`,
      wrapRef: React.createRef(),
      inputRef: React.createRef<HTMLInputElement>()
    },
    [feedbackMessageErrorText]: {
      anchorName: `#feedback-feedbackMessage-wrap`,
      wrapRef: React.createRef(),
      inputRef: React.createRef<HTMLTextAreaElement>()
    },
    [forenameErrorMessage]: {
      anchorName: '#forename-wrap',
      wrapRef: React.createRef(),
      inputRef: React.createRef<HTMLInputElement>()
    },
    [surnameErrorMessage]: {
      anchorName: '#surname-wrap',
      wrapRef: React.createRef(),
      inputRef: React.createRef<HTMLInputElement>()
    }
  }

  const isSomeoneElsesAccount: boolean =
    targetAccount === SupportRequestTargetAccount.SomeoneElse

  const emailIsValid = (input: string): boolean =>
    input.trim().length > 0 && isEmail(input)

  const messageIsValid = (input: string): boolean =>
    input.trim().length <= textAreaMaxLength

  const isNotEmpty = (input: string): boolean => input.trim().length > 0

  /**
   * Handles input change for a specified field.
   * @param {ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
   * @param {Function} setValue - The function to set the field value.
   * @param {Function} validationFn - The function used for input validation.
   * @param {string} errorMessage - The error message related to the field.
   * @returns {void}
   */
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: Function,
    validationFn: Function,
    errorMessage: string
  ): void => {
    const {
      target: { value }
    } = e

    if (validationFn(value) && formErrors.some((error) => error !== value)) {
      setFormErrors(
        formErrors.filter((formError) => formError !== errorMessage)
      )
    }

    setSubmissionEnabled(true);
    setValue(value);
  };

  const onForenameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    handleInputChange(e, setForename, isNotEmpty, forenameErrorMessage)
  }

  const onSurnameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    handleInputChange(e, setSurname, isNotEmpty, surnameErrorMessage)
  }

  const onEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    handleInputChange(e, setEmail, emailIsValid, emailErrorMessage)
  }

  const onMessageChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const {
      target: { value }
    } = e

    // This checks if the feedback message is valid and updates error states accordingly
    const isValid = messageIsValid(value)
    setFeedbackMessageError(
      isValid ? '' : initialState.feedbackMessageErrorBoolean
    )

    // This will update form errors array based on message validity
    setFormErrors((prevErrors) =>
      isValid
        ? prevErrors.filter((error) => error !== feedbackMessageErrorText)
        : [
            ...prevErrors.filter((error) => error !== feedbackMessageErrorText),
            feedbackMessageErrorText
          ]
    )

    setFeedbackMessage(value)
  };

  /**
   * A method to validate each form field which returns a boolean value.
   *
   * @param accountCheckBoolean - boolean value representing if it is users account or not
   * @param validatorFunction - call back function to validate the string that is passed in via the form
   * @param hasErrors - the local scoped hasErrors boolean value which triggers within the onSubmit method
   * @param valueToCheck - the string value that is to be checked within the validator function
   * @param formFieldErrorMessage - the error message related to the form fields within the form
   * @returns A boolean value based on when the user interacts with the form.
   */
  const checkForErrors = (
    accountCheckBoolean: boolean,
    validatorFunction: (value: string) => boolean,
    valueToCheck: string,
    formFieldErrorMessage: string
  ): boolean => {
    if (
      accountCheckBoolean &&
      !validatorFunction(valueToCheck) &&
      !formErrors.includes(formFieldErrorMessage)
    ) {
      setFormErrors((formErrors) => [...formErrors, formFieldErrorMessage])
      return true
    }

    return false;
  };

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    let forenameFieldHasErrors = false;
    let surnameFieldHasErrors = false;
    let emailFieldHasErrors = false;

    
    forenameFieldHasErrors = checkForErrors(
      isSomeoneElsesAccount,
      isNotEmpty,
      forename,
      forenameErrorMessage
    );

    surnameFieldHasErrors = checkForErrors(
      isSomeoneElsesAccount,
      isNotEmpty,
      surname,
      surnameErrorMessage
    );

    emailFieldHasErrors = checkForErrors(
      isSomeoneElsesAccount,
      emailIsValid,
      email,
      emailErrorMessage
    );

    if (targetAccount === SupportRequestTargetAccount.SomeoneElse && forenameFieldHasErrors || surnameFieldHasErrors || emailFieldHasErrors) return;
    if (targetAccount === SupportRequestTargetAccount.SomeoneElse && !submissionEnabled) return;
    if (targetAccount === SupportRequestTargetAccount.SomeoneElse && formErrors.length > 0) return;

    const message = feedbackMessage

    const content: SupportRequest =
      targetAccount === SupportRequestTargetAccount.SomeoneElse
        ? ({
            targetAccount,
            forename,
            surname,
            email,
            message
          } as SupportRequestSomeoneElse)
        : ({
            targetAccount,
            message
          } as SupportRequestOwn)

    switch (requestType) {
      case SupportRequestType.CreateAccount:
        await dispatch(submitCreateAccountRequest(content))
        break
      case SupportRequestType.RemoveAccount:
        await dispatch(submitRemoveAccountRequest(content))
        break
      case SupportRequestType.ChangeOTPDevice:
        await dispatch(submitChangeOtpRequest(content))
        break
      default:
        return
    }

    setSubmitted(true)
  }

  return (
    <>
      {formErrors && formErrors.length > 0 && (
        <GovUKErrorSummary
          title="Please check the form"
          errors={formErrors}
          errorLinks={errorLinks}
        />
      )}

      <form onSubmit={onSubmit}>
        {isSomeoneElsesAccount && (
          <>
            <GovUKTextInput
              name="forename"
              label="Forename"
              onChange={onForenameChange}
              showError={
                !!formErrors &&
                formErrors.length > 0 &&
                formErrors.includes(forenameErrorMessage)
              }
              errorMessage={forenameErrorMessage}
              wrapStyle={{ maxWidth: MAX_WIDTH }}
              wrapRef={errorLinks[forenameErrorMessage].wrapRef}
              inputRef={errorLinks[forenameErrorMessage].inputRef}
            />

            <GovUKTextInput
              name="surname"
              label="Surname"
              showError={
                !!formErrors &&
                formErrors.length > 0 &&
                formErrors.includes(surnameErrorMessage)
              }
              errorMessage={surnameErrorMessage}
              onChange={onSurnameChange}
              wrapStyle={{ maxWidth: MAX_WIDTH }}
              wrapRef={errorLinks[surnameErrorMessage].wrapRef}
              inputRef={errorLinks[surnameErrorMessage].inputRef}
            />

            <GovUKTextInput
              name="email"
              label="Email"
              onChange={onEmailChange}
              showError={
                !!formErrors &&
                formErrors.length > 0 &&
                formErrors.includes(emailErrorMessage)
              }
              errorMessage={emailErrorMessage}
              wrapRef={errorLinks[emailErrorMessage].wrapRef}
              inputRef={errorLinks[emailErrorMessage].inputRef}
              wrapStyle={{ maxWidth: MAX_WIDTH }}
            />
          </>
        )}
        <GovUKTextArea
          rows={textAreaRows}
          columns={textAreaColumns}
          name="feedbackMessage"
          label="Additional comments"
          value={feedbackMessage}
          showLabel={true}
          onChange={onMessageChange}
          showError={
            !!feedbackMessageErrorBoolean &&
            feedbackMessageErrorBoolean.length > 0
          }
          errorMessage={feedbackMessageErrorBoolean}
          wrapRef={errorLinks[feedbackMessageErrorText].wrapRef}
          inputRef={errorLinks[feedbackMessageErrorText].inputRef}
          maxLength={textAreaMaxLength}
          wrapStyle={{ maxWidth: MAX_WIDTH }}
        />

        <div className="govuk-form-group">
          <GovUKButton
            style={{ marginBottom: '0px' }}
            id="support-request-account-submit"
            type="submit">
            Submit
          </GovUKButton>
        </div>
      </form>
    </>
  )
}

export default SupportFeedbackRequestAccountDetails
