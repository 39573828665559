export type DateFilter = {
  id: string

  day: string
  month: string
  year: string

  validDay?: string
  validMonth?: string
  validYear?: string
}

export type FilterType = 'single' | 'range'

export type DatePart = 'day' | 'month' | 'year'

export const FromSuffix = '-FROM'

export const ToSuffix = '-TO'

export const DayPostfix = '-day'

export const MonthPostfix = '-month'

export const YearPostfix = '-year'