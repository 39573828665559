import axios, { AxiosRequestConfig } from 'axios'
import FormData from 'form-data'
import { ApiSuccessResult } from '../../types/api'

const getFileFromObjectURL = async (url: string) => {
  const { data } = await axios.get(url, { responseType: 'blob' })
  return new Blob([data], { type: data.type })
}

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uploadFile = async (file: Blob, fileName: string, source: string, sourceType: string, onUploadProgress: (ProgressEvent: any) => void, count = 0, onUploadFail: (ProgressEvent: any) => void): Promise<ApiSuccessResult<any>> => {
  const form = new FormData()
  form.append('file', file, fileName)

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-SourceName': fileName,
      'Content-SourceType': sourceType,
      'Content-SourceOrg': source
    },
    onUploadProgress
  }

  const result = await axios.post<ApiSuccessResult<any>>('/api/v1/batches', form, config)
  .then(result => {return result.data})
  .catch(error => {
    if(error.response?.status?.toString().startsWith('5')) {
      return onUploadFailure(file, fileName, source, sourceType, onUploadProgress, ++count, onUploadFail)
    }
    else {
      throw error
    }
  })
  return result
}

const onUploadFailure  = async (file: Blob, fileName: string, source: string, sourceType: string, onUploadProgress: (ProgressEvent: any) => void, count = 0, onUploadFail: (ProgressEvent: any) => void): Promise<ApiSuccessResult<any>> => {
  if (count === 10) {
    throw new Error("Upload failed.")
  }
  await sleep(2500)
  onUploadFail({hasError: true})
  onUploadProgress({loaded: 0, total: 100})
  await sleep(2500)
  return uploadFile(file, fileName, source, sourceType, onUploadProgress, count, onUploadFail)
}

export default {
  getFileFromObjectURL,
  uploadFile
}
