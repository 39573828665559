import React from 'react'
import classNames, { Argument as ClassValue } from 'classnames'

type GovUKLabelProps = {
  text: string
  htmlFor: string
  extraClasses?: ClassValue
  visible?: boolean
}

const GovUKLabel = ({
  text,
  htmlFor,
  extraClasses,
  visible = true
}: GovUKLabelProps) => {

  const labelClasses = classNames('govuk-label', extraClasses, {
    'govuk-visually-hidden': !visible
  })

  return (
    <label className={labelClasses} htmlFor={htmlFor}>
      {text}
    </label>
  )
}

export default GovUKLabel
