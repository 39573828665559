import React, { useState, ChangeEvent } from 'react'
import GovUKRadioGroup from '../../../components/govuk/GovUKRadioGroup'
import SupportFeedbackRequestAccountDetails from './SupportFeedbackRequestAccountDetails'
import { SupportRequestTargetAccount, SupportRequestType } from '../../../types/api'
import { RadioButton } from '../../../types/radios'
import GovUKErrorSummary, { ErrorLinks } from '../../../components/govuk/GovUKErrorSummary'
import PageTitle from '../../../components/PageTitle'
import { hasRoles } from '../../../services/token'
import { useAuthentication } from '../../../hooks/authentication'
import { CHANGE_OTP_DEVICE_REQUESTOR } from '../../../constants/roles'
import { useNavigate } from 'react-router-dom'
import GovUKBackButton from '../../../components/govuk/GovUKBackButton'

const typeInputName = 'change-otp-type'
const typeErrorMessage = 'The account type field cannot be empty'

const errorLinks: ErrorLinks = {
  [typeErrorMessage]: {
    anchorName: `#change-otp-type-wrap`,
    wrapRef: React.createRef(),
    inputRef: React.createRef<HTMLTextAreaElement>()
  }
}

const typeIsValid = (input: SupportRequestTargetAccount) =>
  input === SupportRequestTargetAccount.Own ||
  input === SupportRequestTargetAccount.SomeoneElse

const SupportFeedbackRequestChangeOtp = () => {

  const navigate = useNavigate()
  const { tokenParsed } = useAuthentication()
  const [type, setType] = useState(SupportRequestTargetAccount.Own)
  const [typeError, setTypeError] = useState('')

  const onTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value as SupportRequestTargetAccount
    if (typeIsValid(value)) {
      setTypeError('')
      setType(value)
    } else {
      setTypeError(typeErrorMessage)
    }
  }

  const radioItems: RadioButton[] = [
    {
      id: SupportRequestTargetAccount.Own,
      text: 'My own'
    },
    {
      id: SupportRequestTargetAccount.SomeoneElse,
      text: 'Someone else'
    }
  ]
  return (
    <>
      <GovUKBackButton caption='Back to request type' onClick={() => { navigate('/feedback/support/request') }} />

      <PageTitle heading='Change OTP device' />

      {typeError && <GovUKErrorSummary title='Please check the form' errors={[typeError]} errorLinks={errorLinks} />}

      {hasRoles(tokenParsed, CHANGE_OTP_DEVICE_REQUESTOR) &&
        <GovUKRadioGroup
          name={typeInputName}
          legend='Which account do you wish to change?'
          items={radioItems}
          selected={type}
          onChange={onTypeChange}
          legendClass='govuk-fieldset__legend--s'
          showError={typeError.length > 0}
          errorMessage={typeError}
          wrapRef={errorLinks[typeErrorMessage].wrapRef}
          inputRef={errorLinks[typeErrorMessage].inputRef}
        />
      }

      <SupportFeedbackRequestAccountDetails requestType={SupportRequestType.ChangeOTPDevice} targetAccount={type} />
    </>
  )
}

export default SupportFeedbackRequestChangeOtp
