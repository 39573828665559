import date from './date'
import { DateFilter, FromSuffix, ToSuffix } from '../types/dates'
import { RANGE, TEXT, MenuFilter, CHECKBOX, DATE, MATCH, TERM, DATE_TIME, FilterType, FilterMenuItem } from '../types/filters'
import { CheckboxStatus } from '../types/checkboxes'
import { TextSuggestion } from '../types/TextSuggestion'
import { Filter, FieldFilter, AggregateMetadata } from '../types/api'
import { ExpandedStatus } from '../types/SideMenu'

export const mapFilters = (filters: MenuFilter[], expandedStatus: ExpandedStatus, topMatches: AggregateMetadata) => {
  return filters.map((filter) => ({
    aggregateField: filter.aggregateField,
    checkboxes: topMatches[filter.aggregateField || filter.field] || [],
    defaultDateFilterType: filter.defaultDateFilterType,
    displayFormatter: filter.displayFormatter,
    field: filter.field,
    filterType: filter.filterType,
    inputType: filter.inputType,
    isExpanded: expandedStatus[filter.field] || false,
    label: filter.label,
    minimumInputLength: filter.minimumInputLength,
    showMissingValue: filter.showMissingValue,
    wildcard: filter.wildcard
  } as FilterMenuItem))
}

export const getFilters = (
  possibleFilters: MenuFilter[],
  sideMenuCheckboxStatus: CheckboxStatus[],
  sideMenuDateFilters: DateFilter[],
  sideMenuTextMatchesStatus: TextSuggestion[]): Filter => {

  const filter: Filter = {
    ...buildTextFilters(possibleFilters, sideMenuTextMatchesStatus),
    ...buildCheckboxFilters(possibleFilters, sideMenuCheckboxStatus),
    ...buildDateRangeFilters(possibleFilters, sideMenuDateFilters),
    ...buildDateTimeRangeFilters(possibleFilters, sideMenuDateFilters)
  }

  return filter
}

const getFilterType = (filter: MenuFilter): FilterType => {
  if (filter.filterType) {
    return filter.filterType
  }

  if (filter.inputType === TEXT) {
    return MATCH
  }

  return TERM
}

const buildTextFilters = (possibleFilters: MenuFilter[], sideMenuTextMatchesStatus: TextSuggestion[]) => {
  const filter: Filter = {}

  possibleFilters
    .filter((menuFilter) => menuFilter.inputType === TEXT)
    .forEach((menuFilter) => {
      const groupEnabledFilters = sideMenuTextMatchesStatus.filter((textMatches) => textMatches.groupId === menuFilter.label)
      if (groupEnabledFilters.length > 0) {
        const field = menuFilter.filterType === TERM && menuFilter.aggregateField
          ? menuFilter.aggregateField
          : menuFilter.field
        filter[field] = {
          type: getFilterType(menuFilter),
          dataType: menuFilter.inputType,
          values: groupEnabledFilters.map((textMatches) => textMatches.value)
        }
      }
    })

  return filter
}

const shouldIncludeFallbackField = (menuFilter: MenuFilter): boolean => !!menuFilter.fallbackField

const buildCheckboxFilters = (possibleFilters: MenuFilter[], sideMenuCheckboxStatus: CheckboxStatus[]) => {
  const filter: Filter = {}

  const enabledFilters = sideMenuCheckboxStatus.filter((checkboxStatus) => checkboxStatus.checked)

  possibleFilters
    .filter((menuFilter) => menuFilter.inputType === CHECKBOX)
    .forEach((menuFilter) => {
      const groupEnabledFilters = enabledFilters.filter((checkboxStatus) => checkboxStatus.groupId === menuFilter.field)
      if (groupEnabledFilters.length > 0) {
        const field = menuFilter.filterType === TERM && menuFilter.aggregateField
          ? menuFilter.aggregateField
          : menuFilter.field
        filter[field] = {
          type: getFilterType(menuFilter),
          dataType: menuFilter.inputType,
          values: groupEnabledFilters.map((checkboxStatus) => checkboxStatus.checkboxId)
        }
      }
    })

  return filter
}

const buildDateRangeFilters = (possibleFilters: MenuFilter[], sideMenuDateFilters: DateFilter[]) => {
  const filter: Filter = {}

  possibleFilters
    .filter((menuFilter) => menuFilter.inputType === DATE)
    .forEach((menuFilter) => {
      const dateFilter = buildDateFilter(sideMenuDateFilters, menuFilter.field)
      if (dateFilter) {
        const fieldKey = `${menuFilter.field}`
        filter[fieldKey] = dateFilter
        if (shouldIncludeFallbackField(menuFilter)) {
          filter[fieldKey].fallbackField = menuFilter.fallbackField
        }
      }
    })

  return filter
}

const buildDateTimeRangeFilters = (possibleFilters: MenuFilter[], sideMenuDateFilters: DateFilter[]): Filter => {
  const filter: Filter = {}

  possibleFilters
    .filter((menuFilter) => menuFilter.inputType === DATE_TIME)
    .forEach((menuFilter) => {
      const dateFilter = buildDateTimeFilter(sideMenuDateFilters, menuFilter.field)
      if (dateFilter) {
        const fieldKey = `${menuFilter.field}`
        filter[fieldKey] = dateFilter
        if (shouldIncludeFallbackField(menuFilter)) {
          filter[fieldKey].fallbackField = menuFilter.fallbackField
        }
      }
    })

  return filter
}

export const buildDateFilter = (filters: DateFilter[], filterName: string): FieldFilter | undefined => {
  const fromDate = getDateFromFilters(filters, `${filterName}${FromSuffix}`)
  const toDate = getDateTimeFromFilters(filters, `${filterName}${ToSuffix}`)

  if (fromDate !== null || toDate !== null) {
    const filter: FieldFilter = {
      type: RANGE,
      dataType: DATE
    }
    if (fromDate !== null) {
      filter.from = fromDate
    }
    if (toDate !== null) {
      toDate.setDate(toDate.getDate() + 1)
      filter.to = date.formatDateFromDate(toDate)
    }
    return filter
  }
  else {
    const specificDateMatch = getDateFromFilters(filters, `${filterName}`)

    if (specificDateMatch !== null) {
      return {
        type: MATCH,
        dataType: DATE,
        values: [specificDateMatch]
      }
    }
  }
}

export const buildDateTimeFilter = (filters: DateFilter[], filterName: string): FieldFilter | undefined => {
  const fromDateTime: string | null = getDateTimeISOFromFilters(filters, `${filterName}${FromSuffix}`)
  const toDateTime: Date | null = getDateTimeFromFilters(filters, `${filterName}${ToSuffix}`)

  if (fromDateTime !== null || toDateTime !== null) {
    const filter: FieldFilter = {
      type: RANGE,
      dataType: DATE
    }
    if (fromDateTime !== null) {
      filter.from = fromDateTime
    }
    if (toDateTime !== null) {
      toDateTime.setDate(toDateTime.getDate() + 1)
      filter.to = toDateTime.toISOString()
    }
    return filter
  }
  else {
    const specificDateMatch = getDateTimeFromFilters(filters, `${filterName}`)

    if (specificDateMatch !== null) {
      const nextDayYear = specificDateMatch.getUTCFullYear()
      const nextDayMonth = specificDateMatch.getMonth() // Starts at 0, which Date below expects
      const nextDayDay = specificDateMatch.getDate() + 1 // Next day
      const startOfNextDay = new Date(Date.UTC(nextDayYear, nextDayMonth, nextDayDay))
      return {
        type: RANGE,
        dataType: DATE,
        from: specificDateMatch.toISOString(),
        to: startOfNextDay.toISOString()
      }
    }
  }
}

export const getDateTimeISOFromFilters = (filters: DateFilter[], id: string) => {
  const dateTime = getDateTimeFromFilters(filters, id)
  return dateTime !== null ? dateTime.toISOString() : null
}

const getDateTimeFromFilters = (filters: DateFilter[], id: string) => {
  const filter = filters.find(dateFilter => dateFilter.id === id)

  if (!filter || !filter.validDay || !filter.validMonth || !filter.validYear) {
    return null
  }

  const [day, month, year] = [filter.day, filter.month, filter.year].map((num) => parseInt(num, 10))

  return new Date(Date.UTC(year, month - 1, day))
}

export const getDateFromFilters = (filters: DateFilter[], id: string) => {
  const filter = filters.find(dateFilter => dateFilter.id === id)

  if (!filter || !filter.validDay || !filter.validMonth || !filter.validYear) {
    return null
  }

  const { validDay, validMonth, validYear } = filter

  if (!date.isValidDate(validDay, validMonth, validYear)) {
    return null
  }

  return date.formatDateFromFragments(validDay, validMonth, validYear)
}
