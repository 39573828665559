import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import DefaultSuspense from '../../components/DefaultSuspense'
import { BULK_SEARCH } from '../../constants/roles'
import RoleCheck from '../../modules/Authorisation/components/RoleCheck'

const BulkSearchComponent = lazy(() => import('./components/BulkSearch'))

const BulkSearch = () =>
  <Routes>
    <Route path='*' element={
      <RoleCheck roles={[BULK_SEARCH]} render={() =>
        <DefaultSuspense>
          <BulkSearchComponent />
        </DefaultSuspense>
      } />
    } />
  </Routes>

export default BulkSearch
