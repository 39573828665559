import React, { FC } from 'react'
import classNames, { Argument as ClassValue } from 'classnames'

type GovUKFieldsetProps = {
  legend?: string
  describedBy?: string
  extraClasses?: ClassValue
  role?: string
  isPageHeading?: boolean
}

const GovUKFieldset: FC<GovUKFieldsetProps> = ({
  legend,
  describedBy,
  extraClasses = 'govuk-fieldset__legend--xl',
  role,
  isPageHeading = false,
  children
}) => {

  return (
    <fieldset className="govuk-fieldset" role={role} aria-describedby={describedBy}>
      {!!legend &&
        <legend className={classNames('govuk-fieldset__legend', extraClasses)}>
          {isPageHeading
            ? <h1 className="govuk-fieldset__heading">
              {legend}
            </h1>
            : legend
          }
        </legend>
      }
      {children}
    </fieldset>
  )
}

export default GovUKFieldset
