import React from 'react'

type GovUKHintProps = {
  hint: string
  id: string
}

const GovUKHint = ({ 
  hint,
  id
}: GovUKHintProps) => {
  
  return (
    <span id={id} className="govuk-hint">{hint}</span>
  )
}

export default GovUKHint
