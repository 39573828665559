import React from 'react'
import SupportFeedbackRequestAccountDetails from './SupportFeedbackRequestAccountDetails'
import { SupportRequestType } from '../../../types/api'
import PageTitle from '../../../components/PageTitle'
import { useNavigate } from 'react-router-dom'
import GovUKBackButton from '../../../components/govuk/GovUKBackButton'

const SupportFeedbackRequestAccountCreate = () => {
  const navigate = useNavigate()

  return (
    <>
      <GovUKBackButton caption='Back to request type' onClick={() => { navigate('/feedback/support/request') }} />

      <PageTitle heading='Create account' />

      <SupportFeedbackRequestAccountDetails requestType={SupportRequestType.CreateAccount} />
    </>
  )
}

export default SupportFeedbackRequestAccountCreate
