import { combineReducers } from '@reduxjs/toolkit'
import results from './results'
import justification from './justification'
import suggestions from './suggestions'

export default combineReducers({
  justification,
  results,
  suggestions
})
