import reviewService from '../../reviewService'
import { AppAsyncThunkOptions } from '../../../../store'
import { BatchRecord, PageResult } from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

interface IIssueRequest {
  batchId: string
  newPage: number
  newSeverity: string
}

export const refresh = createAsyncThunk<PageResult<BatchRecord>, IIssueRequest, AppAsyncThunkOptions>
  ('Issues/refresh', async ({ batchId, newPage, newSeverity }, { getState, rejectWithValue }) => {
    const state = getState()

    const {
      page,
      severity,
      pageSize
    } = state.ingest.issues

    const {
      sortBy,
      sortOrder
    } = state.sort

    const issuesSortBy = sortBy[SortList.UploadIssues]
    const issuesSortOrder = sortOrder[SortList.UploadIssues]

    const loadPage = newPage || page
    let loadSeverity = newSeverity || severity
    loadSeverity = loadSeverity === 'all' ? '' : loadSeverity

    try {
      const result = await reviewService.requestReview(batchId, loadSeverity, loadPage, pageSize, issuesSortBy, issuesSortOrder)
      return result
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
