import React, { FC } from 'react'
import PageTitle from './PageTitle'

type ErrorProps = {
  message?: string
}

const Error: FC<ErrorProps> = ({
  message
}) => {

  const suffix = message ? `: ${message}` : '.'

  return (
    <div>
      <PageTitle heading='Error' />

      <p>There was an error starting the application{`${suffix}`}</p>
      <p>Please try logging out and back in again. If this does not resolve the issue, please contact APHIDS support.</p>
    </div>
  )
}

export default Error
