import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { PageResult, SavedProfileResult, SavedProfileSummary } from '../../../../types/api'
import { SortList } from '../../../Sort/state'
import savedProfileService from '../../savedProfileService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestErrorHandler } from '../../../../services/request'

interface ISaveProfileRequest {
  personId: string
  justification?: string
}

interface IUnsaveProfileRequest {
  personId: string
  id: string
}

export const saveProfile = createAsyncThunk<SavedProfileResult, ISaveProfileRequest>
  ('SavedProfile/saveProfile', async ({ personId, justification }, { rejectWithValue }) => {
    try {
      const result = await savedProfileService.saveProfile(personId, justification)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const unsaveProfile = createAsyncThunk<void, IUnsaveProfileRequest>
  ('SavedProfile/unsaveProfile', async ({ id }) => {
    await savedProfileService.unsaveProfile(id)
  })

export const getSavedProfiles = (): AppThunk<Promise<void>> => async (dispatch) => {
  const page = 1
  dispatch(requestSavedProfiles(page))
}

export const changePage = (page: number): AppThunk => async (dispatch) => {
  dispatch(requestSavedProfiles(page))
}

export const requestSavedProfiles = createAsyncThunk<PageResult<SavedProfileSummary>, number, AppAsyncThunkOptions>
  ('SavedProfile/requestSavedProfiles', async (page, { getState, rejectWithValue }) => {
    const state = getState()
    const pageSize = state.savedProfile.pageSize
    const { sortBy, sortOrder } = state.sort

    const listSortBy = sortBy[SortList.SavedProfileResults]
    const listSortOrder = sortOrder[SortList.SavedProfileResults]

    try {
      const result = await savedProfileService.getSavedProfiles(page, pageSize, listSortBy, listSortOrder)
      return result
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })
