import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReleaseInformation } from '../../../../types/api'

export const AUTHORISATION_HEALTH = 'Authorisation/health'

export type HealthState = IReleaseInformation

export const initialState: HealthState = {
  release_number: undefined,
  release_phase: undefined,
  maintenance_status: undefined,
  services: undefined
}

const slice = createSlice({
  name: AUTHORISATION_HEALTH,
  initialState,
  reducers: {
    setHealth(state, action: PayloadAction<IReleaseInformation>) {
      return action.payload
    }
  }
})

export const {
  setHealth
} = slice.actions

export default slice.reducer
