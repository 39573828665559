import { addSelectedSuggestion, setTermsError } from '../results'
import searchService from '../../searchService'
import {
  updatePassHolderSearch,
  logClientError
} from '../results/thunks'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import {
  ClientErrorType,
  FilterWildcard
} from '../../../../types/api'
import { initialState } from '../results'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Suggestion } from '../../../../types/Suggestion'
import { requestErrorHandler } from '../../../../services/request'

interface FilterInformation {
  field: string
  aggregateField: string
  value: string
  wildcard?: FilterWildcard
}

export const getPassHolderSuggestions = createAsyncThunk<Suggestion, FilterInformation, AppAsyncThunkOptions>
  ('Search/suggestions', async ({ field, aggregateField, value, wildcard }, { dispatch, getState, rejectWithValue }) => {
    const state = getState()

    const {
      termsError,
      terms,
      widened
    } = state.search.results

    const hasSearchTerms = !!terms && terms.trim().length > 0

    if (!hasSearchTerms) {
      if (termsError === initialState.termsError) {
        dispatch(logClientError(ClientErrorType.PassHolderSearchTerms))
      }

      dispatch(setTermsError('Enter a search term'))
      return { field, suggestions: [] }
    }

    try {
      const result = await searchService.getPassHolderSuggestions(widened, field, aggregateField, value, terms, wildcard)

      return { field, suggestions: result.data }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const selectFilterSuggestion = (field: string, value: string): AppThunk => (dispatch) => {
  dispatch(addSelectedSuggestion({ field, value }))
  dispatch(updatePassHolderSearch())
}
